import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import axios from 'axios';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AlertTemplate from '../Common/CustomAlertTemplate';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import Alerts from '../Layout/Alerts';
import ServiceRequestsGlobalProvider from '../Operations/ServiceRequests/Main/context/GlobalContext';
import { loadUser } from '../../redux/actions/authActions';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-circular-progressbar/dist/styles.css';
import '../../scss/app.scss';

// Alert Options
// optional cofiguration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '10px 35px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1051,
  },
};

axios.interceptors.response.use(null, error => {
  const errorStatus = error.response && error.response.status;

  const expectedError =
    errorStatus === 403 && error.response.config.method === 'get';
  if (expectedError) {
    window.location = `/pages/unauthorized?message=${error.response.data.detail}`;
  }

  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/forgotpassword' &&
    window.location.pathname !== '/resetpassword' &&
    errorStatus === 401
  ) {
    window.location = '/login';
  }

  return Promise.reject(error);
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    store.dispatch(loadUser());
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
              <ServiceRequestsGlobalProvider>
                <Fragment>
                  {!loaded && (
                    <div className={`load${loading ? '' : ' loaded'}`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path
                            fill="#e0c46cde"
                            d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div>
                    <Alerts />
                    <Router />
                  </div>
                </Fragment>
              </ServiceRequestsGlobalProvider>
            </AlertProvider>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
