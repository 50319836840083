import axios from 'axios';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const GET_LOOKUPS_BY_TYPE = 'GET_LOOKUPS_BY_TYPE';
export const GET_LOOKUPS = 'GET_LOOKUPS';
export const GET_LOOKUP = 'GET_LOOKUP';
export const ADD_LOOKUP = 'ADD_LOOKUP';
export const DELETE_LOOKUP = 'DELETE_LOOKUP';
export const EDIT_LOOKUP = 'EDIT_LOOKUP';
export const GET_LOOKUP_PLACE_OF_SUPPLY = 'GET_LOOKUP_PLACE_OF_SUPPLY';

// GET LOOKUP
export const getLookup = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/lookups/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LOOKUP,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LOOKUPS BY TYPE
export const getLookupsByType =
  (lookupType, lookupKey) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/lookups/search/${lookupType}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_LOOKUPS_BY_TYPE,
        payload: res.data.results,
        meta: { lookupKey },
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET LOOKUPS
export const getLookups = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/lookups/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LOOKUPS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE LOOKUP
export const deleteLookup = id => async (dispatch, getState) => {
  try {
    await axios.delete(`${API_URL}/api/lookups/${id}/`, tokenConfig(getState));
    dispatch(createMessage({ message: 'Setting Deleted' }));
    dispatch({
      type: DELETE_LOOKUP,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD LOOKUP
export const addLookup = values => async (dispatch, getState) => {
  const lookup = {
    lookup_type: values.lookup_type,
    lookup_name: values.lookup_name,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/lookups/`,
      lookup,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Setting Added' }));
    dispatch({
      type: ADD_LOOKUP,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT LOOKUP
export const editLookup = values => async (dispatch, getState) => {
  const lookup = {
    lookup_type: values.lookup_type,
    lookup_name: values.lookup_name,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/lookups/${values.id}/`,
      lookup,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Setting Updated' }));
    dispatch({
      type: EDIT_LOOKUP,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET SPECIFIC LOOKUP --> PLACE OF SUPPLY
export const getPlaceOfSupplyByTaxTreatment =
  id => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/lookups/${id}/placeofsupply`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_LOOKUP_PLACE_OF_SUPPLY,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
