/* eslint react/forbid-prop-types: 0 */
/* eslint no-script-url: 0 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { logout } from '../../../redux/actions/authActions';

const Ava = `${process.env.PUBLIC_URL}/img/avatar.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = async () => {
    const { doLogout } = this.props;
    await doLogout();
    window.location = '/login';
  };

  render() {
    const { collapse } = this.state;
    const { user } = this.props;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          {user.profile && user.profile.photo && (
            <img
              className="topbar__avatar-img"
              src={user.profile && user.profile.photo}
              alt="avatar"
            />
          )}
          {user.profile && user.profile.photo === '' && (
            <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          )}
          <p className="topbar__avatar-name">
            {user.profile && user.profile.employee_name}
            {!user.profile && 'Admin User'}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title="My Profile"
              icon="user"
              path="/pages/user/profile"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Calendar"
              icon="calendar-full"
              path="/pages/user/calendar"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              path="/login"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

TopbarProfile.propTypes = {
  user: PropTypes.object.isRequired,
  doLogout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { doLogout: logout })(TopbarProfile);
