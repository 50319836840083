import {
  GET_CURRENCY_ADJUSTMENT,
  CLEAR_CURRENCY_ADJUSTMENT,
  GET_CURRENCY_ADJUSTMENT_LIST,
  DELETE_CURRENCY_ADJUSTMENT,
  ADD_CURRENCY_ADJUSTMENT,
  QUERY_CURRENCY_ADJUSTMENT,
} from '../actions/currencyAdjustmentActions';

const initialState = {
  currencyAdjustments: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY_ADJUSTMENT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_CURRENCY_ADJUSTMENT:
      return {
        ...state,
        fetched: null,
      };
    case GET_CURRENCY_ADJUSTMENT_LIST:
      return {
        ...state,
        currencyAdjustments: action.payload,
      };
    case DELETE_CURRENCY_ADJUSTMENT:
      return {
        ...state,
        currencyAdjustments: state.currencyAdjustments.filter(
          currency => currency.id !== action.payload
        ),
      };
    case ADD_CURRENCY_ADJUSTMENT:
      return {
        ...state,
        currencyAdjustments: [...state.currencyAdjustments, action.payload],
      };
    case QUERY_CURRENCY_ADJUSTMENT:
      return {
        ...state,
        fetched: action.payload,
      };
    default:
      return state;
  }
};
