import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const UPLOAD_USER_FILE = 'UPLOAD_USER_FILE';
export const GET_USER_FILES = 'GET_USER_FILES';
export const GET_USER_FILE = 'GET_USER_FILE';
export const DELETE_USER_FILE = 'DELETE_USER_FILE';
export const RENAME_USER_FILE = 'RENAME_USER_FILE';
export const CLEAR_USER_FILE = 'CLEAR_USER_FILE';
export const CLEAR_USER_FILES = 'CLEAR_USER_FILES';
export const ADD_USER_FOLDER = 'ADD_USER_FOLDER';
export const DELETE_USER_FOLDER = 'DELETE_USER_FOLDER';
export const GET_USER_FOLDER_DATA = 'GET_USER_FOLDER_DATA';
export const ADD_USER_FOLDER_IN_HIERARCHY = 'ADD_USER_FOLDER_IN_HIERARCHY';
export const REMOVE_USER_FOLDER_FROM_HIERARCHY =
  'REMOVE_USER_FOLDER_FROM_HIERARCHY';
export const REMOVE_USER_FOLDERS_FROM_HIERARCHY =
  'REMOVE_USER_FOLDERS_FROM_HIERARCHY';
export const CLEAR_USER_FOLDERS_HIERARCHY = 'CLEAR_USER_FOLDERS_HIERARCHY';
export const CLEAR_USER_STORAGE = 'CLEAR_USER_STORAGE';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const CLEAR_SELECTED_FILE = 'CLEAR_SELECTED_FILE';

// UPLOAD USER FILE
export const uploadUserFile =
  (userProfileId, values, folderId) => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append('profile', userProfileId);
    formData.append('folder', folderId);
    formData.append('location', 'ProfileDoc');
    formData.append('doc_type', values.doc_file.file.type);
    formData.append('doc_name', values.doc_file.file.name);
    formData.append('doc_size_bytes', values.doc_file.file.size);
    formData.append(
      'doc_file',
      values.doc_file.file,
      values.doc_file.file.name
    );

    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';
    try {
      const res = await axios.post(
        `${API_URL}/api/ProfileDocViewSet/`,
        formData,
        config
      );
      dispatch(createMessage({ message: 'File Uploaded' }));
      dispatch({ type: UPLOAD_USER_FILE, payload: res.data });
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      else
        dispatch(
          returnErrors({ message: ['An unexpected error occured'] }, 500)
        );
    }
  };

// GET USER FILES
export const getUserFiles = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounts/profile/${userId}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_FILES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// GET USER FILE
export const getUserFile = fileId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/ProfileDocViewSet/${fileId}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_FILE,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// DELETE USER FILE
export const deleteUserFile = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/ProfileDocViewSet/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'File Deleted' }));
    dispatch({ type: DELETE_USER_FILE, payload: item.id });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// RENAME USER FILE
export const renameUserFile =
  (values, userProfileId) => async (dispatch, getState) => {
    try {
      await axios.put(
        `${API_URL}/api/ProfileDocViewSet/${values.id}/`,
        { doc_name: values.newName },
        tokenConfig(getState)
      );
      dispatch(getUserFiles(userProfileId));
      dispatch(createMessage({ message: 'File Renamed Successfully' }));
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      else
        dispatch(
          returnErrors({ message: ['An unexpected error occured'] }, 500)
        );
    }
  };

export const clearUserFile = () => ({
  type: CLEAR_USER_FILE,
});

export const clearUserFiles = () => ({
  type: CLEAR_USER_FILES,
});

// DELETE FOLDER
export const deleteUserFolder = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/Folder/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Folder Deleted' }));
    dispatch({
      type: DELETE_USER_FOLDER,
      payload: item.id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD FOLDER
export const addUserFolder =
  (values, parentId) => async (dispatch, getState) => {
    const newFolder = { ...values };
    newFolder.parent_id = parentId || 0;
    try {
      const res = await axios.post(
        `${API_URL}/api/Folder/`,
        newFolder,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'Folder Added' }));
      dispatch({
        type: ADD_USER_FOLDER,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET FOLDER FILES
export const getUserFolderData =
  (folderId, userProfileId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/ProfileDocViewSet/get_folders_files/?folder_id=${folderId}&user_profile_id=${userProfileId}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_USER_FOLDER_DATA,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const addUserFolderInHierarchy = data => ({
  type: ADD_USER_FOLDER_IN_HIERARCHY,
  payload: data,
});

export const removeUserFolderFromHierarchy = folderId => ({
  type: REMOVE_USER_FOLDER_FROM_HIERARCHY,
  payload: folderId,
});

export const removeUserFoldersFromHierarchy = index => ({
  type: REMOVE_USER_FOLDERS_FROM_HIERARCHY,
  payload: index,
});

export const clearUserFoldersHierarchy = () => ({
  type: CLEAR_USER_FOLDERS_HIERARCHY,
});

export const clearUserStorage = () => ({ type: CLEAR_USER_STORAGE });
