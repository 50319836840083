import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
// import { CLEAR_SUPPLIER } from './suppliersActions';

export const GET_SUPPLIER_CONTACT = 'GET_SUPPLIER_CONTACT';
export const CLEAR_SUPPLIER_CONTACT = 'CLEAR_SUPPLIER_CONTACT';
export const GET_SUPPLIER_CONTACTS = 'GET_SUPPLIER_CONTACTS';
export const ADD_SUPPLIER_CONTACT = 'ADD_SUPPLIER_CONTACT';
export const EDIT_SUPPLIER_CONTACT = 'EDIT_SUPPLIER_CONTACT';
export const DELETE_SUPPLIER_CONTACT = 'DELETE_SUPPLIER_CONTACT';

// GET SUPPLIER CONTACT
export const getSupplierContact = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/contacts/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_CONTACT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// CLEAR SUPPLIER CONTACT
export const clearSupplierContact = () => {
  return {
    type: CLEAR_SUPPLIER_CONTACT,
  };
};

// GET SUPPLIER CONTACTS
export const getSupplierContacts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/contacts/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SUPPLIER_CONTACTS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SUPPLIER CONTACT
export const deleteSupplierContact = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/suppliers/contacts/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Contact Deleted' }));
    dispatch({ type: DELETE_SUPPLIER_CONTACT, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD SUPPLIER CONTACT
export const addSupplierContact = values => async (dispatch, getState) => {
  const supplierContact = {
    supplier_id: values.supplier_id,
    primary_contact: values.primary_contact,
    title: values.title,
    first_name: values.first_name,
    last_name: values.last_name,
    mobile_num: values.mobile_num,
    phone_num: values.phone_num,
    email: values.email,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/suppliers/contacts/`,
      supplierContact,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Contact Added' }));
    // Commented because it doesnot seems to be useful
    // dispatch({ type: CLEAR_SUPPLIER });
    dispatch({ type: ADD_SUPPLIER_CONTACT, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT SUPPLIER CONTACT
export const editSupplierContact = values => async (dispatch, getState) => {
  const supplierContact = {
    supplier_id: values.supplier_id,
    primary_contact: values.primary_contact,
    title: values.title,
    first_name: values.first_name,
    last_name: values.last_name,
    mobile_num: values.mobile_num,
    phone_num: values.phone_num,
    email: values.email,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/suppliers/contacts/${values.id}/`,
      supplierContact,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Contact Updated' }));
    dispatch({ type: EDIT_SUPPLIER_CONTACT, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
