import React from 'react';
import PropTypes from 'prop-types';

function ToolTip({
  text,
  position,
  children,
  className,
  containerClassName,
  containerStyle,
}) {
  // If no text is passed then it means no tooltip is to be displayed
  if (!text) return children;

  return (
    <div className={`custom-tooltip ${className}`}>
      {children}
      {text && (
        <div
          className={`tooltip-container position-${position} ${containerClassName}`}
          style={containerStyle}
        >
          {text}
        </div>
      )}
    </div>
  );
}

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  children: PropTypes.element.isRequired,
};

ToolTip.defaultProps = {
  position: 'top',
  className: '',
  containerClassName: '',
  containerStyle: {},
};

export default ToolTip;
