/* eslint-disable no-nested-ternary */
import moment from 'moment';
import axios from 'axios';
import store from '../containers/App/store';

const INJECTED_SPINNER_ID = 'injected-spinner';

export const formatReportComparisonFilters = filters => {
  // const selectedNumberOfPeriods = +filters.number_of_periods || 0;

  if (filters.comparison === 'yearly') {
    // const currentYear = moment().year();
    const startYear = moment(filters.comparison_start_date).year();
    const endYear = moment(filters.comparison_end_date).year();

    return {
      comparison: filters.comparison,
      number_of_year: filters.number_of_periods,
      comparison_start_date: `${startYear}-01-01`,
      comparison_end_date: `${endYear}-12-31`,
    };
  }
  if (filters.comparison === 'monthly') {
    const endDateYearAndMonth = moment(filters.comparison_end_date).format(
      'YYYY-MM'
    );
    const lastDateOfMonth = moment(filters.comparison_end_date)
      .endOf('month')
      .date();
    return {
      comparison: filters.comparison,
      comparison_start_date: moment(filters.comparison_start_date).format(
        'YYYY-MM-DD'
      ),
      comparison_end_date: `${endDateYearAndMonth}-${lastDateOfMonth}`,
    };
  }
  return {};
};

export const calculateSupplierTransactionTotals = (
  headings = [],
  transactions = []
) => {
  const totals = [];
  headings.forEach(({ key: comparisonKey }) => {
    const periodTotals = transactions.reduce(
      (acc, val) => ({
        quantity: acc.quantity + val[`${comparisonKey}_quantity`] || 0,
        amount: acc.amount + val[`${comparisonKey}_amount`] || 0,
      }),
      { quantity: 0, amount: 0 }
    );
    totals.push({
      [`${comparisonKey}_quantity`]: periodTotals.quantity,
      [`${comparisonKey}_amount`]: periodTotals.amount,
    });
  });
  return totals;
};

export const convertSelectedDurationToDates = (
  comparison = '',
  selectedDuration = ''
) => {
  if (comparison && selectedDuration) {
    if (comparison === 'yearly') {
      return {
        start_date: `${selectedDuration}-01-01`,
        end_date: `${selectedDuration}-12-31`,
      };
    }
    if (comparison === 'monthly') {
      const [month, year] = selectedDuration.split('-');
      const startDate = moment(
        `${year}-${moment().month(month).format('M')}-01`
      ).format('YYYY-MM-DD');

      return {
        start_date: startDate,
        // Subtracting 1 day because we get first day of next month which we do not require
        // In other words exclusive of end date after adding 1 month into start date
        end_date: moment(startDate)
          .add(1, 'month')
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
      };
    }
  }
  return {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  };
};

export const downloadFileWithURL = (fileURL, fileName = 'document') => {
  // Passing timestamp in every request just to bypass cache because it cause cross origin issue
  // and uses cache when download request is dispatched
  axios
    .get(`${fileURL}?timestamp=${new Date().getTime()}`, {
      responseType: 'blob',
    })
    .then(res => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res.data);
      link.download = fileName;
      link.target = '_blank';
      link.click();
    })
    .catch(() => {
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    });
};

export const downloadFileWithFileObject = (fileObject = {}) => {
  try {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileObject);
    link.download = fileObject.name || 'document';
    link.target = '_blank';
    link.click();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getItemsOptions = values => {
  return values
    .filter(
      (singleitem, index, items) =>
        items.findIndex(
          item => item.service_type === singleitem.service_type
        ) === index
    )
    .map(item => ({
      value: item.service_type,
      label: item.service_type,
    }));
};

export function extractContentFromHTML(htmlString) {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
}

export const getAuthorizationToken = () => {
  // Get token from state
  const { getState } = store;
  const { token } = getState().auth;
  return token;
};

export const injectSpinner = (selector, customSpinner) => {
  if (!selector) return;
  try {
    const target = document.querySelector(selector);
    target.style.position = 'relative';

    const spinnerNode = document.createElement('span');
    spinnerNode.id = INJECTED_SPINNER_ID;
    spinnerNode.style.position = 'absolute';
    spinnerNode.style.top = '50%';
    spinnerNode.style.left = '50%';
    spinnerNode.style.transform = 'translate(-50%,-50%)';
    spinnerNode.style.width = '100%';
    spinnerNode.style.height = '100%';
    spinnerNode.style.display = 'flex';
    spinnerNode.style.alignItems = 'center';
    spinnerNode.style.justifyContent = 'center';
    spinnerNode.style.backgroundColor = 'rgba(32, 32, 32, 0.650)';
    spinnerNode.style.zIndex = '99999';
    spinnerNode.innerHTML = customSpinner ? (
      // eslint-disable-next-line react/react-in-jsx-scope, react/no-danger
      <span dangerouslySetInnerHTML={{ __html: customSpinner }} />
    ) : (
      '<span class="h1 custom-text-primary fas fa-spinner fa-spin" />'
    );
    target.appendChild(spinnerNode);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }
};

export const removeSpinner = () => {
  try {
    const spinnerNode = document.getElementById(INJECTED_SPINNER_ID);
    if (spinnerNode) spinnerNode.remove();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }
};

export const transformSocketData = socketData => {
  if (socketData && socketData.status === 'success') {
    return {
      data: socketData.data || null,
      isSuccess: true,
      isError: false,
    };
  }
  return {
    data: socketData.data || null,
    isSuccess: false,
    isError: true,
    errorMessage: socketData.data || 'Something went wrong',
  };
};

export const convertURLToFile = async url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  const response = await fetch(`${url}?timestamp=${new Date().getTime()}`);
  const blob = await response.blob();
  const file = new File([blob], `${fileName}`, {
    type: blob.type,
  });
  return file;
};

export const getFlattenArray = (data = [], childrenKey = 'children') => {
  return data.reduce(
    (acc, item) =>
      acc.concat(item, ...getFlattenArray(item[childrenKey] || [])),
    []
  );
};

export const getFurtherPermissionActions = (
  furtherPermissons = [],
  moduleName = ''
) => {
  const moduleActions = furtherPermissons.find(
    actions => actions.module === moduleName
  );
  const access =
    moduleActions && moduleActions.more_actions
      ? moduleActions.more_actions.reduce((acc, action) => {
          acc[action.name.toLowerCase().replace(/ /g, '_')] =
            action.checked || moduleActions.all;
          return acc;
        }, {})
      : {};
  return access;
};
