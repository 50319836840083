import {
  GET_COMPANY_HOLIDAY,
  CLEAR_COMPANY_HOLIDAY,
  GET_COMPANY_HOLIDAYS,
  DELETE_COMPANY_HOLIDAY,
  ADD_COMPANY_HOLIDAY,
  EDIT_COMPANY_HOLIDAY,
  GET_HOLIDAY_DATES,
} from '../actions/companyHolidaysActions';

const initialState = {
  count: null,
  holidays: [],
  holidayDates: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_HOLIDAY:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_COMPANY_HOLIDAY:
      return {
        ...state,
        fetched: null,
      };
    case GET_COMPANY_HOLIDAYS:
      return {
        ...state,
        count: action.payload.count,
        holidays: action.payload.results,
      };
    case DELETE_COMPANY_HOLIDAY:
      return {
        ...state,
        holidays: state.holidays.filter(
          profile => profile.id !== action.payload
        ),
      };
    case ADD_COMPANY_HOLIDAY:
      return {
        ...state,
        holidays: [...state.holidays, action.payload],
        holidayDates: [...state.holidayDates, ...action.payload.holiday_dates],
      };
    case EDIT_COMPANY_HOLIDAY:
      return {
        ...state,
      };
    case GET_HOLIDAY_DATES:
      return {
        ...state,
        holidayDates: action.payload,
      };
    default:
      return state;
  }
};
