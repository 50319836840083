import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_VAT_RETURN_REPORTS = 'GET_VAT_RETURN_REPORTS';
export const GET_VAT_RETURN_DETAILS = 'GET_VAT_RETURN_DETAILS';
export const GET_VAT_RETURN_DETAIL_TRANSACTIONS =
  'GET_VAT_RETURN_DETAIL_TRANSACTIONS';
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const SET_VAT_RETURN_SETTINGS = 'SET_VAT_RETURN_SETTINGS';
export const MARK_REPORT_AS_FILED = 'MARK_REPORT_AS_FILED';
export const MARK_REPORT_AS_UNFILED = 'MARK_REPORT_AS_UNFILED';
export const GENERATE_VAT_RETURN_REPORT = 'GENERATE_VAT_RETURN_REPORT';
export const DELETE_VAT_RETURN_REPORT = 'DELETE_VAT_RETURN_REPORT';
export const DELETE_PAYMENT_HISTORY_RECORD = 'DELETE_PAYMENT_HISTORY_RECORD';
export const RECORD_TAX_PAYMENT = 'RECORD_TAX_PAYMENT';
export const CLEAR_VAT_RETURN_DATA = 'CLEAR_VAT_RETURN_DATA';
export const CLEAR_VAT_REPORTS = 'CLEAR_VAT_REPORTS';
export const CLEAR_TAX_PAYMENTS_DATA = 'CLEAR_TAX_PAYMENTS_DATA';
export const VAT_RETURN_LOADING = 'VAT_RETURN_LOADING';
export const VAT_RETURN_LOADED = 'VAT_RETURN_LOADED';
export const GET_VAT_AUDIT_RECORDS = 'GET_VAT_AUDIT_RECORDS';

export const clearVATReturnData = () => ({ type: CLEAR_VAT_RETURN_DATA });

export const clearVATReturnReports = () => ({ type: CLEAR_VAT_REPORTS });

export const clearTaxPaymentsData = () => ({ type: CLEAR_TAX_PAYMENTS_DATA });

export const getVATReturnReports = () => async (dispatch, getState) => {
  try {
    dispatch({ type: VAT_RETURN_LOADING });
    const res = await axios.get(
      `${API_URL}/api/tax/return/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_VAT_RETURN_REPORTS,
      payload: res.data.results,
    });
    dispatch({ type: VAT_RETURN_LOADED });
  } catch (err) {
    dispatch({ type: VAT_RETURN_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const generateVATReturnReport =
  (startDate, endDate) => async (dispatch, getState) => {
    const data = {
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const res = await axios.post(
        `${API_URL}/api/tax/return/`,
        data,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'VAT Return Generated Successfully' }));
      dispatch({
        type: GENERATE_VAT_RETURN_REPORT,
        payload: res.data.new_created_object,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const deleteVATReturnReport = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/tax/return/${id}/`,
      tokenConfig(getState)
    );
    dispatch(
      createMessage({ message: 'VAT Return Report Deleted Successfully' })
    );
    dispatch({
      type: DELETE_VAT_RETURN_REPORT,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response, err.response.status));
  }
};

export const markVATReturnReportAsFiled =
  (id, date) => async (dispatch, getState) => {
    const data = {
      status: 'filed',
      filed_on: date,
    };
    try {
      const res = await axios.put(
        `${API_URL}/api/tax/return/${id}/`,
        data,
        tokenConfig(getState)
      );
      dispatch(
        createMessage({ message: 'VAT Return Marked as Filed Successfully' })
      );
      dispatch({
        type: MARK_REPORT_AS_FILED,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response, err.response.status));
    }
  };

export const markVATReturnReportAsUnFiled =
  id => async (dispatch, getState) => {
    const data = {
      status: 'unfiled',
      filed_on: null,
    };
    try {
      await axios.put(
        `${API_URL}/api/tax/return/${id}/`,
        data,
        tokenConfig(getState)
      );
      dispatch(
        createMessage({ message: 'VAT Return Marked as UnFiled Successfully' })
      );
      dispatch({ type: MARK_REPORT_AS_UNFILED });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const getVATReturnDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/tax/return/report?tax_return_id=${id}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_VAT_RETURN_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response, err.response.status));
  }
};

export const getVATReturnDetailTransactions =
  (startDate, endDate, state, taxRate, transType) =>
  async (dispatch, getState) => {
    const queryParams = {
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      place_of_supply: state,
      tax_rate: taxRate,
      type: transType,
    };
    try {
      const res = await axios.get(`${API_URL}/api/tax/return/report/detail`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({
        type: GET_VAT_RETURN_DETAIL_TRANSACTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response, err.response.status));
    }
  };

export const getTaxPaymentHistory = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/vat/return/payment/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PAYMENT_HISTORY,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const deletePaymentHistoryRecord = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/vat/return/payment/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Record Deleted Successfully' }));
    dispatch({
      type: DELETE_PAYMENT_HISTORY_RECORD,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response, err.response.status));
  }
};

// Add Payment Record
export const recordTaxPayment = values => async (dispatch, getState) => {
  try {
    // To get latest payment number
    const latestNumberResponse = await axios.get(
      `${API_URL}/api/accountant/tax/payment/latest`,
      tokenConfig(getState)
    );

    const res = await axios.post(
      `${API_URL}/api/vat/return/payment/`,
      {
        ...values,
        transaction_num: latestNumberResponse.data.latest_num + 1,
      },
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Recorded Tax Payment Successfully' }));
    dispatch({
      type: RECORD_TAX_PAYMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Save VAT Return Settings
export const getVATReturnSettings = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/tax/return/settings/1/`,
      tokenConfig(getState)
    );
    dispatch({
      type: SET_VAT_RETURN_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Save VAT Return Settings
export const setVATReturnSettings = values => async (dispatch, getState) => {
  const data = {
    trn_prefix: values.prefix,
    trn_num: values.trnNo,
    is_international_trade: values.isInternational,
    vat_registered_on: moment(values.registeredOn).format('YYYY-MM-DD'),
    first_tax_from: moment(values.returnsFrom).format('YYYY-MM-DD'),
    reporting_period: values.reportingPeriod,
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/tax/return/settings/1/`,
      data,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'VAT Settings Saved Successfully' }));
    dispatch({
      type: SET_VAT_RETURN_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// VAT Audit Records
export const getVATAuditRecords = () => async (dispatch, getState) => {
  try {
    dispatch({ type: VAT_RETURN_LOADING });
    const res = await axios.get(
      `${API_URL}/api/tax/return/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_VAT_AUDIT_RECORDS,
      payload: res.data.results,
    });
    dispatch({ type: VAT_RETURN_LOADED });
  } catch (err) {
    dispatch({ type: VAT_RETURN_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
