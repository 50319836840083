import { GET_ACCOUNTS, UPDATE_ACCOUNTS } from "../actions/emailSettingsActions";

const initialState = {
  accounts: [],
  selectedAccounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        ...action.payload
      };

    case UPDATE_ACCOUNTS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}