import {
  CLEAR_OPERATIONS_DATA,
  CLEAR_PRODUCT_LAUNCH_DATA,
  GET_RFP_DETAILS,
  SAVE_RFP_DETAILS,
} from '../actions/operationsActions';

const initialState = {
  fetchedRFPDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_OPERATIONS_DATA:
      return {
        ...initialState,
      };
    case CLEAR_PRODUCT_LAUNCH_DATA:
      return {
        ...state,
        fetchedRFPDetails: {},
      };
    case GET_RFP_DETAILS:
      return {
        ...state,
        fetchedRFPDetails: action.payload,
      };
    case SAVE_RFP_DETAILS:
      return {
        ...state,
        fetchedRFPDetails: action.payload,
      };
    default:
      return state;
  }
};
