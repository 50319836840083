import React, { useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

function AccountantSidebar({ handleClick }) {
  const location = useLocation();
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const {
    auth: {
      user: {
        hasEmployeeLeavesAccess,
        hasEmployeeSalariesAccess,
        hasActivitiesAccess,
        hasDocStorageAccess,
        hasTeamMeetingAccess,
        hasTrashAccess,
        hasCreditNoteAccess,
        hasAccountsAccess,
        hasCustomersAccess,
        hasQuotationsAccess,
        hasProInoviceAccess,
        hasInvoicesAccess,
        hasPaymentReceivedAccess,
        hasCurrenciesAccess,
        hasJournalsAccess,
        hasTaxPaymentsAccess,
        hasCOAAccess,
        hasTrLockingAccess,
        hasSupplierAccess,
        hasExpensesAccess,
        hasPurOrderAccess,
        hasBillsAccess,
        hasPaymentMadeAccess,
        hasDebitNoteAccess,
      },
      permission,
    },
  } = useSelector(state => state);
  const section = permission ? permission.section_access : {};

  // removed previous logic causing white page error and implemented below one with search param.
  const urlToGoBack = location.search
    ? `${location.pathname}${location.search}`
    : location.pathname;
  const goBackURL = localStorage.getItem('urlToGoBack');

  const toggleSubSidebar = () => setIsSubSidebarOpen(!isSubSidebarOpen);

  return (
    <>
      {isSubSidebarOpen ? (
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isSubSidebarOpen}
            addEndListener={(node, done) =>
              node.addEventListener('transitionend', done, false)
            }
            classNames="fade"
          >
            <>
              <ul className="sidebar__block">
                <NavLink
                  to={goBackURL}
                  onClick={() => {
                    localStorage.removeItem('urlToGoBack', '');
                    toggleSubSidebar();
                  }}
                >
                  <li className="sidebar__link custom-sidebar-link">
                    <span className="sidebar__link-icon lnr lnr-arrow-left custom-text-primary" />
                    <p className="sidebar__link-title custom-text-primary">
                      Back
                    </p>
                  </li>
                </NavLink>
              </ul>
              <ul className="sidebar__block">
                {hasActivitiesAccess && (
                  <SidebarLink
                    title="Activities"
                    route="/pages/manageActivities"
                    // onClick={this.hideSidebar}
                    icon="clipboard-list"
                  />
                )}

                {hasEmployeeSalariesAccess && (
                  <SidebarLink
                    title="Employee Salaries"
                    route="/pages/accounting/salaries"
                    icon="money-bill-alt"
                    onClick={handleClick}
                  />
                )}

                {hasEmployeeLeavesAccess && (
                  <SidebarLink
                    title="Employee Leaves"
                    route="/pages/hr/leaves"
                    icon="file-alt"
                    onClick={handleClick}
                  />
                )}
                {hasTeamMeetingAccess && (
                  <SidebarLink
                    title="Team Meeting"
                    route="/pages/manageInternalMeetings"
                    onClick={handleClick}
                    icon="handshake"
                  />
                )}
                {hasDocStorageAccess && (
                  <SidebarLink
                    icon="folder-open"
                    title="Document Storage"
                    route="/pages/storage/main"
                    onClick={handleClick}
                  />
                )}
                {hasTrashAccess && (
                  <SidebarLink
                    icon="trash-alt"
                    title="Trashed Documents"
                    route="/pages/storage/trash"
                    onClick={handleClick}
                  />
                )}
              </ul>
            </>
          </CSSTransition>
        </SwitchTransition>
      ) : (
        <>
          <ul className="sidebar__block">
            <SidebarLink
              title="Dashboard"
              icon="tachometer-alt"
              route="/"
              onClick={handleClick}
            />
          </ul>
          <ul className="sidebar__block">
            {section.Items && (
              <SidebarLink
                title="Items"
                icon="shopping-basket"
                route="/pages/accounting/items"
                onClick={handleClick}
              />
            )}

            {section['Inventory Adjustments'] && (
              <SidebarLink
                title="Inventory Adjustments"
                icon="cubes"
                route="/pages/accounting/inventoryAdjustments"
                onClick={handleClick}
              />
            )}

            {section.Banking && (
              <SidebarLink
                title="Banking"
                icon="university"
                route="/pages/accounting/banking"
                onClick={handleClick}
              />
            )}

            {/* {isImmediateSupervisor && (
              <SidebarLink
                title="Employee Leave"
                icon="th-list"
                route="/pages/employee/leaves"
                onClick={handleClick}
              />
            )} */}
            {section.Sales && (
              <SidebarCategory title="Sales" icon="gifts">
                {hasAccountsAccess && (
                  <SidebarLink
                    title="Accounts"
                    route="/pages/accounting/sales/accounts"
                    onClick={handleClick}
                  />
                )}
                {hasCustomersAccess && (
                  <SidebarLink
                    title="Customers"
                    route="/pages/accounting/sales/customers"
                    onClick={handleClick}
                  />
                )}
                {hasQuotationsAccess && (
                  <SidebarLink
                    title="Quotations"
                    route="/pages/accounting/sales/quotations"
                    onClick={handleClick}
                  />
                )}
                {hasProInoviceAccess && (
                  <SidebarLink
                    title="Proforma Invoices"
                    route="/pages/accounting/sales/proInvoices"
                    onClick={handleClick}
                  />
                )}
                {hasInvoicesAccess && (
                  <SidebarLink
                    title="Invoices"
                    route="/pages/accounting/sales/invoices"
                    onClick={handleClick}
                  />
                )}
                {hasPaymentReceivedAccess && (
                  <SidebarLink
                    title="Payments Received"
                    route="/pages/accounting/sales/paymentsReceived"
                    onClick={handleClick}
                  />
                )}
                {hasCreditNoteAccess && (
                  <SidebarLink
                    title="Credit Notes"
                    route="/pages/accounting/sales/creditNotes"
                    onClick={handleClick}
                  />
                )}
              </SidebarCategory>
            )}
            {section.Purchases && (
              <SidebarCategory title="Purchases" icon="shopping-bag">
                {hasSupplierAccess && (
                  <SidebarLink
                    title="Suppliers"
                    route="/pages/accounting/purchases/suppliers"
                    onClick={handleClick}
                  />
                )}
                {hasExpensesAccess && (
                  <SidebarLink
                    title="Expenses"
                    route="/pages/accounting/purchases/expenses"
                    onClick={handleClick}
                  />
                )}
                {hasPurOrderAccess && (
                  <SidebarLink
                    title="Purchase Orders"
                    route="/pages/accounting/purchases/purOrders"
                    onClick={handleClick}
                  />
                )}
                {hasBillsAccess && (
                  <SidebarLink
                    title="Bills"
                    route="/pages/accounting/purchases/bills"
                    onClick={handleClick}
                  />
                )}
                {hasPaymentMadeAccess && (
                  <SidebarLink
                    title="Payments Made"
                    route="/pages/accounting/purchases/paymentsMade"
                    onClick={handleClick}
                  />
                )}
                {hasDebitNoteAccess && (
                  <SidebarLink
                    title="Debit Notes"
                    route="/pages/accounting/purchases/supplierCredits"
                    onClick={handleClick}
                  />
                )}
              </SidebarCategory>
            )}
            {section.Accountant && (
              <SidebarCategory title="Accountant" icon="user-tie">
                {hasCurrenciesAccess && (
                  <SidebarLink
                    title="Currencies"
                    route="/pages/accounting/accountant/currencies"
                    onClick={handleClick}
                  />
                )}
                {hasJournalsAccess && (
                  <SidebarLink
                    title="Manual Journals"
                    route="/pages/accounting/accountant/journals"
                    onClick={handleClick}
                  />
                )}
                {hasTaxPaymentsAccess && (
                  <SidebarLink
                    title="Tax Payments"
                    route="/pages/accounting/accountant/taxPayments"
                    onClick={handleClick}
                  />
                )}
                {hasCOAAccess && (
                  <SidebarLink
                    title="Chart of Accounts"
                    route="/pages/accounting/accountant/chartOfAccounts"
                    onClick={handleClick}
                  />
                )}
                {hasTrLockingAccess && (
                  <SidebarLink
                    title="Transaction Locking"
                    route="/pages/accounting/accountant/transactionLocking"
                    onClick={handleClick}
                  />
                )}
              </SidebarCategory>
            )}
            {section.Reports && (
              <SidebarLink
                title="Reports"
                route="/pages/reports"
                icon="project-diagram"
                onClick={handleClick}
              />
            )}
          </ul>
          {section.Miscellaneous && (
            <ul className="sidebar__block">
              <NavLink
                to={urlToGoBack}
                onClick={() => {
                  localStorage.setItem('urlToGoBack', urlToGoBack);
                  toggleSubSidebar();
                }}
              >
                <li className="sidebar__link">
                  <span className="sidebar__link-icon fas fa-layer-group" />
                  <p className="sidebar__link-title">Miscellaneous</p>
                </li>
              </NavLink>
            </ul>
          )}
        </>
      )}
    </>
  );
}

AccountantSidebar.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default AccountantSidebar;
