/* eslint-disable no-param-reassign */

const groupArrayOfObjects = (templist, key) => {
  return templist.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default groupArrayOfObjects;
