import {
  ACCOUNTING_STAFF_RECEIVABLE,
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_MANAGER,
  ACCOUNTING_STAFF_PAYABLE,
  HR_STAFF,
  HR_MANAGER,
  HR_CONTROLLER,
  OPERATIONS_STAFF,
  OPERTIONS_DEPT,
  OPERTIONS_VIP_DEPT,
  OPERTIONS_CORP_DEPT,
  OPERTIONS_TICKETING_DEPT,
  OPERTIONS_MICE_DEPT,
  SALES_DEPT,
  // HR_DEPT,
  // FINANCE_DEPT,
} from './constants';

const sideBar = {
  items: 'Items',
  storage: 'storage',
  trash: 'trash',
  meetings: 'Meetings',
  hr: {
    hrTab: 'HR',
    hrTabChild: {
      employees: 'Employees',
      holidays: 'Holidays',
      docRequests: 'Documents Request',
      leavesRequest: 'Leaves Request',
      salaries: 'Salaries',
      announcements: 'Announcements',
      activities: 'Activities',
    },
    comapnyTab: 'Company',
    comapnyTabChild: {
      companyProfile: 'Company Profiles',
      companyFiles: 'Company Files',
    },
  },
  accounting: {
    inventoryAdjustment: 'Inventory Adjustments',
    bank: 'Banking',

    salesTab: 'Sales',
    salesTabChild: {
      accounts: 'Accounts',
      customers: 'Customers',
      quotation: 'Quotations',
      proInvoice: 'Proforma Invoices',
      invoice: 'Invoices',
      paymentReceived: 'Payments Received',
      creditNote: 'Credit Notes',
    },
    purchaseTab: 'Purchases',
    purchaseTabChild: {
      suppliers: 'Suppliers',
      expenses: 'Expenses',
      purOrder: 'Purchase Orders',
      bills: 'Bills',
      paymentMade: 'Payments Made',
      debitNotes: 'Debit Notes',
    },
    accountantTab: 'Accountant',
    accountantTabChild: {
      currencies: 'Currencies',
      journals: 'Manual Journals',
      taxPayments: 'Tax Payments',
      coa: 'Chart of Accounts',
      trLocking: 'Transaction Locking',
      debitNotes: 'Debit Notes',
    },
    miscTab: 'Miscellaneous',
    miscTabChild: {
      miscActivities: 'Activities',
      empSalaries: 'Employee Salaries',
      empLeaves: 'Employee Leaves',
      meeting: 'Team Meeting',
      docStorage: 'Document Storage',
      trashDoc: 'Trashed Documents',
    },
  },

  operations: {
    operationsTab: 'Event Management',
    operationsTabChild: {
      eventCalender: 'Event Calendar',
      eventCoordinators: 'Event Coordinators',
      requested: 'Requested',
      confirmed: 'Confirmed',
      finished: 'Finished',
      cancelled: 'Cancelled',
      serviceRequests: 'Service Requests',
    },
  },
};

const getUserPermissionsForSystemAccess = (
  userRole = '',
  department = '',
  permission = {}
) => {
  const isOperationalDept = [
    OPERTIONS_VIP_DEPT,
    OPERTIONS_CORP_DEPT,
    OPERTIONS_TICKETING_DEPT,
    OPERTIONS_MICE_DEPT,
    OPERTIONS_DEPT,
  ].includes(department);
  const userDepartment = isOperationalDept ? 'Operations' : department;

  const {
    hr: {
      hrTab,
      hrTabChild: {
        employees,
        holidays,
        docRequests,
        leavesRequest,
        salaries,
        announcements,
        activities,
      },
      comapnyTab,
      comapnyTabChild: { companyProfile, companyFiles },
    },
    accounting: {
      salesTab,
      salesTabChild: {
        accounts,
        customers,
        quotation,
        proInvoice,
        invoice,
        paymentReceived,
        creditNote,
      },
      purchaseTab,
      purchaseTabChild: {
        suppliers,
        expenses,
        purOrder,
        bills,
        paymentMade,
        debitNotes,
      },
      accountantTab,
      accountantTabChild: { currencies, journals, taxPayments, coa, trLocking },
      miscTab,
      miscTabChild: {
        miscActivities,
        empSalaries,
        empLeaves,
        meeting,
        docStorage,
        trashDoc,
      },
    },
    operations: {
      operationsTab,
      operationsTabChild: {
        eventCalender,
        eventCoordinators,
        requested,
        confirmed,
        finished,
        cancelled,
        serviceRequests,
      },
    },
  } = sideBar;

  const hasAccess = (parentModule, subModule) => {
    return (
      permission &&
      permission.module_access[parentModule] &&
      permission.module_access[parentModule].includes(subModule)
    );
  };

  const modulesPermission = {
    HR: {
      // for module which has sub modules.
      hasEmployeesAccess: hasAccess(hrTab, employees),
      hasHolidaysAccess: hasAccess(hrTab, holidays),
      hasDocumentsAccess: hasAccess(hrTab, docRequests),
      hasLeavesReqAccess: hasAccess(hrTab, leavesRequest),
      hasSalariesAccess: hasAccess(hrTab, salaries),
      hasAnnouncementAccess: hasAccess(hrTab, announcements),
      hasActivitiesAccess: hasAccess(hrTab, activities),
      hasCompanyProfilesAccess: hasAccess(comapnyTab, companyProfile),
      hasCompanyFilesAccess: hasAccess(comapnyTab, companyFiles),
    },
    Finance: {
      // sales
      hasCreditNoteAccess: hasAccess(salesTab, creditNote),
      hasAccountsAccess: hasAccess(salesTab, accounts),
      hasCustomersAccess: hasAccess(salesTab, customers),
      hasQuotationsAccess: hasAccess(salesTab, quotation),
      hasProInoviceAccess: hasAccess(salesTab, proInvoice),
      hasInvoicesAccess: hasAccess(salesTab, invoice),
      hasPaymentReceivedAccess: hasAccess(salesTab, paymentReceived),
      // purchase
      hasSupplierAccess: hasAccess(purchaseTab, suppliers),
      hasExpensesAccess: hasAccess(purchaseTab, expenses),
      hasPurOrderAccess: hasAccess(purchaseTab, purOrder),
      hasBillsAccess: hasAccess(purchaseTab, bills),
      hasPaymentMadeAccess: hasAccess(purchaseTab, paymentMade),
      hasDebitNoteAccess: hasAccess(purchaseTab, debitNotes),
      // Accountant
      hasCurrenciesAccess: hasAccess(accountantTab, currencies),
      hasJournalsAccess: hasAccess(accountantTab, journals),
      hasTaxPaymentsAccess: hasAccess(accountantTab, taxPayments),
      hasCOAAccess: hasAccess(accountantTab, coa),
      hasTrLockingAccess: hasAccess(accountantTab, trLocking),
      // Misc
      hasEmployeeLeavesAccess: hasAccess(miscTab, empLeaves),
      hasEmployeeSalariesAccess: hasAccess(miscTab, empSalaries),
      hasActivitiesAccess: hasAccess(miscTab, miscActivities),
      hasDocStorageAccess: hasAccess(miscTab, docStorage),
      hasTeamMeetingAccess: hasAccess(miscTab, meeting),
      hasTrashAccess: hasAccess(miscTab, trashDoc),
    },
    Operations: {
      // sales
      hasAccountsAccess: hasAccess(salesTab, accounts),
      hasCustomersAccess: hasAccess(salesTab, customers),
      hasQuotationsAccess: hasAccess(salesTab, quotation),
      hasProInoviceAccess: hasAccess(salesTab, proInvoice),
      hasInvoicesAccess: hasAccess(salesTab, invoice),
      // purchase
      hasSupplierAccess: hasAccess(purchaseTab, suppliers),
      hasPurOrderAccess: hasAccess(purchaseTab, purOrder),
      hasBillsAccess: hasAccess(purchaseTab, bills),
      // Event Managment

      hasEventCalenderAccess: hasAccess(operationsTab, eventCalender),
      hasEventCoordinatorsAccess: hasAccess(operationsTab, eventCoordinators),
      hasRequestedAccess: hasAccess(operationsTab, requested),
      hasConfirmedAccess: hasAccess(operationsTab, confirmed),
      hasFinishedAccess: hasAccess(operationsTab, finished),
      hasCancelledAccess: hasAccess(operationsTab, cancelled),
      hasServiceRequestsAccess: hasAccess(operationsTab, serviceRequests),

      // Accountant
      hasCurrenciesAccess: hasAccess(accountantTab, currencies),
      hasJournalsAccess: hasAccess(accountantTab, journals),
      hasTaxPaymentsAccess: hasAccess(accountantTab, taxPayments),
      hasCOAAccess: hasAccess(accountantTab, coa),
      hasTrLockingAccess: hasAccess(accountantTab, trLocking),
      // Misc
      hasEmployeeLeavesAccess: hasAccess(miscTab, empLeaves),
      hasActivitiesAccess: hasAccess(miscTab, miscActivities),
      hasAnnouncementAccess: hasAccess(miscTab, announcements),
    },
  };

  return {
    // ACCOUNTING roles
    isStaffPayable: userRole === ACCOUNTING_STAFF_PAYABLE,
    isStaffReceivable: userRole === ACCOUNTING_STAFF_RECEIVABLE,
    isAccountingStaff:
      userRole === ACCOUNTING_STAFF_PAYABLE ||
      userRole === ACCOUNTING_STAFF_RECEIVABLE,
    isAccountingSupervisor:
      userRole === ACCOUNTING_CONTROLLER || userRole === ACCOUNTING_MANAGER,
    // HR roles
    isHRStaff: userRole === HR_STAFF,
    isHRManager: userRole === HR_MANAGER,
    isHRController: userRole === HR_CONTROLLER,
    // OPERATIONS roles
    isSalesDepartment: userRole === SALES_DEPT,
    isOperationStaff:
      userRole === OPERATIONS_STAFF && department === OPERTIONS_DEPT,
    isOperationalDept,
    ...modulesPermission[userDepartment], // [department],
  };
};

export default getUserPermissionsForSystemAccess;
