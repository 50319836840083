import {
  GET_CUSTOMERS_LIST,
  GET_CUSTOMER,
  CLEAR_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS_BY_SALES_ACCOUNT_ID,
  CUSTOMER_LOADING,
  CUSTOMER_LOADED,
} from '../actions/customerActions';

const initialState = {
  count: null,
  accounts: [],
  accountsList: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        fetched: null,
      };
    case GET_CUSTOMERS_LIST:
      return {
        ...state,
        count: action.payload.count,
        accountsList: action.payload.results,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        count: action.payload.count,
        accounts: action.payload.results,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        accountsList: state.accountsList.filter(
          account => account.id !== action.payload
        ),
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        accountsList: [...state.accountsList],
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
      };
    case GET_CUSTOMERS_BY_SALES_ACCOUNT_ID:
      return {
        ...state,
        accounts: action.payload,
      };
    case CUSTOMER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CUSTOMER_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
