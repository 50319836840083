import {
  GET_USER_PERMISSION_DETIALS,
  GET_EMPLOYEES_PERMISSIONS,
  CLEAR_USER_PERMISSION_DETIALS,
  USERS_LOADING,
  USERS_LOADED,
} from '../actions/userPermissionActions';

const initialState = {
  fetched: {},
  employees: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PERMISSION_DETIALS:
      return {
        ...state,
        fetched: action.payload,
      };

    case CLEAR_USER_PERMISSION_DETIALS:
      return {
        ...state,
        fetched: {},
      };
    case GET_EMPLOYEES_PERMISSIONS:
      return {
        ...state,
        employees: action.payload,
      };

    case USERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USERS_LOADED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
