import {
  GET_LOOKUPS_BY_TYPE,
  GET_LOOKUP,
  GET_LOOKUPS,
  DELETE_LOOKUP,
  ADD_LOOKUP,
  EDIT_LOOKUP,
  GET_LOOKUP_PLACE_OF_SUPPLY,
} from '../actions/lookupsActions';

const initialState = {
  fetched: null,
  mapping: {
    activity: 'Activity',
    case: 'Case',
    city: 'City',
    country: 'Country',
    currency: 'Currency',
    event_congress: 'Event/Congress',
    gender: 'Gender',
    industry: 'Industry',
    invoice_prefix: 'Invoice Prefix',
    payment_mode: 'Payment Mode',
    payment_term: 'Payment Term',
    pro_invoice_prefix: 'Proforma Invoice Prefix',
    project: 'Project',
    project_status: 'Project Status',
    pur_order_suffix: 'Purchase Order Suffix',
    quotation_prefix: 'Quotation Prefix',
    sales_account_type: 'Sales Account Type',
    account_type: 'Account Type',
    service: 'Service',
    title: 'Title',
    unit: 'Unit',
    supplier_type: 'Supplier Type',
    tax_treatment: 'Tax Treatment',
    source_of_supply: 'Source of Supply',
    place_of_supply: 'Place of Supply',
    marital_status: 'Marital Status',
    doc_request_type: 'Request Type (My profile- Docs Request)',
    leave_type: 'Leave Type',
    adjustment_reason: 'Inventory Adjustment Reason',
  },
  lookups: [],
  countries: [],
  cities: [],
  genders: [],
  units: [],
  currencies: [],
  titles: [],
  paymentTerms: [],
  industries: [],
  salesAccountTypes: [],
  accountTypes: [],
  placeOfSupply: [],
  documentType: [],
  leaveType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOOKUPS_BY_TYPE:
      return {
        ...state,
        [action.meta.lookupKey]: action.payload,
      };
    case GET_LOOKUP:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_LOOKUPS:
      return {
        ...state,
        lookups: action.payload,
      };
    case DELETE_LOOKUP:
      return {
        ...state,
        lookups: state.lookups.filter(lookup => lookup.id !== action.payload),
      };
    case ADD_LOOKUP:
      return {
        ...state,
        lookups: [...state.lookups, action.payload],
      };
    case EDIT_LOOKUP:
      return {
        ...state,
      };
    case GET_LOOKUP_PLACE_OF_SUPPLY:
      return {
        ...state,
        placeOfSupply: action.payload,
      };
    default:
      return state;
  }
};
