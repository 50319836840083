/* eslint-disable no-plusplus */

import {
  UPLOAD_USER_FILE,
  GET_USER_FILES,
  DELETE_USER_FILE,
  RENAME_USER_FILE,
  GET_USER_FILE,
  CLEAR_USER_FILE,
  CLEAR_USER_FILES,
  ADD_USER_FOLDER_IN_HIERARCHY,
  REMOVE_USER_FOLDER_FROM_HIERARCHY,
  REMOVE_USER_FOLDERS_FROM_HIERARCHY,
  CLEAR_USER_FOLDERS_HIERARCHY,
  CLEAR_USER_STORAGE,
  DELETE_USER_FOLDER,
  ADD_USER_FOLDER,
  GET_USER_FOLDER_DATA,
} from '../actions/userFilesActions';

const initialState = {
  fetched: null,
  docs: [],
  folders: [],
  files: [],
  foldersHierarchy: [],
  openedFolderId: null,
  parentFolderId: null,
  openedFolderName: '',
  fetchedFile: null,
  filesForPaste: null,
};

let i = 0;

const mapFolders = item => ({
  id: Date.now() + i++,
  itemId: item.id,
  name: item.fold_name,
  type: item.type,
  owner: item.department,
  // user_profile_id: item.user_profile_id,
  created_by: item.created_by,
  createdAt: item.created_at,
});

const mapFiles = item => ({
  id: Date.now() + i++,
  itemId: item.id,
  name: item.doc_name,
  type: item.type,
  owner: '',
  createdAt: item.created_at,
  docType: item.doc_type,
  docFile: item.doc_file,
  category: item.location,
  // user_profile_id: item.user_profile_id,
  created_by: item.created_by,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_USER_FILE:
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: Date.now() + i++,
            itemId: action.payload.id,
            name: action.payload.doc_name,
            type: action.payload.type,
            owner: '',
            createdAt: action.payload.created_at,
            docType: action.payload.doc_type,
            docFile: action.payload.doc_file,
            category: action.payload.location,
            created_by: action.payload.created_by,
          },
        ],
      };
    case GET_USER_FILES:
      return {
        ...state,
        docs: action.payload,
      };
    case GET_USER_FILE:
      return {
        ...state,
        fetched: action.payload,
      };
    case DELETE_USER_FILE:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case RENAME_USER_FILE:
      return state;
    case CLEAR_USER_FILE:
      return {
        ...state,
        fetched: null,
      };
    case CLEAR_USER_FILES:
      return {
        ...state,
        docs: [],
      };
    case DELETE_USER_FOLDER:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case ADD_USER_FOLDER:
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: Date.now() + i++,
            itemId: action.payload.id,
            name: action.payload.fold_name,
            type: action.payload.type,
            owner: action.payload.department,
            createdAt: action.payload.created_at,
            created_by: action.payload.created_by,
          },
        ],
      };
    case GET_USER_FOLDER_DATA:
      return {
        ...state,
        items: [
          ...action.payload.folders.map(mapFolders),
          ...action.payload.storage.map(mapFiles),
        ],
        openedFolderId: action.payload.folder_id,
        openedFolderName: action.payload.folder_name,
        parentFolderId: action.payload.parent_folder_id,
      };
    case CLEAR_USER_STORAGE:
      return {
        ...state,
        fetched: null,
        folders: [],
        files: [],
        foldersHierarchy: [],
        openedFolderId: null,
        parentFolderId: null,
        openedFolderName: '',
      };
    case ADD_USER_FOLDER_IN_HIERARCHY:
      return {
        ...state,
        foldersHierarchy: [...state.foldersHierarchy, action.payload],
      };
    case REMOVE_USER_FOLDER_FROM_HIERARCHY:
      return {
        ...state,
        foldersHierarchy: state.foldersHierarchy.filter(
          item => item.id !== action.payload
        ),
      };
    case REMOVE_USER_FOLDERS_FROM_HIERARCHY:
      return {
        ...state,
        foldersHierarchy: state.foldersHierarchy.filter(
          (_, index) => index <= action.payload
        ),
      };
    case CLEAR_USER_FOLDERS_HIERARCHY:
      return {
        ...state,
        foldersHierarchy: [],
      };
    default:
      return state;
  }
}
