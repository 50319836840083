import {
  GET_SALES_PERSON,
  CLEAR_SALES_PERSON,
  GET_SALES_PERSONS,
  DELETE_SALES_PERSON,
  ADD_SALES_PERSON,
  EDIT_SALES_PERSON,
} from '../actions/salesPersonsActions';

const initialState = {
  salesPersons: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_PERSON:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_SALES_PERSON:
      return {
        ...state,
        fetched: null,
      };
    case GET_SALES_PERSONS:
      return {
        ...state,
        salesPersons: action.payload,
      };
    case DELETE_SALES_PERSON:
      return {
        ...state,
        salesPersons: state.salesPersons.filter(
          salesPerson => salesPerson.id !== action.payload
        ),
      };
    case ADD_SALES_PERSON:
      return {
        ...state,
        salesPersons: [...state.salesPersons, action.payload],
      };
    case EDIT_SALES_PERSON:
      return {
        ...state,
      };
    default:
      return state;
  }
};
