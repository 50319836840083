import {
  GET_PRO_INVOICE,
  CLEAR_PRO_INVOICE,
  GET_PRO_INVOICES,
  DELETE_PRO_INVOICE,
  ADD_PRO_INVOICE,
  EDIT_PRO_INVOICE,
  GET_PRO_INVOICE_DOCS,
  ADD_PRO_INVOICE_DOCS,
  DELETE_PRO_INVOICE_DOC,
  EDIT_PRO_INVOICE_NOTE,
  DELETE_PRO_INVOICE_NOTE,
  PRO_INVOICE_LOADING,
  PRO_INVOICE_LOADED,
  GET_PRO_INVOICES_LIST,
  CANCEL_PRO_INVOICE,
} from '../actions/proInvoicesActions';

const initialState = {
  count: null,
  proInvoicesList: [],
  proInvoices: [],
  currentProInvoiceDocs: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRO_INVOICE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CANCEL_PRO_INVOICE:
      return {
        ...state,
        fetched: action.payload,
      };

    case CLEAR_PRO_INVOICE:
      return {
        ...state,
        fetched: null,
      };
    case GET_PRO_INVOICES_LIST:
      return {
        ...state,
        count: action.payload.count,
        proInvoicesList: action.payload.results,
      };
    case GET_PRO_INVOICES:
      return {
        ...state,
        count: action.payload.count,
        proInvoices: action.payload.results,
      };
    case DELETE_PRO_INVOICE:
      return {
        ...state,
        proInvoicesList: state.proInvoicesList.filter(
          proInvoice => proInvoice.id !== action.payload
        ),
      };
    case ADD_PRO_INVOICE:
      return {
        ...state,
        proInvoicesList: [...state.proInvoicesList],
      };
    case EDIT_PRO_INVOICE:
      return {
        ...state,
        // proInvoices: [...state.proInvoices, action.payload],
      };
    case GET_PRO_INVOICE_DOCS:
      return {
        ...state,
        currentProInvoiceDocs: action.payload,
      };
    case ADD_PRO_INVOICE_DOCS:
      return {
        ...state,
        currentProInvoiceDocs: [...state.currentProInvoiceDocs, action.payload],
      };
    case DELETE_PRO_INVOICE_DOC:
      return {
        ...state,
        currentProInvoiceDocs: state.currentProInvoiceDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case EDIT_PRO_INVOICE_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            pro_invoice_notes: state.fetched.pro_invoice_notes.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_PRO_INVOICE_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            pro_invoice_notes: state.fetched.pro_invoice_notes.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    case PRO_INVOICE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PRO_INVOICE_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
