import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
// import SingleSubCategory from './SingleSubCategory';
// import {
//   OPERTIONS_VIP_DEPT,
//   OPERTIONS_CORP_DEPT,
//   OPERTIONS_TICKETING_DEPT,
//   // OPERTIONS_DEPT,
//   // OPERATIONS_STAFF,
//   // OPERTIONS_MICE_DEPT,
// } from '../../../utils/constants';

function OperationSidebar({ handleClick }) {
  // const { department } = useSelector(state => state.auth.user.profile);
  const {
    auth: {
      user: {
        hasEmployeeLeavesAccess,
        hasActivitiesAccess,
        hasAccountsAccess,
        hasCustomersAccess,
        hasQuotationsAccess,
        hasProInoviceAccess,
        hasInvoicesAccess,
        hasSupplierAccess,
        hasPurOrderAccess,
        hasBillsAccess,
        hasAnnouncementAccess,
        hasEventCalenderAccess,
        hasEventCoordinatorsAccess,
        hasRequestedAccess,
        hasConfirmedAccess,
        hasFinishedAccess,
        hasCancelledAccess,
        hasServiceRequestsAccess,
        hasCurrenciesAccess,
        hasJournalsAccess,
        hasTaxPaymentsAccess,
        hasCOAAccess,
        hasTrLockingAccess,
      },
      permission,
    },
  } = useSelector(state => state);
  // const showSalesSideBar = [
  //   OPERTIONS_VIP_DEPT,
  //   OPERTIONS_CORP_DEPT,
  //   OPERTIONS_TICKETING_DEPT,
  // ].includes(department);

  // const isOpDepartment = [
  //   OPERTIONS_DEPT,
  //   OPERATIONS_STAFF,
  //   OPERTIONS_MICE_DEPT,
  // ].includes(department);

  const section = permission ? permission.section_access : {};

  return (
    <>
      <ul className="sidebar__block">
        <SidebarLink
          title="Dashboard"
          icon="tachometer-alt"
          route="/"
          onClick={handleClick}
        />
      </ul>
      <ul className="sidebar__block">
        {section.Items && (
          <SidebarLink
            title="Items"
            icon="shopping-basket"
            route="/pages/accounting/items"
            onClick={handleClick}
          />
        )}

        {section.Sales && (
          <SidebarCategory title="Sales" icon="gifts">
            {hasAccountsAccess && (
              <SidebarLink
                title="Accounts"
                // icon="building"
                route="/pages/accounting/sales/accounts"
                onClick={handleClick}
              />
            )}
            {hasCustomersAccess && (
              <SidebarLink
                title="Customers"
                // icon="users"
                route="/pages/accounting/sales/customers"
                onClick={handleClick}
              />
            )}
            {hasQuotationsAccess && (
              <SidebarLink
                title="Quotations"
                route="/pages/accounting/sales/quotations"
                onClick={handleClick}
              />
            )}
            {hasProInoviceAccess && (
              <SidebarLink
                title="Proforma Invoices"
                route="/pages/accounting/sales/proInvoices"
                onClick={handleClick}
              />
            )}
            {hasInvoicesAccess && (
              <SidebarLink
                title="Invoices"
                route="/pages/accounting/sales/invoices"
                onClick={handleClick}
              />
            )}
          </SidebarCategory>
        )}

        {section.Purchases && (
          <SidebarCategory title="Purchases" icon="shopping-bag">
            {hasSupplierAccess && (
              <SidebarLink
                title="Suppliers"
                route="/pages/accounting/purchases/suppliers"
                onClick={handleClick}
              />
            )}

            {hasPurOrderAccess && (
              <SidebarLink
                title="Purchase Orders"
                route="/pages/accounting/purchases/purOrders"
                onClick={handleClick}
              />
            )}
            {hasBillsAccess && (
              <SidebarLink
                title="Bills"
                route="/pages/accounting/purchases/bills"
                onClick={handleClick}
              />
            )}
          </SidebarCategory>
        )}

        {section['Event Management'] && (
          <SidebarCategory isOpen title="Event Management" icon="tasks">
            {hasEventCalenderAccess && (
              <SidebarLink
                title="Event Calendar"
                route="/pages/operations/eventCalendar"
                onClick={handleClick}
              />
            )}
            {hasEventCoordinatorsAccess && (
              <SidebarLink
                title="Event Coordinators"
                route="/pages/operations/eventCoordinator"
                onClick={handleClick}
              />
            )}
            {hasRequestedAccess && (
              <SidebarLink
                title="Requested"
                route="/pages/operations/requested"
                onClick={handleClick}
              />
            )}
            {hasConfirmedAccess && (
              <SidebarLink
                title="Confirmed"
                route="/pages/operations/confirmed"
                onClick={handleClick}
              />
            )}
            {hasFinishedAccess && (
              <SidebarLink
                title="Finished"
                route="/pages/operations/finished"
                onClick={handleClick}
              />
            )}
            {hasCancelledAccess && (
              <SidebarLink
                title="Cancelled"
                route="/pages/operations/cancelled"
                onClick={handleClick}
              />
            )}
            {hasServiceRequestsAccess && (
              <SidebarLink
                title="Service Requests"
                route="/pages/operations/serviceRequests"
                onClick={handleClick}
              />
            )}
          </SidebarCategory>
        )}
        {section.Accountant && (
          <SidebarCategory title="Accountant" icon="user-tie">
            {hasCurrenciesAccess && (
              <SidebarLink
                title="Currencies"
                route="/pages/accounting/accountant/currencies"
                onClick={handleClick}
              />
            )}
            {hasJournalsAccess && (
              <SidebarLink
                title="Manual Journals"
                route="/pages/accounting/accountant/journals"
                onClick={handleClick}
              />
            )}
            {hasTaxPaymentsAccess && (
              <SidebarLink
                title="Tax Payments"
                route="/pages/accounting/accountant/taxPayments"
                onClick={handleClick}
              />
            )}
            {hasCOAAccess && (
              <SidebarLink
                title="Chart of Accounts"
                route="/pages/accounting/accountant/chartOfAccounts"
                onClick={handleClick}
              />
            )}
            {hasTrLockingAccess && (
              <SidebarLink
                title="Transaction Locking"
                route="/pages/accounting/accountant/transactionLocking"
                onClick={handleClick}
              />
            )}
          </SidebarCategory>
        )}

        {section.Meetings && (
          <SidebarLink
            title="Meetings"
            route="/pages/manageInternalMeetings"
            onClick={handleClick}
            icon="handshake"
          />
        )}
        {section.Storage && (
          <SidebarLink
            icon="database"
            title="Storage"
            route="/pages/storage/main"
            onClick={handleClick}
          />
        )}
        {/* {!isOperationStaff && ( */}
        {section.Miscellaneous && (
          <SidebarCategory title="Miscellaneous" icon="fas fa-layer-group">
            {hasEmployeeLeavesAccess && (
              <SidebarLink
                title="Employee Leaves"
                route="/pages/hr/leaves"
                onClick={handleClick}
              />
            )}
            {hasActivitiesAccess && (
              <SidebarLink
                title="Activities"
                route="/pages/manageActivities"
                onClick={handleClick}
              />
            )}
            {hasAnnouncementAccess && (
              <SidebarLink
                title="Announcements"
                route="/pages/announcements"
                onClick={handleClick}
              />
            )}
          </SidebarCategory>
        )}
        {section.Trash && (
          <SidebarLink
            icon="trash-alt"
            title="Trash"
            route="/pages/storage/trash"
            onClick={handleClick}
          />
        )}
      </ul>
    </>
  );
}

OperationSidebar.propTypes = {
  handleClick: PropTypes.func.isRequired,
  // isOperationStaff: PropTypes.bool.isRequired,
};

export default OperationSidebar;
