import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import reduxThunk from 'redux-thunk';
import {
  sidebarReducer,
  uiReducer,
  rtlReducer,
  documentsReducer,
  lookupsReducer,
  clientsReducer,
  errorsReducer,
  messagesReducer,
  authReducer,
  usersReducer,
  itemsReducer,
  currenciesReducer,
  exchangeRatesReducer,
  chartOfAccountsReducer,
  journalsReducer,
  bankingReducer,
  customerReducer,
  salesAccountsReducer,
  salesContactsReducer,
  suppliersReducer,
  supplierContactsReducer,
  quotationsReducer,
  proInvoicesReducer,
  invoicesReducer,
  paymentsReceivedReducer,
  creditNotesReducer,
  purOrdersReducer,
  paymentTermsReducer,
  paymentModesReducer,
  salesPersonsReducer,
  expensesReducer,
  taxRatesReducer,
  billsReducer,
  paymentsMadeReducer,
  supplierCreditsReducer,
  currencyAdjustmentReducer,
  leavesReducer,
  salaryReducer,
  companyFilesReducer,
  userFilesReducer,
  assetsLogReducer,
  docsRequestReducer,
  companyProfileReducer,
  storageReducer,
  finalPayReducer,
  companyHolidaysReducer,
  companyActivityReducer,
  announcementsReducer,
  internalMeetingsReducer,
  trashReducer,
  transactionsLockingReduer,
  taxReturnReducer,
  reportsReducer,
  dashboardReducer,
  operationsReducer,
  emailSettingsReducer,
  userPermissionReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  ui: uiReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  documents: documentsReducer,
  lookups: lookupsReducer,
  clients: clientsReducer,
  errors: errorsReducer,
  messages: messagesReducer,
  auth: authReducer,
  users: usersReducer,
  leaves: leavesReducer,
  employeeDocsRequests: docsRequestReducer,
  salaries: salaryReducer,
  companyProfiles: companyProfileReducer,
  companyFiles: companyFilesReducer,
  companyHolidays: companyHolidaysReducer,
  companyActivities: companyActivityReducer,
  internalMeetings: internalMeetingsReducer,
  announcements: announcementsReducer,
  userFiles: userFilesReducer,
  assetsLog: assetsLogReducer,
  finalPay: finalPayReducer,
  items: itemsReducer,
  currencies: currenciesReducer,
  currencyAdjustments: currencyAdjustmentReducer,
  exchangeRates: exchangeRatesReducer,
  chartOfAccounts: chartOfAccountsReducer,
  journals: journalsReducer,
  banking: bankingReducer,
  customers: customerReducer,
  salesAccounts: salesAccountsReducer,
  salesContacts: salesContactsReducer,
  suppliers: suppliersReducer,
  supplierContacts: supplierContactsReducer,
  expenses: expensesReducer,
  purOrders: purOrdersReducer,
  bills: billsReducer,
  paymentsMade: paymentsMadeReducer,
  supplierCredits: supplierCreditsReducer,
  quotations: quotationsReducer,
  proInvoices: proInvoicesReducer,
  invoices: invoicesReducer,
  paymentsReceived: paymentsReceivedReducer,
  creditNotes: creditNotesReducer,
  paymentTerms: paymentTermsReducer,
  paymentModes: paymentModesReducer,
  salesPersons: salesPersonsReducer,
  taxRates: taxRatesReducer,
  storage: storageReducer,
  trash: trashReducer,
  transactionLocking: transactionsLockingReduer,
  taxReturns: taxReturnReducer,
  reports: reportsReducer,
  dashboard: dashboardReducer,
  operations: operationsReducer,
  emailSettings: emailSettingsReducer,
  userAccess: userPermissionReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

export default store;
