import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  DISABLE_GLOBAL_LOADER,
  ENABLE_GLOBAL_LOADER,
} from '../actions/uiActions';

const initialState = {
  className: 'theme-light',
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { ...state, className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { ...state, className: 'theme-light' };
    case ENABLE_GLOBAL_LOADER:
      return { ...state, isLoading: true };
    case DISABLE_GLOBAL_LOADER:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
