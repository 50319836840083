import {
  GET_SUPPLIER_CONTACT,
  ADD_SUPPLIER_CONTACT,
  CLEAR_SUPPLIER_CONTACT,
  GET_SUPPLIER_CONTACTS,
  DELETE_SUPPLIER_CONTACT,
  EDIT_SUPPLIER_CONTACT,
} from '../actions/supplierContactsActions';

const initialState = {
  contacts: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIER_CONTACT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_SUPPLIER_CONTACT:
      return {
        ...state,
        fetched: null,
        contacts: [],
      };
    case ADD_SUPPLIER_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      };
    case GET_SUPPLIER_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case DELETE_SUPPLIER_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter(
          supplier => supplier.id !== action.payload
        ),
      };
    case EDIT_SUPPLIER_CONTACT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
