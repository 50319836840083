import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { CLEAR_CURRENCY, DELETE_RATE_FROM_CURRENCY } from './currenciesActions';

export const GET_EXCHANGE_RATE = 'GET_EXCHANGE_RATE';
export const CLEAR_EXCHANGE_RATE = 'CLEAR_EXCHANGE_RATE';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const ADD_EXCHANGE_RATE = 'ADD_EXCHANGE_RATE';
export const EDIT_EXCHANGE_RATE = 'EDIT_EXCHANGE_RATE';
export const DELETE_EXCHANGE_RATE = 'DELETE_EXCHANGE_RATE';
export const GET_EXCHANGE_RATE_BY_CURRENCY_ID =
  'GET_EXCHANGE_RATE_BY_CURRENCY_ID';

// GET EXCHANGE RATE
export const getExchangeRate = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/currencies/exchangeRates/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_EXCHANGE_RATE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// CLEAR EXCHANGE RATE
export const clearExchangeRate = () => {
  return {
    type: CLEAR_EXCHANGE_RATE,
  };
};

// GET EXCHANGE RATES
export const getExchangeRates = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/currencies/exchangeRates/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_EXCHANGE_RATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE EXCHANGE RATE
export const deleteExchangeRate = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/currencies/exchangeRates/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Exchange Rate Deleted' }));
    dispatch({ type: DELETE_EXCHANGE_RATE, payload: id });
    dispatch({ type: DELETE_RATE_FROM_CURRENCY, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD EXCHANGE RATE
export const addExchangeRate = values => async (dispatch, getState) => {
  const exchangeRate = {
    currency_id: values.currency_id,
    effective_date:
      values.effective_date && values.effective_date !== ''
        ? moment(values.effective_date).format('YYYY-MM-DD')
        : null,
    rate: values.rate,
  };
  try {
    await axios.post(
      `${API_URL}/api/accounting/accountant/currencies/exchangeRates/`,
      exchangeRate,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Exchange Rate Created' }));
    dispatch({ type: CLEAR_CURRENCY });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT EXCHANGE RATE
export const editExchangeRate = values => async (dispatch, getState) => {
  const exchangeRate = {
    currency_id: values.currency_id,
    effective_date:
      values.effective_date && values.effective_date !== ''
        ? moment(values.effective_date).format('YYYY-MM-DD')
        : null,
    rate: values.rate,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/currencies/exchangeRates/${values.id}/`,
      exchangeRate,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Exchange Rate Updated' }));
    dispatch({ type: EDIT_EXCHANGE_RATE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET EXCHANGE RATES OF SPECIFIC CURRENCY
export const getExchangeRateOfCurrency =
  (currencyId, date = '') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/accountant/currencies/${currencyId}/exchangeRates?from_date=${date}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_EXCHANGE_RATE_BY_CURRENCY_ID,
        payload: res.data,
      });
    } catch (err) {
      dispatch(clearExchangeRate());
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
