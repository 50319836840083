/* eslint-disable max-len */
export default function getEmailTemplate({
  salutation,
  message,
  heading,
  details,
  currencySymbol,
}) {
  return `
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Email Template</title>
      </head>
      <body style="margin: 0; padding: 10px 20px;">
        <div style=" max-width: 500px; margin: 20px auto; background-color: #f4f4ff; padding: 30px; border-radius: 8px; box-shadow: 0px 0px 10px 0px darkgrey;font-family: Arial, Helvetica, sans-serif">
          <div style="display: flex; width: 100%;">
            <div style="border-bottom: 5px solid #dfc369; width: 40%;"></div>
              <div style="width: 20%; padding: 0 10px;">
                <img style="width: 100%;" src="https://crm-application-storages.s3.ap-south-1.amazonaws.com/media/logo/Logo-bg.png" alt="logo" />
              </div>
            <div style="border-bottom: 5px solid #dfc369; width: 40%;"></div>
          </div>
          <div style="display: flex;flex-direction: column; margin: 30px 0 10px 0;">
            <div style="text-align: justify;">
              <p>Dear <span style="font-weight: 600;">${salutation}</span>,</p>
              <p>${message}</p>
             </div>
          </div>
          ${
            heading && details.length > 0
              ? `
                  <div style="background-color: black; padding: 10px 0; width: 92%; margin: 0 auto;">
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                    <table style="width:92%; margin: 0 auto; border-collapse: collapse;" >
                      <tbody >
                        <tr >
                          <td>
                            <h5 style="color:#dfc369; padding:10px; margin: 0; font-size: 18px;">
                              ${heading.documentName}
                            </h5>
                          </td>
                          <td>
                            <h5 style="color:#dfc369; padding:10px; margin: 0; font-size: 18px; text-align: right;">
                              ${heading.number}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                    <table style="width:92%; margin: 0 auto; border-collapse: collapse;" >
                      <tbody >
                        ${details
                          .map(
                            item => `<tr>${
                              item.value !== false && item.value !== null
                                ? `<td style="padding: 10px 0;">
                                <p style="margin: 0;color: white; padding-left: 10px; text-align: left;">${item.key}</p>
                              </td>
                              <td style="padding: 10px 0;">
                                  <p class="price" style="margin: 0;text-align: right; padding-right: 10px; ">
                                      <span style="color: #dfc369;">${currencySymbol}</span>
                                      <span style="color: white">${item.value}</span>
                                  </p>
                              </td>`
                                : ''
                            }
                            </tr>`
                          )
                          .join('')}
                        </tbody>
                    </table>
                    <div style="width: 90%; border-bottom: 2px dashed white; margin: 0 auto;"></div>
                  </div>
                `
              : ''
          }
        <div style="margin-top: 20px;">
          <p style="line-height: 30px;">
            Regards,
            <br />
            Luxury Events and VIP Travel DMCC
          </p>
        </div>
      </div>
    </body>
  </html>
    `;
}
