import axios from 'axios';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';
import { returnErrors } from './messagesActions';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';

const getAccountPayload = accounts => {
  const selectedAccounts = [...accounts]
    .filter(account => account.is_email_allowed === true)
    .map(account => account.id);
  return {
    accounts,
    selectedAccounts,
  };
};

export const getAccounts = query => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/accounts`,
      {
        ...tokenConfig(getState),
        params: query,
      }
    );

    const payload = getAccountPayload(res.data.results);

    dispatch({
      type: GET_ACCOUNTS,
      payload,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const updateAccounts = data => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/allow_email`,
      data,
      tokenConfig(getState)
    );

    const payload = getAccountPayload(res.data);

    dispatch({
      type: UPDATE_ACCOUNTS,
      payload,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};
