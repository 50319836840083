import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from './Icons/InfoIcon';
import SuccessIcon from './Icons/SuccessIcon';
import ErrorIcon from './Icons/ErrorIcon';

const AlertTemplate = ({ message, options, style, close }) => {
  let Icon = <span className="lnr lnr-flag modal__title-icon" />;
  let heading = '';

  if (options.type === 'success') {
    Icon = <SuccessIcon />;
    heading = 'Success';
  } else if (options.type === 'error') {
    Icon = <ErrorIcon />;
    heading = 'Error';
  } else if (options.type === 'info') {
    Icon = <InfoIcon />;
    heading = 'Info';
  }

  return (
    <div className="modal-content error-template" style={{ ...style }}>
      <div className="modal-dialog--warning">
        <div className="modal__header py-2">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={close}
          />
          {Icon}
          <h5 className="text-modal modal__title mb-0">{heading}!</h5>
        </div>
        <div className="modal__body" style={{ fontSize: 12 }}>
          {message}
        </div>
      </div>
    </div>
  );
};

AlertTemplate.propTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default AlertTemplate;
