import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { returnErrors, createMessage } from './messagesActions';

export const GET_TAX_RATES = 'GET_TAX_RATES';
export const MODIFY_TAX_RATES = 'MODIFY_TAX_RATES';
export const CHANGE_DEFAULT_TAX_RATE = 'CHANGE_DEFAULT_TAX_RATE';
export const ADD_TAX_RATE = 'ADD_TAX_RATE';
export const CLEAR_TAX_RATES = 'CLEAR_TAX_RATES';

// CLEAR TAX RATES
export const clearTaxRates = () => ({ type: CLEAR_TAX_RATES });

// Get Tax Rates
export const getTaxRates = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/taxRates/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TAX_RATES,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Add Tax Rate
export const addTaxRate = values => async (dispatch, getState) => {
  const taxRate = {
    tax_rate_name: values.tax_rate_name,
    tax_rate_perc: values.tax_rate_perc,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/taxRates/`,
      taxRate,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Tax Rate Added' }));
    dispatch({
      type: ADD_TAX_RATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Configure Tax Rate
export const configureTaxRates = values => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/taxRates/all`,
      values,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Tax Rate Modified' }));
    dispatch({
      type: MODIFY_TAX_RATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Change Default Tax Rate
export const changeDefaultTaxRate = modifiedtaxRates => ({
  type: CHANGE_DEFAULT_TAX_RATE,
  payload: modifiedtaxRates,
});
