/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

class SidebarCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    location: PropTypes.object.isRequired,
    // added to open event Mangament dropdown by default.
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
    isOpen: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapse: props.isOpen,
    };
  }

  componentDidMount() {
    const { children } = this.props;

    this.iterateChildren(children);
  }

  componentDidUpdate(prevProps) {
    const { location, children } = this.props;
    if (!isEqual(prevProps.location, location)) {
      this.setState({ collapse: this.handleCollapse(children) });
    }
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  handleCollapse = children => {
    const { location } = this.props;
    let hasActiveChild = false;
    React.Children.toArray(children)
      .filter(Boolean)
      .forEach(item => {
        // Check if any of its child' route matches
        if (location.pathname.includes(item.props.route)) {
          hasActiveChild = true;
          // If not its child then go for its subchild
        } else if (item.props.children) {
          const temp = this.handleCollapse(item.props.children);
          // If any of subchild is active just in then case set TRUE
          if (temp) hasActiveChild = true;
        }
      });
    return hasActiveChild;
  };

  iterateChildren = children => {
    const { location } = this.props;
    React.Children.toArray(children)
      .filter(Boolean)
      .forEach(item => {
        if (item.props.children) {
          this.iterateChildren(item.props.children);
        } else if (location.pathname.includes(item.props.route)) {
          this.setState({ collapse: true });
        }
      });
  };

  render() {
    const { title, icon, isNew, children } = this.props;
    const { collapse } = this.state;

    return (
      <div
        className={
          collapse
            ? 'sidebar__category-wrap sidebar__category-wrap--open'
            : 'sidebar__category-wrap'
        }
      >
        <button
          type="button"
          className="sidebar__link sidebar__category"
          onClick={this.toggle}
        >
          {icon ? <span className={`sidebar__link-icon fas fa-${icon}`} /> : ''}
          <p className="sidebar__link-title">
            {title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>{children}</div>
          </ul>
        </Collapse>
      </div>
    );
  }
}

export default withRouter(SidebarCategory);
