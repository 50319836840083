import {
  GET_PAYMENT_RECEIVED,
  CLEAR_PAYMENT_RECEIVED,
  GET_PAYMENTS_RECEIVED,
  DELETE_PAYMENT_RECEIVED,
  ADD_PAYMENT_RECEIVED,
  EDIT_PAYMENT_RECEIVED,
  GET_PAYMENT_RECEIVED_DOCS,
  ADD_PAYMENT_RECEIVED_DOCS,
  DELETE_PAYMENT_RECEIVED_DOC,
  GET_PAYMENT_RECEIVED_JOURNAL,
  CLEAR_PAYMENT_RECEIVED_JOURNAL,
  PAYMENT_RECEIVED_LOADING,
  PAYMENT_RECEIVED_LOADED,
  REFUND_PAYAMENT_RECEIVED,
  GET_PAYMENT_RECEIVED_REFUNDS,
  GET_PAYMENTS_RECEIVED_LIST,
} from '../actions/paymentsReceivedActions';

const initialState = {
  count: null,
  paymentsReceivedList: [],
  paymentsReceived: [],
  currentPaymentReceivedDocs: [],
  paymentsReceivedJournal: [],
  refundPayment: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_RECEIVED:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_PAYMENT_RECEIVED:
      return {
        ...state,
        fetched: null,
      };
    case GET_PAYMENTS_RECEIVED_LIST:
      return {
        ...state,
        count: action.payload.count,
        paymentsReceivedList: action.payload.results,
      };
    case GET_PAYMENTS_RECEIVED:
      return {
        ...state,
        count: action.payload.count,
        paymentsReceived: action.payload.results,
      };
    case DELETE_PAYMENT_RECEIVED:
      return {
        ...state,
        paymentsReceivedList: state.paymentsReceivedList.filter(
          paymentReceived => paymentReceived.id !== action.payload
        ),
      };
    case ADD_PAYMENT_RECEIVED:
      return {
        ...state,
        paymentsReceivedList: [...state.paymentsReceivedList],
      };
    case EDIT_PAYMENT_RECEIVED:
      return {
        ...state,
      };
    case GET_PAYMENT_RECEIVED_DOCS:
      return {
        ...state,
        currentPaymentReceivedDocs: action.payload,
      };
    case ADD_PAYMENT_RECEIVED_DOCS:
      return {
        ...state,
        currentPaymentReceivedDocs: [
          ...state.currentPaymentReceivedDocs,
          action.payload,
        ],
      };
    case DELETE_PAYMENT_RECEIVED_DOC:
      return {
        ...state,
        currentPaymentReceivedDocs: state.currentPaymentReceivedDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case GET_PAYMENT_RECEIVED_JOURNAL:
      return {
        ...state,
        paymentsReceivedJournal: action.payload,
      };
    case CLEAR_PAYMENT_RECEIVED_JOURNAL:
      return {
        ...state,
        paymentsReceivedJournal: [],
      };
    case PAYMENT_RECEIVED_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PAYMENT_RECEIVED_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case REFUND_PAYAMENT_RECEIVED:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_PAYMENT_RECEIVED_REFUNDS:
      return {
        ...state,
        refundPayment: action.payload,
      };
    default:
      return state;
  }
};
