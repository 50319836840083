export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';
export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY =
  'CHANGE_MOBILE_SIDEBAR_VISIBILITY';

export function changeSidebarVisibility() {
  return {
    type: CHANGE_SIDEBAR_VISIBILITY,
  };
}

export function changeMobileSidebarVisibility() {
  return {
    type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
  };
}

export function collapseSideBar() {
  return {
    type: COLLAPSE_SIDEBAR,
  };
}

export function expandSideBar() {
  return {
    type: EXPAND_SIDEBAR,
  };
}
