import {
  GET_PUR_ORDER,
  CLEAR_PUR_ORDER,
  GET_PUR_ORDERS,
  DELETE_PUR_ORDER,
  ADD_PUR_ORDER,
  EDIT_PUR_ORDER,
  EDIT_PUR_ORDER_NOTE,
  DELETE_PUR_ORDER_NOTE,
  GET_PUR_ORDER_DOCS,
  ADD_PUR_ORDER_DOCS,
  DELETE_PUR_ORDER_DOC,
  PUR_ORDERS_LOADING,
  PUR_ORDERS_LOADED,
  GET_PUR_ORDERS_LIST,
  GET_PUR_ORDERS_BY_SUPPLIER,
  CLEAR_SUPPLIER_PUR_ORDERS,
} from '../actions/purOrdersActions';

const initialState = {
  count: null,
  purOrdersList: [],
  purOrders: [],
  supplierPurOrders: [],
  docs: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PUR_ORDER:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_PUR_ORDER:
      return {
        ...state,
        fetched: null,
      };
    case GET_PUR_ORDERS_LIST:
      return {
        ...state,
        count: action.payload.count,
        purOrdersList: action.payload.results,
      };
    case GET_PUR_ORDERS:
      return {
        ...state,
        count: action.payload.count,
        purOrders: action.payload.results,
      };
    case GET_PUR_ORDERS_BY_SUPPLIER:
      return {
        ...state,
        supplierPurOrders: action.payload,
      };
    case DELETE_PUR_ORDER:
      return {
        ...state,
        purOrdersList: state.purOrdersList.filter(
          purOrder => purOrder.id !== action.payload
        ),
      };
    case ADD_PUR_ORDER:
      return {
        ...state,
        purOrdersList: [...state.purOrdersList],
      };
    case EDIT_PUR_ORDER:
      return {
        ...state,
        // purOrders: [...state.purOrders, action.payload],
      };
    case EDIT_PUR_ORDER_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            pur_order_notes: state.fetched.pur_order_notes.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_PUR_ORDER_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            pur_order_notes: state.fetched.pur_order_notes.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    case GET_PUR_ORDER_DOCS:
      return {
        ...state,
        docs: action.payload,
      };
    case ADD_PUR_ORDER_DOCS:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      };
    case DELETE_PUR_ORDER_DOC:
      return {
        ...state,
        docs: state.docs.filter(doc => doc.id !== action.payload),
      };
    case PUR_ORDERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PUR_ORDERS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_SUPPLIER_PUR_ORDERS:
      return {
        ...state,
        supplierPurOrders: [],
      };
    default:
      return state;
  }
};
