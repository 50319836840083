import {
  DELETE_DOC_REQUEST,
  GET_FILTERED_DOC_REQUESTS,
  GET_DOC_REQUESTS,
  CLEAR_DOC_REQUESTS,
  GET_USER_DOC_REQUESTS,
  GET_DOC_REQUEST_HISTORY,
  CLEAR_DOC_REQUEST_HISTORY,
  UPLOAD_REQUESTED_DOCUMENT,
  REQUEST_FOR_DOC,
} from '../actions/docsRequestActions';

const initialState = {
  docsRequests: [],
  docsHistory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOC_REQUESTS:
      return {
        ...state,
        docsRequests: action.payload,
      };
    case GET_DOC_REQUEST_HISTORY:
      return {
        ...state,
        docsHistory: action.payload,
      };
    case CLEAR_DOC_REQUEST_HISTORY:
      return {
        ...state,
        docsHistory: [],
      };
    case GET_USER_DOC_REQUESTS:
      return {
        ...state,
        docsRequests: action.payload,
      };
    case GET_FILTERED_DOC_REQUESTS:
      return {
        ...state,
        docsRequests: action.payload,
      };
    case UPLOAD_REQUESTED_DOCUMENT:
      return {
        ...state,
        docsRequests: state.docsRequests.map(record =>
          record.id === action.payload.id ? action.payload : record
        ),
      };
    case DELETE_DOC_REQUEST:
      return {
        ...state,
        docsRequests: state.docsRequests.filter(
          request => request.id !== action.payload
        ),
      };
    case REQUEST_FOR_DOC:
      return {
        ...state,
        docsRequests: [...state.docsRequests, action.payload],
        docsHistory: [...state.docsHistory, action.payload],
      };
    case CLEAR_DOC_REQUESTS:
      return {
        docsRequests: [],
      };
    default:
      return state;
  }
};
