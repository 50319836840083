import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

export const USAGE_VALUE = 'usage_value';
export const QUANTITY = 'quantity';

export const DEFAULT_CLASSFICATION_BASIS = USAGE_VALUE;
export const DEFAULT_CLASS_A_VALUE = 50;
export const DEFAULT_CLASS_B_VALUE = 30;
export const DEFAULT_CLASS_C_VALUE = 20;

function useABCClassificationCustomFilter() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const initialValues = {
    classification_basis:
      query.get('classification_basis') || DEFAULT_CLASSFICATION_BASIS,
    class_a: query.get('class_a') || DEFAULT_CLASS_A_VALUE,
    class_b: query.get('class_b') || DEFAULT_CLASS_B_VALUE,
    class_c: query.get('class_c') || DEFAULT_CLASS_C_VALUE,
  };

  function validateClassesTotal() {
    const { class_a: classA, class_b: classB, class_c: classC } = this.parent;
    return classA + classB + classC === 100;
  }

  const validationSchema = {
    classification_basis: Yup.string(),
    class_a: Yup.number()
      .typeError('Must be a number')
      .min(0, 'Must be greater than or equal to 0')
      .integer('Decimals not allowed')
      .test(
        'TotalCheck',
        'Class percentage total must be 100',
        validateClassesTotal
      ),
    class_b: Yup.number()
      .typeError('Must be a number')
      .min(0, 'Must be greater than or equal to 0')
      .integer('Decimals not allowed')
      .test(
        'TotalCheck',
        'Class percentage total must be 100',
        validateClassesTotal
      ),
    class_c: Yup.number()
      .typeError('Must be a number')
      .min(0, 'Must be greater than or equal to 0')
      .integer('Decimals not allowed')
      .test(
        'TotalCheck',
        'Class percentage total must be 100',
        validateClassesTotal
      ),
  };

  const formatValues = values => {
    return Object.entries(values).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: `${value}` }),
      { ...initialValues }
    );
  };

  return {
    initialValues,
    validationSchema,
    formatValues,
  };
}

export default useABCClassificationCustomFilter;
