/* eslint-disable no-unused-vars */
import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { returnErrors, createMessage } from './messagesActions';

export const GET_PAYMENT_MODES = 'GET_PAYMENT_MODES';
export const MODIFY_PAYMENT_MODES = 'MODIFY_PAYMENT_MODES';
export const CHANGE_DEFAULT_MODE = 'CHANGE_DEFAULT_MODE';

// Get Payment Modes
export const getPaymentModes = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/paymentModes/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PAYMENT_MODES,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Comfigure Payment Modes
export const configurePaymentModes = values => async (dispatch, getState) => {
  const paymentModes = values.payment_modes.map(item => ({
    payment_mode_name: item.payment_mode_name,
    system_name: item.system_name,
    is_default: item.is_default,
  }));
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/paymentModes/all`,
      { payment_modes: paymentModes },
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Modes Modified' }));
    dispatch({
      type: MODIFY_PAYMENT_MODES,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Change Default Mode
export const changeDefaultMode = modifiedPaymentModes => ({
  type: CHANGE_DEFAULT_MODE,
  payload: modifiedPaymentModes,
});
