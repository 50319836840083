import {
  GET_REPORTS,
  CLEAR_REPORTS,
  SET_REPORTS_FILTER,
  GET_VAT_AUDIT_DATA,
  GENERATE_VAT_AUDIT,
  REPORT_LOADING,
  REPORT_LOADED,
  RECORD_EXIST,
  CLEAR_CHECK_RECORD,
} from '../actions/reportsActions';

const initialState = {
  data: null,
  filters: {},
  isRecordExist: false,
  vatAuditData: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAR_REPORTS:
      return {
        ...state,
        data: null,
        vatAuditData: [],
      };
    case SET_REPORTS_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_VAT_AUDIT_DATA:
      return {
        ...state,
        vatAuditData: action.payload,
      };
    case GENERATE_VAT_AUDIT:
      return {
        ...state,
        vatAuditData: [...state.vatAuditData, action.payload],
      };
    case REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REPORT_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case RECORD_EXIST:
      return {
        ...state,
        isRecordExist: action.payload,
      };
    case CLEAR_CHECK_RECORD:
      return {
        ...state,
        isRecordExist: false,
      };
    default:
      return state;
  }
};
