import {
  GET_EXCHANGE_RATE,
  CLEAR_EXCHANGE_RATE,
  GET_EXCHANGE_RATES,
  DELETE_EXCHANGE_RATE,
  EDIT_EXCHANGE_RATE,
  GET_EXCHANGE_RATE_BY_CURRENCY_ID,
} from '../actions/exchangeRatesActions';

const initialState = {
  exchangeRates: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXCHANGE_RATE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_EXCHANGE_RATE:
      return {
        ...state,
        fetched: null,
      };
    case GET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload,
      };
    case DELETE_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRates: state.exchangeRates.filter(
          exchangeRate => exchangeRate.id !== action.payload
        ),
      };
    case EDIT_EXCHANGE_RATE:
      return {
        ...state,
      };
    case GET_EXCHANGE_RATE_BY_CURRENCY_ID:
      return {
        ...state,
        fetched: action.payload,
      };
    default:
      return state;
  }
};
