import axios from 'axios';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';

export const GET_ASSET_LOG = 'GET_ASSET_LOG';
export const CLEAR_ASSET_LOG = 'CLEAR_ASSET_LOG';
export const ADD_ASSET_LOG = 'ADD_ASSET_LOG';
export const DELETE_ASSET_LOG = 'DELETE_ASSET_LOG';
export const EDIT_ASSET_LOG = 'EDIT_ASSET_LOG';

export const getAssetLog = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/AssetLog/asset_log_list/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ASSET_LOG, payload: res.data && res.data[0] });
  } catch (err) {
    if (err && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addAssetLog = values => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/AssetLog/`,
      values,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: ' Asset Log Saved' }));
    dispatch({ type: ADD_ASSET_LOG, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editAssetLog = values => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/AssetLog/${values.id}/`,
      values,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: ' Asset Log Updated' }));
    dispatch({ type: EDIT_ASSET_LOG, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearAssetLog = () => ({ type: CLEAR_ASSET_LOG });
