import GET_ERRORS from './errorsActions';

const CREATE_MESSAGE = 'CREATE_MESSAGE';
export { CREATE_MESSAGE as default };

// CREATE MESSAGE
export const createMessage = msg => {
  return {
    type: CREATE_MESSAGE,
    payload: msg,
  };
};

// RETURN ERRORS
export const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status },
  };
};
