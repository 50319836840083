import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';

export const GET_COMPANY_ACTIVITY = 'GET_COMPANY_ACTIVITY';
export const GET_COMPANY_ACTIVITIES = 'GET_COMPANY_ACTIVITIES';
export const CLEAR_COMPANY_ACTIVITY = 'CLEAR_COMPANY_ACTIVITY';
export const ADD_COMPANY_ACTIVITY = 'ADD_COMPANY_ACTIVITY';
export const DELETE_COMPANY_ACTIVITY = 'DELETE_COMPANY_ACTIVITY';
export const EDIT_COMPANY_ACTIVITY = 'EDIT_COMPANY_ACTIVITY';
export const GET_UPCOMING_ACTIVITIES = 'GET_UPCOMING_ACTIVITIES';

export const getCompanyActivities = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/activities/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_COMPANY_ACTIVITIES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getCompanyUpcomingActivities =
  () => async (dispatch, getState) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/activities/latest/`,
        tokenConfig(getState)
      );
      dispatch({ type: GET_UPCOMING_ACTIVITIES, payload: data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const getCompanyActivity = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/activities/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_COMPANY_ACTIVITY, payload: res.data });
  } catch (err) {
    if (err && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addCompanyActivity = values => async (dispatch, getState) => {
  const payload = {
    start_time: values.start_time
      ? moment(values.start_time, ['h:mm A']).format('HH:mm')
      : '',
    end_time: values.end_time
      ? moment(values.end_time, ['h:mm A']).format('HH:mm')
      : '',
    start_date: values.start_date
      ? moment(values.start_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    end_date: values.end_date
      ? moment(values.end_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    title: values.title,
    description: values.description,
    participants: values.participants,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/activities/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Activity Saved' }));
    dispatch({ type: ADD_COMPANY_ACTIVITY, payload: res.data });
    dispatch(getCompanyUpcomingActivities());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editCompanyActivity = values => async (dispatch, getState) => {
  const payload = {
    start_time: values.start_time
      ? moment(values.start_time, ['h:mm A']).format('HH:mm')
      : '',
    end_time: values.end_time
      ? moment(values.end_time, ['h:mm A']).format('HH:mm')
      : '',
    start_date: values.start_date
      ? moment(values.start_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    end_date: values.end_date
      ? moment(values.end_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    title: values.title,
    description: values.description,
    participants: values.participants,
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/activities/${values.id}/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Activity Updated' }));
    dispatch({ type: EDIT_COMPANY_ACTIVITY, payload: res.data });
    dispatch(getCompanyUpcomingActivities());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const deleteCompanyActivity = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/activities/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Activity Deleted' }));
    dispatch({ type: DELETE_COMPANY_ACTIVITY, payload: id });
    dispatch(getCompanyUpcomingActivities());
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearCompanyActvity = () => ({ type: CLEAR_COMPANY_ACTIVITY });
