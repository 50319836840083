import axios from 'axios';
import { returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const GET_NEWLY_HIRED_USERS = 'GET_NEWLY_HIRED_USERS';
export const GET_LATEST_MEETINGS = 'GET_LATEST_MEETINGS';
export const GET_RECENT_LEAVES = 'GET_RECENT_LEAVES';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export const GET_TOTAL_RECEIVABLE_DATA = 'GET_TOTAL_RECEIVABLE_DATA';
export const GET_TOTAL_PAYABLE_DATA = 'GET_TOTAL_PAYABLE_DATA';
export const GET_INCOME_EXPENSE_DATA = 'GET_INCOME_EXPENSE_DATA';
export const GET_TOP_EXPENSES = 'GET_TOP_EXPENSES';

// Clear Dashboard Data
export const clearDashboardData = () => ({
  type: CLEAR_DASHBOARD_DATA,
});

// Get Newly Hired Users
export const getNewlyHiredEmployees = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/top/three/users`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_NEWLY_HIRED_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getLatestMeetings = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/top/three/meetings`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LATEST_MEETINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getRecentLeaves = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/top/four/leaves`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_RECENT_LEAVES,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getTotalReceivableData = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/report/ar/aging/detail?duration=today&date_type=due_date`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TOTAL_RECEIVABLE_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getTotalPayableData = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/report/ap/aging/details?duration=today&date_type=due_date`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TOTAL_PAYABLE_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getIncomeExpenseData =
  (duration = 'this fiscal year') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/income/expense/dashboard?duration=${duration}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_INCOME_EXPENSE_DATA,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const getTopExpenses =
  (duration = 'this fiscal year') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/top/expenses/dashboard?duration=${duration}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_TOP_EXPENSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
