import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { addActivityLog } from './miscActions';

export const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const GET_CUSTOMERS_BY_SALES_ACCOUNT_ID =
  'GET_CUSTOMERS_BY_SALES_ACCOUNT_ID';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';

// GET CUSTOMERS LIST
export const getCustomersList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/company`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_CUSTOMERS_LIST, payload: res.data });
    dispatch({ type: CUSTOMER_LOADED });
  } catch (err) {
    dispatch({ type: CUSTOMER_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CUSTOMERS
export const getCustomers = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/accounting/sales/company/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_CUSTOMERS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CUSTOMER
export const getCustomer = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/company/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CUSTOMER, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CUSTOMER
export const deleteCustomer = values => async (dispatch, getState) => {
  const newActivityLog = {
    activity_type: 'Customer',
    activity_title: 'Customer Deleted',
    description: `Customer (${values.company_name}) Deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/company/${values.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: ' Customer Deleted' }));
    dispatch({ type: DELETE_CUSTOMER, payload: values.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD CUSTOMER
export const addCustomer = values => async (dispatch, getState) => {
  const companyName = values.company_name.replace(/ +(?= )/g, '').trim();

  const newCustomer = {
    sales_account_id: values.sales_account_id,
    company_name: companyName || null,
    company_full_name: companyName || '',
    company_name_emp: companyName.replace(/\s+/g, '_'),
    company_name_sorting: companyName ? companyName.toLowerCase() : null,
    vat_treatment: values.vat_treatment || null,
    trn: values.trn || null,
    place_of_supply: values.place_of_supply || null,
    category: values.category || null,
    currency: values.currency || null,
    website: values.website || null,
    email: values.email || null,
    phone: values.phone || null,
    fax: values.fax || null,
    address: values.address || null,
    country: values.country || null,
    city: values.city || null,
    city_sorting: values.city ? values.city.toLowerCase() : null,
    zipcode: values.zipcode || null,
    state: values.state || null,
    type: 'customer',
    customer_date: moment(new Date()).format('YYYY-MM-DD'), // added type && customer_date key for transection locking
  };

  if (values.parent) newCustomer.parent = values.parent;

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/company/`,
      newCustomer,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: res.data.id,
      module_num: res.data.id,
      module_name: res.data.company_name,
      activity_type: 'Customer',
      activity_title: 'Customer Added',
      description: `Customer (${res.data.company_name}) created`,
    };
    dispatch(createMessage({ message: 'Customer Added' }));
    dispatch({ type: ADD_CUSTOMER, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    // error message dispatched from view
    if (err && err.response) {
      throw err;
    } else
      dispatch(
        returnErrors(
          { message: ['An Unexpected Error Occured. Please try again later'] },
          500
        )
      );
  }
};

// EDIT CUSTOMER
export const editCustomer = values => async (dispatch, getState) => {
  const companyName = values.company_name.replace(/ +(?= )/g, '').trim();
  const updatedCustomer = {
    sales_account_id: values.sales_account_id,
    company_name: companyName || null,
    company_full_name: companyName || '',
    company_name_emp: companyName.replace(/\s+/g, '_'),
    company_name_sorting: companyName ? companyName.toLowerCase() : null,
    vat_treatment: values.vat_treatment || null,
    trn: values.trn || null,
    place_of_supply: values.place_of_supply || null,
    category: values.category || null,
    currency: values.currency || null,
    website: values.website || null,
    email: values.email || null,
    phone: values.phone || null,
    fax: values.fax || null,
    address: values.address || null,
    country: values.country || null,
    city: values.city || null,
    city_sorting: values.city ? values.city.toLowerCase() : null,
    zipcode: values.zipcode || null,
    state: values.state || null,
    type: 'customer',
    customer_date: moment(new Date()).format('YYYY-MM-DD'), // added type && customer_date key for transection locking
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/company/${values.id}/`,
      updatedCustomer,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: values.id,
      module_num: values.id,
      module_name: res.data.company_name,
      activity_type: 'Customer',
      activity_title: 'Customer Updated',
      description: `Customer (${res.data.company_name}) Updated`,
    };
    dispatch(createMessage({ message: 'Customer Updated' }));
    dispatch({ type: EDIT_CUSTOMER, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET CUSTOMERS BY CUSTOMER ID
export const getCustomersByAccountId = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/company/${id}/customers`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CUSTOMERS_BY_SALES_ACCOUNT_ID, payload: res.data });
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

// CLEAR CUSTOMER
export const clearCustomer = () => ({ type: CLEAR_CUSTOMER });
