import {
  SET_VAT_RETURN_SETTINGS,
  CLEAR_VAT_RETURN_DATA,
  CLEAR_VAT_REPORTS,
  GENERATE_VAT_RETURN_REPORT,
  MARK_REPORT_AS_FILED,
  MARK_REPORT_AS_UNFILED,
  DELETE_VAT_RETURN_REPORT,
  DELETE_PAYMENT_HISTORY_RECORD,
  GET_VAT_RETURN_DETAILS,
  GET_VAT_RETURN_REPORTS,
  GET_VAT_RETURN_DETAIL_TRANSACTIONS,
  GET_PAYMENT_HISTORY,
  CLEAR_TAX_PAYMENTS_DATA,
  RECORD_TAX_PAYMENT,
  GET_VAT_AUDIT_RECORDS,
  VAT_RETURN_LOADING,
  VAT_RETURN_LOADED,
} from '../actions/taxReturnActions';

const initialState = {
  vatReturnSettings: {},
  selectedVATReturnDetails: {},
  vatReturnReports: [],
  vatReturnDetailTransactions: null,
  paymentHistory: [],
  vatAuditRecords: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_VAT_RETURN_DATA:
      return {
        ...state,
        selectedVATReturn: {},
        selectedVATReturnDetails: {},
        vatReturnDetailTransactions: null,
        vatAuditRecords: [],
      };
    case CLEAR_VAT_REPORTS:
      return {
        ...state,
        vatReturnReports: [],
      };
    case CLEAR_TAX_PAYMENTS_DATA:
      return {
        ...state,
        vatReturnReports: [],
        paymentHistory: [],
      };
    case GET_VAT_RETURN_REPORTS:
      return {
        ...state,
        vatReturnReports: action.payload,
      };
    case GET_VAT_RETURN_DETAILS:
      return {
        ...state,
        selectedVATReturnDetails: action.payload,
      };
    case GET_VAT_RETURN_DETAIL_TRANSACTIONS:
      return {
        ...state,
        vatReturnDetailTransactions: action.payload,
      };
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: action.payload,
      };
    case SET_VAT_RETURN_SETTINGS:
      return {
        ...state,
        vatReturnSettings: action.payload,
      };
    case DELETE_VAT_RETURN_REPORT:
      return {
        ...state,
        vatReturnReports: state.vatReturnReports.filter(
          report => report.id !== action.payload
        ),
      };
    case DELETE_PAYMENT_HISTORY_RECORD:
      return {
        ...state,
        paymentHistory: state.paymentHistory.filter(
          item => item.id !== action.payload
        ),
      };
    case GENERATE_VAT_RETURN_REPORT:
      return {
        ...state,
        vatReturnReports: [...state.vatReturnReports, action.payload],
      };
    case MARK_REPORT_AS_FILED:
      return {
        ...state,
        selectedVATReturnDetails: {
          ...state.selectedVATReturnDetails,
          status: 'filed',
          filed_on: action.payload.filed_on,
        },
      };
    case MARK_REPORT_AS_UNFILED:
      return {
        ...state,
        selectedVATReturnDetails: {
          ...state.selectedVATReturnDetails,
          status: 'unfiled',
          filed_on: null,
        },
      };
    case RECORD_TAX_PAYMENT:
      return {
        ...state,
        paymentHistory: [...state.paymentHistory, action.payload],
        vatReturnReports: state.vatReturnReports.map(report =>
          report.id === action.payload.tax_return.id
            ? action.payload.tax_return
            : report
        ),
      };
    case GET_VAT_AUDIT_RECORDS:
      return {
        ...state,
        vatAuditRecords: action.payload,
      };
    case VAT_RETURN_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case VAT_RETURN_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
