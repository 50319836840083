import {
  GET_TAX_RATES,
  ADD_TAX_RATE,
  CHANGE_DEFAULT_TAX_RATE,
  MODIFY_TAX_RATES,
  CLEAR_TAX_RATES,
} from '../actions/taxRatesActions';

const initialState = {
  taxRates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TAX_RATES:
      return {
        ...state,
        taxRates: action.payload,
      };
    case ADD_TAX_RATE:
      return {
        ...state,
        taxRates: [...state.taxRates, action.payload],
      };
    case CHANGE_DEFAULT_TAX_RATE:
      return {
        ...state,
        taxRates: action.payload,
      };
    case MODIFY_TAX_RATES:
      return {
        ...state,
        taxRates: action.payload,
      };
    case CLEAR_TAX_RATES:
      return {
        ...state,
        taxRates: [],
      };
    default:
      return state;
  }
};
