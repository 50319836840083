import {
  CLEAR_ASSET_LOG,
  GET_ASSET_LOG,
  ADD_ASSET_LOG,
  EDIT_ASSET_LOG,
} from '../actions/assetsLogActions';

const initialState = {
  log: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSET_LOG:
      return {
        ...state,
        log: action.payload,
      };
    case CLEAR_ASSET_LOG:
      return {
        ...state,
        log: null,
      };
    case ADD_ASSET_LOG:
    case EDIT_ASSET_LOG:
      return {
        ...state,
        log: action.payload,
      };
    default:
      return state;
  }
}
