import {
  GET_NEWLY_HIRED_USERS,
  GET_LATEST_MEETINGS,
  GET_RECENT_LEAVES,
  CLEAR_DASHBOARD_DATA,
  GET_TOTAL_RECEIVABLE_DATA,
  GET_TOTAL_PAYABLE_DATA,
  GET_INCOME_EXPENSE_DATA,
  GET_TOP_EXPENSES,
} from '../actions/dashboardActions';

const initialState = {
  newlyHiredUsers: [],
  latestMeetings: [],
  recentLeaves: [],
  totalReceivables: null,
  totalPayables: null,
  incomeExpense: null,
  topExpenses: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWLY_HIRED_USERS:
      return {
        ...state,
        newlyHiredUsers: action.payload,
      };
    case GET_LATEST_MEETINGS:
      return {
        ...state,
        latestMeetings: action.payload,
      };
    case GET_RECENT_LEAVES:
      return {
        ...state,
        recentLeaves: action.payload,
      };
    case CLEAR_DASHBOARD_DATA:
      return {
        newlyHiredUsers: [],
        latestMeetings: [],
        recentLeaves: [],
        totalReceivables: null,
        totalPayables: null,
        incomeExpense: null,
        topExpenses: null,
      };
    case GET_TOTAL_RECEIVABLE_DATA:
      return {
        ...state,
        totalReceivables: action.payload,
      };
    case GET_TOTAL_PAYABLE_DATA:
      return {
        ...state,
        totalPayables: action.payload,
      };
    case GET_INCOME_EXPENSE_DATA:
      return {
        ...state,
        incomeExpense: action.payload,
      };
    case GET_TOP_EXPENSES:
      return {
        ...state,
        topExpenses: action.payload,
      };
    default:
      return state;
  }
};
