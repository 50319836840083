import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_INTERNAL_MEETINGS = 'GET_INTERNAL_MEETINGS';
export const GET_INTERNAL_MEETING = 'GET_INTERNAL_MEETING';
export const CLEAR_INTERNAL_MEETINGS = 'CLEAR_INTERNAL_MEETINGS';
export const CLEAR_INTERNAL_MEETING = 'CLEAR_INTERNAL_MEETING';
export const ADD_INTERNAL_MEETING = 'ADD_INTERNAL_MEETING';
export const DELETE_INTERNAL_MEETING = 'DELETE_INTERNAL_MEETING';
export const EDIT_INTERNAL_MEETING = 'EDIT_INTERNAL_MEETING';

// GET Internal Meetings
export const getInternalMeetings = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/InternalMeeting/internal_meeting_participants_list/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    const userOwnMeetings = res.data.user_own_meetings
      ? res.data.user_own_meetings
      : [];

    const userParticipatedMeetings = res.data.user_participated_meetings
      ? res.data.user_participated_meetings
      : [];

    dispatch({
      type: GET_INTERNAL_MEETINGS,
      payload: [...userOwnMeetings, ...userParticipatedMeetings],
    });
  } catch (err) {
    if (err && err.response && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET Internal Meeting
export const getInternalMeetingById = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/InternalMeeting/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_INTERNAL_MEETING,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR InternalMeeting
export const clearInternalMeetings = () => {
  return {
    type: CLEAR_INTERNAL_MEETINGS,
  };
};

// CLEAR InternalMeeting
export const clearInternalMeeting = () => {
  return {
    type: CLEAR_INTERNAL_MEETING,
  };
};

// DELETE InternalMeeting
export const deleteInternalMeeting = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/InternalMeeting/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Meeting Deleted' }));
    dispatch({
      type: DELETE_INTERNAL_MEETING,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD InternalMeeting
export const addInternalMeeting = values => async (dispatch, getState) => {
  const newData = {
    from_time: values.from_time
      ? moment(values.from_time, ['h:mm A']).format('HH:mm')
      : '',
    to_time: values.to_time
      ? moment(values.to_time, ['h:mm A']).format('HH:mm')
      : '',
    meeting_date: values.meeting_date
      ? moment(values.meeting_date).format('YYYY-MM-DD')
      : '',
    title: values.title,
    description: values.description,
    participants: values.participants,
    location: values.location,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/InternalMeeting/`,
      newData,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Meeting Added' }));
    dispatch({
      type: ADD_INTERNAL_MEETING,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT InternalMeeting
export const editInternalMeeting = values => async (dispatch, getState) => {
  const editedData = {
    from_time: values.from_time
      ? moment(values.from_time, ['h:mm A']).format('HH:mm')
      : '',
    to_time: values.to_time
      ? moment(values.to_time, ['h:mm A']).format('HH:mm')
      : '',
    meeting_date: values.meeting_date
      ? moment(values.meeting_date).format('YYYY-MM-DD')
      : '',
    title: values.title,
    description: values.description,
    participants: values.participants,
    location: values.location,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/InternalMeeting/${values.id}/`,
      editedData,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Meeting Updated' }));
    dispatch({
      type: EDIT_INTERNAL_MEETING,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
