import {
  GET_QUOTATION,
  CLEAR_QUOTATION,
  GET_QUOTATIONS,
  DELETE_QUOTATION,
  ADD_QUOTATION,
  EDIT_QUOTATION,
  EDIT_QUOTATION_NOTE,
  DELETE_QUOTATION_NOTE,
  GET_QUOTATION_DOCS,
  ADD_QUOTATION_DOCS,
  DELETE_QUOTATION_DOC,
  QUOTATION_LOADING,
  QUOTATION_LOADED,
  GET_QUOTATIONS_LIST,
} from '../actions/quotationsActions';

const initialState = {
  count: null,
  quotationsList: [],
  quotations: [],
  currentQuotationDocs: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUOTATION:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_QUOTATION:
      return {
        ...state,
        fetched: null,
      };
    case GET_QUOTATIONS:
      return {
        ...state,
        count: action.payload.count,
        quotations: action.payload.results,
      };
    case GET_QUOTATIONS_LIST:
      return {
        ...state,
        count: action.payload.count,
        quotationsList: action.payload.results,
      };
    case DELETE_QUOTATION:
      return {
        ...state,
        quotationsList: state.quotationsList.filter(
          quotation => quotation.id !== action.payload
        ),
      };
    case ADD_QUOTATION:
      return {
        ...state,
        quotationsList: [...state.quotationsList],
      };
    case EDIT_QUOTATION:
      return {
        ...state,
        // quotations: [...state.quotations, action.payload],
      };
    case EDIT_QUOTATION_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            quotation_notes: state.fetched.quotation_notes.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_QUOTATION_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            quotation_notes: state.fetched.quotation_notes.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    case GET_QUOTATION_DOCS:
      return {
        ...state,
        currentQuotationDocs: action.payload,
      };
    case ADD_QUOTATION_DOCS:
      return {
        ...state,
        currentQuotationDocs: [...state.currentQuotationDocs, action.payload],
      };
    case DELETE_QUOTATION_DOC:
      return {
        ...state,
        currentQuotationDocs: state.currentQuotationDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case QUOTATION_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case QUOTATION_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
