import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_CURRENCY_ADJUSTMENT_LIST = 'GET_CURRENCY_ADJUSTMENT_LIST';
export const GET_CURRENCY_ADJUSTMENT = 'GET_CURRENCY_ADJUSTMENT';
export const CLEAR_CURRENCY_ADJUSTMENT = 'CLEAR_CURRENCY_ADJUSTMENT';
export const ADD_CURRENCY_ADJUSTMENT = 'ADD_CURRENCY_ADJUSTMENT';
export const DELETE_CURRENCY_ADJUSTMENT = 'DELETE_CURRENCY_ADJUSTMENT';
export const QUERY_CURRENCY_ADJUSTMENT = 'QUERY_CURRENCY_ADJUSTMENT';

// GET CURRENCY ADJUSTMENT
export const getCurrencyAdjustment = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/baseCurrencyAdjustments/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CURRENCY_ADJUSTMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR CURRENCY ADJUSTMENT
export const clearCurrencyAdjustment = () => {
  return {
    type: CLEAR_CURRENCY_ADJUSTMENT,
  };
};

// ADD CURRENCY ADJUSTMENT
export const addCurrencyAdjustment = values => async (dispatch, getState) => {
  const currency = {
    currency: values.currency,
    adjustment_date: moment(values.adjustment_date).format('YYYY-MM-DD'),
    exchange_rate: values.exchange_rate,
    notes: values.notes,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/baseCurrencyAdjustments/save`,
      currency,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Adjustment Added' }));
    dispatch({
      type: ADD_CURRENCY_ADJUSTMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CURRENCY ADJUSTMENT LIST
export const getCurrencyAdjustmentList = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/baseCurrencyAdjustments/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CURRENCY_ADJUSTMENT_LIST,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CURRENCY ADJUSTMENT
export const deleteCurrencyAdjustment = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/baseCurrencyAdjustments/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Adjustment Deleted' }));
    dispatch({
      type: DELETE_CURRENCY_ADJUSTMENT,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const queryCurrencyAdjustment = values => async (dispatch, getState) => {
  // console.log(values);
  const queryParameters = {
    adjustment_date: moment(values.adjustment_date).format('YYYY-MM-DD'),
    currency: values.currency,
    exchange_rate: values.exchange_rate,
    notes: values.notes,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/baseCurrencyAdjustments/query`,
      queryParameters,
      tokenConfig(getState)
    );
    dispatch({ type: QUERY_CURRENCY_ADJUSTMENT, payload: res.data });
  } catch (err) {
    // error dispatch from view
  }
};
