import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';

export const GET_COMPANY_HOLIDAY = 'GET_COMPANY_HOLIDAY';
export const GET_COMPANY_HOLIDAYS = 'GET_COMPANY_HOLIDAYS';
export const CLEAR_COMPANY_HOLIDAY = 'CLEAR_COMPANY_HOLIDAY';
export const ADD_COMPANY_HOLIDAY = 'ADD_COMPANY_HOLIDAY';
export const DELETE_COMPANY_HOLIDAY = 'DELETE_COMPANY_HOLIDAY';
export const EDIT_COMPANY_HOLIDAY = 'EDIT_COMPANY_HOLIDAY';
export const GET_HOLIDAY_DATES = 'GET_HOLIDAY_DATES';

export const getCompanyHolidays = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/CompanyHolidays/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_COMPANY_HOLIDAYS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const getCompanyHoliday = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyHolidays/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_COMPANY_HOLIDAY, payload: res.data });
  } catch (err) {
    if (err && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addCompanyHoliday = values => async (dispatch, getState) => {
  const holiday = {
    title: values.title,
    description: values.description,
    from_date: values.from_date
      ? moment(values.from_date).format('YYYY-MM-DD')
      : '',
    to_date: values.to_date ? moment(values.to_date).format('YYYY-MM-DD') : '',
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/CompanyHolidays/`,
      holiday,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Holiday Saved' }));
    dispatch({ type: ADD_COMPANY_HOLIDAY, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editCompanyHoliday = values => async (dispatch, getState) => {
  const holiday = {
    title: values.title,
    description: values.description,
    from_date: values.from_date
      ? moment(values.from_date).format('YYYY-MM-DD')
      : '',
    to_date: values.to_date ? moment(values.to_date).format('YYYY-MM-DD') : '',
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/CompanyHolidays/${values.id}/`,
      holiday,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Holiday Updated' }));
    dispatch({ type: EDIT_COMPANY_HOLIDAY, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const deleteCompanyHoliday = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/CompanyHolidays/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Holiday Deleted' }));
    dispatch({ type: DELETE_COMPANY_HOLIDAY, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getHolidayDates = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/LeaveDetail/total_company_holidays_for_calendar/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_HOLIDAY_DATES,
      payload: res.data.Company_holiday_dates,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearCompanyHoliday = () => ({ type: CLEAR_COMPANY_HOLIDAY });
