import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { returnErrors, createMessage } from './messagesActions';

export const GET_TRANSACTIONS_LOCKING = 'GET_TRANSACTIONS_LOCKING';
export const LOCK_UNLOCK_TRANSACTION = 'LOCK_UNLOCK_TRANSACTION';
export const GET_TRANSACTION_LOCKING_BY_ID = 'GET_TRANSACTION_LOCKING_BY_ID';

// Get Transactions Locking
export const getTransactionsLocking = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/transaction/lock/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TRANSACTIONS_LOCKING,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Get Transactions Locking
export const getTransactionLockingById = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/transaction/lock/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TRANSACTION_LOCKING_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Get Transactions Locking
export const lockUnlockTransaction = values => async (dispatch, getState) => {
  const transactionData = {
    ...values,
    trans_lock_date: moment(values.trans_lock_date).format('YYYY-MM-DD'),
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/transaction/lock/${values.id}/`,
      transactionData,
      tokenConfig(getState)
    );
    dispatch({
      type: LOCK_UNLOCK_TRANSACTION,
      payload: res.data,
    });
    if (values.trans_lock_status === 'enabled') {
      dispatch(createMessage({ message: 'Transaction Locked Successfully' }));
    } else {
      dispatch(createMessage({ message: 'Transaction Unlocked Successfully' }));
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
