import axios from 'axios';
// import qs from 'querystring';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_FOLDERS = 'GET_FOLDERS';
export const GET_FOLDER = 'GET_FOLDER';
export const CLEAR_FOLDER = 'CLEAR_FOLDER';
export const ADD_FOLDER = 'ADD_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const EDIT_FOLDER = 'EDIT_FOLDER';
export const GET_FOLDER_DATA = 'GET_FOLDER_DATA';
export const ADD_FOLDER_IN_HIERARCHY = 'ADD_FOLDER_IN_HIERARCHY';
export const REMOVE_FOLDER_FROM_HIERARCHY = 'REMOVE_FOLDER_FROM_HIERARCHY';
export const REMOVE_FOLDERS_FROM_HIERARCHY = 'REMOVE_FOLDERS_FROM_HIERARCHY';
export const CLEAR_FOLDERS_HIERARCHY = 'CLEAR_FOLDERS_HIERARCHY';
export const GET_STORAGE_FILE = 'GET_STORAGE_FILE';
export const ADD_STORAGE_FILE = 'ADD_STORAGE_FILE';
export const DELETE_STORAGE_FILE = 'DELETE_STORAGE_FILE';
export const CLEAR_STORAGE_FILE = 'CLEAR_STORAGE_FILE';
export const CLEAR_STORAGE = 'CLEAR_STORAGE';
export const ADD_FILES_FOR_PASTE = 'ADD_FILES_FOR_PASTE';
export const CLEAR_FILES_FOR_PASTE = 'CLEAR_FILES_FOR_PASTE';

// GET FOLDER
export const getFolder = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/Folder/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_FOLDER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR FOLDER
export const clearFolder = () => {
  return {
    type: CLEAR_FOLDER,
  };
};

// GET FOLDERS
export const getFolders =
  (limit = '', offset = '') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/Folder/?limit=${limit}&offset=${offset}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_FOLDERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// DELETE FOLDER
export const deleteFolder = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/Folder/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Folder Deleted' }));
    dispatch({
      type: DELETE_FOLDER,
      payload: item.id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD FOLDER
export const addFolder = (values, parentId) => async (dispatch, getState) => {
  const newFolder = { ...values };
  newFolder.parent_id = parentId || 0;
  try {
    const res = await axios.post(
      `${API_URL}/api/Folder/`,
      newFolder,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Folder Added' }));
    dispatch({
      type: ADD_FOLDER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// EDIT FOLDER
export const editFolder = values => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/Folder/${values.id}/`,
      values,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Folder Updated' }));
    dispatch({
      type: EDIT_FOLDER,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// RENAME FOLDER
export const renameFolder = values => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/Folder/${values.id}/`,
      { fold_name: values.newName },
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Folder Renamed Successfully' }));
    dispatch({
      type: EDIT_FOLDER,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// COPY/MOVE File
export const copyMoveFile =
  (destination, toFolderId, filesForPasteData, userProfileId = null) =>
  async (dispatch, getState) => {
    const { action, fileCategory, fileIds, folderIds } = filesForPasteData;

    let files = fileIds.reduce((acc, val) => `${acc}${val},`, '');
    files = files.slice(0, files.length - 1);

    let folders = folderIds.reduce((acc, val) => `${acc}${val},`, '');
    folders = folders.slice(0, folders.length - 1);

    const queryParams = {
      action,
      destination,
      to_folder_id: toFolderId,
      file_category: fileCategory,
    };

    if (files) queryParams.file_id = files;
    if (folders) queryParams.folder_id = folders;
    if (userProfileId) queryParams.user_profile_id = userProfileId;

    try {
      await axios.get(`${API_URL}/api/CopyMoveFolder/copy_move/`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch(
        createMessage({
          message: `${action === 'copy' ? 'Copied' : 'Moved'} successfully`,
        })
      );
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET FOLDER FILES
export const getFolderData =
  (folderId, department = '') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/StorageFile/get_folders_files/?folder_id=${folderId}&department_name=${department}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_FOLDER_DATA,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET STORAGE FILE
export const getStorageFile = fileId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/StorageFile/${fileId}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_STORAGE_FILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPLOAD STORAGE FILE
export const uploadStorageFile =
  (values, folderId) => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append('folder', folderId);
    formData.append('location', 'StorageFile');
    formData.append('doc_type', values.doc_file.file.type);
    formData.append('doc_name', values.doc_file.file.name);
    formData.append('doc_size_bytes', values.doc_file.file.size);
    formData.append(
      'doc_file',
      values.doc_file.file,
      values.doc_file.file.name
    );

    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';

    try {
      const res = await axios.post(
        `${API_URL}/api/StorageFile/`,
        formData,
        config
      );
      dispatch({
        type: ADD_STORAGE_FILE,
        payload: res.data,
      });
      dispatch(createMessage({ message: 'File Uploaded Successfully' }));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// DELETE STORAGE FILE
export const deleteStorageFile = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/StorageFile/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: DELETE_STORAGE_FILE,
      payload: item.id,
    });
    dispatch(createMessage({ message: 'File Deleted Successfully' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// RENAME STORAGE FILE
export const renameStorageFile = values => async (dispatch, getState) => {
  try {
    await axios.put(
      `${API_URL}/api/StorageFile/${values.id}/`,
      { doc_name: values.newName },
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'File Renamed Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

export const clearStorageFile = () => ({ type: CLEAR_STORAGE_FILE });

export const addFolderInHierarchy = data => ({
  type: ADD_FOLDER_IN_HIERARCHY,
  payload: data,
});

export const removeFolderFromHierarchy = folderId => ({
  type: REMOVE_FOLDER_FROM_HIERARCHY,
  payload: folderId,
});

export const removeFoldersFromHierarchy = index => ({
  type: REMOVE_FOLDERS_FROM_HIERARCHY,
  payload: index,
});

export const clearFoldersHierarchy = () => ({
  type: CLEAR_FOLDERS_HIERARCHY,
});

export const clearStorage = () => ({ type: CLEAR_STORAGE });

export const addFilesForPaste = data => ({
  type: ADD_FILES_FOR_PASTE,
  payload: data,
});

export const clearFilesForPaste = () => ({
  type: CLEAR_FILES_FOR_PASTE,
});
