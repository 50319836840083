import axios from 'axios';
import moment from 'moment';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const CLEAR_SALARIES = 'CLEAR_SALARIES';
export const CLEAR_SINGLE_SALARY = 'CLEAR_SINGLE_SALARY';
export const CLEAR_DATA = 'CLEAR_DATA';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_SALARIES = 'GET_SALARIES';
export const GENERATE_SALARIES = 'GENERATE_SALARIES';
export const GET_USER_GRATUITY = 'GET_USER_GRATUITY';
export const GET_EDIT_SINGLE_SALARY = 'GET_EDIT_SINGLE_SALARY';
export const GET_SINGLE_SALARY_DETAILS = 'GET_SINGLE_SALARY_DETAILS';

const now = new Date();
const currentMonth = moment(now).format('MMM');
const currentYear = now.getFullYear();

export const clearSalariesData = () => ({ type: CLEAR_DATA });

export const generateSalaries = () => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/salary/`,
      {
        salary_month: currentMonth,
        salary_year: currentYear,
      },
      tokenConfig(getState)
    );
    dispatch({
      type: GENERATE_SALARIES,
      payload: res.data,
    });
    dispatch(createMessage({ message: 'Salary Generated Successfully' }));
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getSalaryDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/salary/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SINGLE_SALARY_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const editSalary = values => async (dispatch, getState) => {
  try {
    const res = await axios.patch(
      `${API_URL}/api/salary/${values.id}/`,
      {
        total_leaves_in_month: values.absents,
        working_days: values.work_days,
        advance_salary: values.advance_salary,
      },
      tokenConfig(getState)
    );
    dispatch({
      type: GET_EDIT_SINGLE_SALARY,
      payload: res.data,
    });
    dispatch(createMessage({ message: 'Salary Edited Successfully' }));
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const clearSingleSalary = () => {
  return {
    type: CLEAR_SINGLE_SALARY,
  };
};

export const getSalaries =
  (month, year, userProfileId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/SalaryDetail/salary_history_list/`,
        {
          ...tokenConfig(getState),
          params: {
            salary_month: month,
            salary_year: year,
            user_profile_id: userProfileId,
          },
        }
      );
      dispatch({
        type: GENERATE_SALARIES,
        payload: res.data,
      });
    } catch (err) {
      if (err && err.response && err.response.status === 400) {
        if (
          err.response.data &&
          err.response.data.Message === 'record not found'
        ) {
          dispatch({ type: CLEAR_SALARIES });
        }
      }
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getUserGratuity = userProfileId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/GratuityDetail/gratuity_calculation/?user_profile_id=${userProfileId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_GRATUITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const sendToAccounts = (month, year) => async (dispatch, getState) => {
  try {
    await axios.get(
      `${API_URL}/api/salary/send/to/accounts?month=${month}&year=${year}`,
      tokenConfig(getState)
    );
    dispatch(
      createMessage({ message: 'Salary sent to accounts successfully' })
    );
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getAccountingSalaries = filters => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING });
    const response = await axios.get(`${API_URL}/api/salaries/`, {
      ...tokenConfig(getState),
      params: { ...filters },
    });
    dispatch({
      type: GET_SALARIES,
      payload: response.data,
    });
    dispatch({ type: STOP_LOADING });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};
