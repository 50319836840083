import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { addActivityLog } from './miscActions';
import { createMessage, returnErrors } from './messagesActions';

export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEM = 'GET_ITEM';
export const CLEAR_ITEM = 'CLEAR_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const ITEMS_LOADED = 'ITEMS_LOADED';
export const CHANGE_ITEM_STATUS = 'CHANGE_ITEM_STATUS';
export const GET_ITEM_WITH_REQUEST_NUM = 'GET_ITEM_WITH_REQUEST_NUM';
export const CLEAR_ITEM_WITH_REQUEST_NUM = 'CLEAR_ITEM_WITH_REQUEST_NUM';

// GET ITEM
export const getItem = id => async (dispatch, getState) => {
  try {
    const { data: itemDetails } = await axios.get(
      `${API_URL}/api/accounting/items/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ITEM,
      payload: itemDetails,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ITEM BY NAME
export const getItemByName = name => async (dispatch, getState) => {
  const encodedName = encodeURIComponent(name);
  try {
    const res = await axios.get(
      `${API_URL}/api/item/by/name?item_name=${encodedName}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ITEM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR ITEM
export const clearItem = () => {
  return {
    type: CLEAR_ITEM,
  };
};

// GET ITEMS
export const getItems =
  (params = {}) =>
  async (dispatch, getState) => {
    const { limit, offset, filter, searchQuery } = params;
    const queryParams = {};
    if (limit) {
      queryParams.limit = limit;
      queryParams.offset = offset;
    }
    if (searchQuery) queryParams.query = searchQuery;

    if (filter) {
      if (filter === 'True' || filter === 'False') {
        queryParams.is_active = filter;
      } else {
        queryParams.item_type = filter;
      }
    }

    try {
      dispatch({ type: ITEMS_LOADING }); // api/accounting/items/
      const res = await axios.get(`${API_URL}/api/accounting/list/items`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      });
      dispatch({ type: ITEMS_LOADED });
    } catch (err) {
      dispatch({ type: ITEMS_LOADED });
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// DELETE ITEM
export const deleteItem = item => async (dispatch, getState) => {
  const deleteActivityLog = {
    activity_type: 'Item',
    activity_title: 'Item Deleted',
    description: `Item (${item.item_name}) deleted`,
  };
  try {
    await axios.delete(
      `${API_URL}/api/accounting/items/${item.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Item Deleted' }));
    dispatch({
      type: DELETE_ITEM,
      payload: item.id,
    });
    dispatch(addActivityLog(deleteActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD ITEM
export const addItem = values => async (dispatch, getState) => {
  const newActivityLog = {
    activity_type: 'Item',
    activity_title: 'Item Added',
    description: `New Item (${values.item_name}) created`,
  };

  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/items/`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'Item Added' }));
    dispatch({
      type: ADD_ITEM,
      payload: res.data,
    });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    throw err;
  }
};

// EDIT ITEM
export const editItem = values => async (dispatch, getState) => {
  const updateActivityLog = {
    activity_type: 'Item',
    activity_title: 'Item Updated',
    description: `Item (${values.item_name}) updated`,
  };

  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/items/${values.id}/`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'Item Updated' }));
    dispatch({
      type: EDIT_ITEM,
      payload: res.data,
    });
    dispatch(addActivityLog(updateActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD ITEM PHOTO
export const uploadItemPhoto = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append(
    'item_image',
    values.item_image[0],
    values.item_image[0].name
  );
  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    await axios.put(
      `${API_URL}/api/accounting/items/${values.id}/uploadItemImage`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'Item Image Uploaded' }));
    dispatch(getItem(values.id));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// CHANGE ITEM STATUS
export const changeItemStatus = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/items/${id}/status`,
      tokenConfig(getState)
    );
    dispatch({
      type: CHANGE_ITEM_STATUS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getItemByRequestNumber =
  requestNumber => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/bill/item/detail/${requestNumber}/`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_ITEM_WITH_REQUEST_NUM,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const clearItemByRequestNumber = () => {
  return {
    type: CLEAR_ITEM_WITH_REQUEST_NUM,
  };
};
