import {
  GET_SUPPLIER_CREDIT,
  CLEAR_SUPPLIER_CREDIT,
  GET_SUPPLIER_CREDITS,
  DELETE_SUPPLIER_CREDIT,
  ADD_SUPPLIER_CREDIT,
  EDIT_SUPPLIER_CREDIT,
  GET_SUPPLIER_CREDIT_DOCS,
  ADD_SUPPLIER_CREDIT_DOCS,
  DELETE_SUPPLIER_CREDIT_DOC,
  MARK_SUPPLIER_CREDIT_OPEN,
  GET_SUPPLIER_CREDIT_JOURNAL,
  CLEAR_SUPPLIER_CREDIT_JOURNAL,
  SUPPLIER_CREDITS_LOADING,
  SUPPLIER_CREDITS_LOADED,
  GET_SUPPLIER_CREDITS_LIST,
} from '../actions/supplierCreditsActions';

const initialState = {
  count: null,
  supplierCreditsList: [],
  supplierCredits: [],
  currentSupplierCreditDocs: [],
  fetched: null,
  journal: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIER_CREDIT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_SUPPLIER_CREDIT:
      return {
        ...state,
        fetched: null,
      };
    case GET_SUPPLIER_CREDITS:
      return {
        ...state,
        count: action.payload.count,
        supplierCredits: action.payload.results,
      };
    case GET_SUPPLIER_CREDITS_LIST:
      return {
        ...state,
        count: action.payload.count,
        supplierCreditsList: action.payload.results,
      };
    case DELETE_SUPPLIER_CREDIT:
      return {
        ...state,
        supplierCreditsList: state.supplierCreditsList.filter(
          creditNote => creditNote.id !== action.payload
        ),
      };
    case ADD_SUPPLIER_CREDIT:
      return {
        ...state,
        supplierCreditsList: [...state.supplierCreditsList],
      };
    case EDIT_SUPPLIER_CREDIT:
      return {
        ...state,
        // supplierCredits: [...state.supplierCredits, action.payload],
      };
    case GET_SUPPLIER_CREDIT_DOCS:
      return {
        ...state,
        currentSupplierCreditDocs: action.payload,
      };
    case ADD_SUPPLIER_CREDIT_DOCS:
      return {
        ...state,
        currentSupplierCreditDocs: [
          ...state.currentSupplierCreditDocs,
          action.payload,
        ],
      };
    case DELETE_SUPPLIER_CREDIT_DOC:
      return {
        ...state,
        currentSupplierCreditDocs: state.currentSupplierCreditDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case MARK_SUPPLIER_CREDIT_OPEN:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_SUPPLIER_CREDIT_JOURNAL:
      return {
        ...state,
        journal: action.payload,
      };
    case CLEAR_SUPPLIER_CREDIT_JOURNAL:
      return {
        ...state,
        journal: null,
      };
    case SUPPLIER_CREDITS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SUPPLIER_CREDITS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
