/* eslint-disable react/jsx-indent */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import AccountingSidebar from './AccountantSidebar';
import OperationSidebar from './OperationSidebar';
import SingleSubCategory from './SingleSubCategory';
import {
  OPERATIONS_CONTROLLER,
  OPERATIONS_MANAGER,
  OPERATIONS_STAFF,
  // ACCOUNTING_CONTROLLER,
  // ACCOUNTING_STAFF,
  // ACCOUNTING_MANAGER,
  SUPER_USER,
  // HR_STAFF,
  HR_DEPT,
  FINANCE_DEPT,
} from '../../../utils/constants';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    department: PropTypes.string,
    allowSystemAccess: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  static defaultProps = {
    department: null,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const {
      department,
      role,
      onClick,
      allowSystemAccess,
      auth: { permission },
      user: {
        // isAccountingStaff,
        // isAccountingSupervisor,
        isOperationalDept,

        hasEmployeesAccess,
        hasHolidaysAccess,
        hasDocumentsAccess,
        hasSalariesAccess,
        hasAnnouncementAccess,
        hasActivitiesAccess,
        hasLeavesReqAccess,
        hasCompanyFilesAccess,
        hasCompanyProfilesAccess,
        hasAccountsAccess,
        hasCustomersAccess,
        hasQuotationsAccess,
        hasProInoviceAccess,
        hasInvoicesAccess,
        hasPaymentReceivedAccess,
        hasCreditNoteAccess,
        hasSupplierAccess,
        hasExpensesAccess,
        hasPurOrderAccess,
        hasBillsAccess,
        hasPaymentMadeAccess,
        hasDebitNoteAccess,
        hasCurrenciesAccess,
        hasJournalsAccess,
        hasTaxPaymentsAccess,
        hasCOAAccess,
        hasTrLockingAccess,
      },
    } = this.props;
    const isSuperUser = role === SUPER_USER;
    // const isAccountingController = role === ACCOUNTING_CONTROLLER;
    const isOperationStaff = role === OPERATIONS_STAFF;
    // const isAccountingStaf = role === isAccountingStaff;
    // const isHrStaff = role === HR_STAFF;
    // const hideLeavesTab = isHrStaff || isAccountingStaf || isOperationStaff;
    const isAccountingDept = department === FINANCE_DEPT; // isAccountingSupervisor || isAccountingStaff;
    const isHrDepartment = department === HR_DEPT;
    const isOperationsDept =
      role === OPERATIONS_CONTROLLER ||
      role === OPERATIONS_MANAGER ||
      role === OPERATIONS_STAFF;
    const section = permission ? permission.section_access : {};

    return (
      <div className="sidebar__content">
        {allowSystemAccess ? (
          <>
            {isAccountingDept || isOperationalDept ? ( // isOperationsDept
              <>
                {isAccountingDept && (
                  <AccountingSidebar
                    // isAccountingStaff={isAccountingStaf}
                    // isAccountingController={isAccountingController}
                    handleClick={onClick}
                  />
                )}
                {isOperationalDept && (
                  <OperationSidebar
                    handleClick={onClick}
                    isOperationStaff={isOperationStaff}
                  />
                )}
              </>
            ) : (
              <React.Fragment>
                <ul className="sidebar__block">
                  <SidebarLink
                    title="Dashboard"
                    icon="tachometer-alt"
                    route="/"
                    onClick={this.hideSidebar}
                  />
                </ul>
                <ul className="sidebar__block">
                  {(isSuperUser || isAccountingDept) && ( // || department === 'finance'
                    <SidebarCategory title="Accounting" icon="calculator">
                      {section.Items && (
                        <SidebarLink
                          title="Items"
                          route="/pages/accounting/items"
                          onClick={this.hideSidebar}
                        />
                      )}
                      {section['Inventory Adjustments'] && (
                        <SidebarLink
                          title="Inventory Adjustments"
                          route="/pages/accounting/inventoryAdjustments"
                          onClick={this.hideSidebar}
                        />
                      )}
                      {section.Banking && (
                        <SidebarLink
                          title="Banking"
                          route="/pages/accounting/banking"
                          onClick={this.hideSidebar}
                        />
                      )}
                      {section.Sales && (
                        <SidebarCategory title="Sales">
                          {hasAccountsAccess && (
                            <SidebarLink
                              title="Accounts"
                              route="/pages/accounting/sales/accounts"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasCustomersAccess && (
                            <SidebarLink
                              title="Customers"
                              route="/pages/accounting/sales/customers"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasQuotationsAccess && (
                            <SidebarLink
                              title="Quotations"
                              route="/pages/accounting/sales/quotations"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasProInoviceAccess && (
                            <SidebarLink
                              title="Proforma Invoices"
                              route="/pages/accounting/sales/proInvoices"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasInvoicesAccess && (
                            <SidebarLink
                              title="Invoices"
                              route="/pages/accounting/sales/invoices"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasPaymentReceivedAccess && (
                            <SidebarLink
                              title="Payments Received"
                              route="/pages/accounting/sales/paymentsReceived"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasCreditNoteAccess && (
                            <SidebarLink
                              title="Credit Notes"
                              route="/pages/accounting/sales/creditNotes"
                              onClick={this.hideSidebar}
                            />
                          )}
                        </SidebarCategory>
                      )}
                      {section.Purchases && (
                        <SidebarCategory title="Purchases">
                          {hasSupplierAccess && (
                            <SidebarLink
                              title="Suppliers"
                              route="/pages/accounting/purchases/suppliers"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasExpensesAccess && (
                            <SidebarLink
                              title="Expenses"
                              route="/pages/accounting/purchases/expenses"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasPurOrderAccess && (
                            <SidebarLink
                              title="Purchase Orders"
                              route="/pages/accounting/purchases/purOrders"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasBillsAccess && (
                            <SidebarLink
                              title="Bills"
                              route="/pages/accounting/purchases/bills"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasPaymentMadeAccess && (
                            <SidebarLink
                              title="Payments Made"
                              route="/pages/accounting/purchases/paymentsMade"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasDebitNoteAccess && (
                            <SidebarLink
                              title="Debit Notes"
                              route="/pages/accounting/purchases/supplierCredits"
                              onClick={this.hideSidebar}
                            />
                          )}
                        </SidebarCategory>
                      )}
                      {section.Accountant && (
                        <SidebarCategory title="Accountant">
                          {hasCurrenciesAccess && (
                            <SidebarLink
                              title="Currencies"
                              route="/pages/accounting/accountant/currencies"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasJournalsAccess && (
                            <SidebarLink
                              title="Manual Journals"
                              route="/pages/accounting/accountant/journals"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasTaxPaymentsAccess && (
                            <SidebarLink
                              title="Tax Payments"
                              route="/pages/accounting/accountant/taxPayments"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {/* <SidebarLink
                  title="Tax Adjustments"
                  route="/pages/accounting/accountant/taxAdjustments"
                  onClick={this.hideSidebar}
                /> */}
                          {/* <SidebarLink
                  title="Currency Adjustments"
                  route="/pages/accounting/accountant/currencyAdjustments"
                  onClick={this.hideSidebar}
                /> */}
                          {hasCOAAccess && (
                            <SidebarLink
                              title="Chart of Accounts"
                              route="/pages/accounting/accountant/chartOfAccounts"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasTrLockingAccess && (
                            <SidebarLink
                              title="Transaction Locking"
                              route="/pages/accounting/accountant/transactionLocking"
                              onClick={this.hideSidebar}
                            />
                          )}
                        </SidebarCategory>
                      )}
                    </SidebarCategory>
                  )}
                  {(isSuperUser || isHrDepartment) && (
                    <>
                      {section.HR && (
                        <SidebarCategory title="HR" icon="file-alt">
                          {hasHolidaysAccess && (
                            <SidebarLink
                              title="Holidays"
                              route="/pages/hr/companyHolidays"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasEmployeesAccess && (
                            <SidebarLink
                              title="Employees"
                              route="/pages/hr/employees"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasLeavesReqAccess && (
                            <SidebarLink
                              title="Leaves Request"
                              route="/pages/hr/leaves"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {/* {!isHrStaff && (
                          <> */}
                          {hasDocumentsAccess && (
                            <SidebarLink
                              title="Documents Request"
                              route="/pages/hr/employeeRequests"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasSalariesAccess && (
                            <SidebarLink
                              title="Salaries"
                              route="/pages/hr/salaries"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasActivitiesAccess && (
                            <SidebarLink
                              title="Activities"
                              route="/pages/manageActivities"
                              onClick={this.hideSidebar}
                              // icon="clipboard-list"
                            />
                          )}
                          {hasAnnouncementAccess && (
                            <SidebarLink
                              title="Announcements"
                              route="/pages/announcements"
                              onClick={this.hideSidebar}
                              // icon="clipboard-list"
                            />
                          )}
                          {/* </>
                        )} */}
                        </SidebarCategory>
                      )}
                      {section.Company && (
                        <SidebarCategory title="Company" icon="building">
                          {hasCompanyProfilesAccess && (
                            <SidebarLink
                              title="Company Profiles"
                              route="/pages/hr/companyProfiles"
                              onClick={this.hideSidebar}
                            />
                          )}
                          {hasCompanyFilesAccess && (
                            <SidebarLink
                              title="Company Files"
                              route="/pages/hr/companyFiles"
                              onClick={this.hideSidebar}
                            />
                          )}
                        </SidebarCategory>
                      )}
                    </>
                  )}

                  {/* {isAccountingDept && !isAccountingStaff && (
                    <SidebarCategory title="HR" icon="diamond">
                      {hasSalariesAccess && (
                        <SidebarLink
                          title="Salaries"
                          route="/pages/hr/salaries"
                          onClick={this.hideSidebar}
                        />
                      )}
                      {hasLeavesReqAccess && (
                        <SidebarLink
                          title="Leaves"
                          route="/pages/hr/leaves"
                          onClick={this.hideSidebar}
                          hidden={role !== ACCOUNTING_CONTROLLER}
                        />
                      )}
                    </SidebarCategory>
                  )} */}
                  {section.Meetings && (
                    <SidebarLink
                      title="Meetings"
                      route="/pages/manageInternalMeetings"
                      onClick={this.hideSidebar}
                      icon="handshake"
                    />
                  )}
                  {(isSuperUser ||
                    (department !== HR_DEPT && department !== 'finance')) && (
                    <SidebarCategory title="Operations" icon="chart-bar">
                      <SidebarCategory title="Event Management">
                        <SidebarLink
                          title="Event Calendar"
                          route="/pages/operations/eventCalendar"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Event Coordinators"
                          route="/pages/operations/eventCoordinator"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Requested"
                          route="/pages/operations/requested"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Confirmed"
                          route="/pages/operations/confirmed"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Finished"
                          route="/pages/operations/finished"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Cancelled"
                          route="/pages/operations/cancelled"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Service Requests"
                          route="/pages/operations/serviceRequests"
                          onClick={this.hideSidebar}
                        />
                      </SidebarCategory>
                      {hasAccountsAccess && (
                        <SidebarLink
                          title="Accounts"
                          route="/pages/accounting/sales/accounts"
                          onClick={this.hideSidebar}
                        />
                      )}
                      {hasCustomersAccess && (
                        <SidebarLink
                          title="Customers"
                          route="/pages/accounting/sales/customers"
                          onClick={this.hideSidebar}
                        />
                      )}
                    </SidebarCategory>
                  )}
                  {(isSuperUser || department === 'finance') && (
                    <SidebarLink
                      title="Reports"
                      route="/pages/reports"
                      icon="project-diagram"
                      onClick={this.hideSidebar}
                    />
                  )}
                  {(isSuperUser || department === 'finance') && (
                    <SidebarLink
                      title="Settings"
                      route="/pages/settings"
                      onClick={this.hideSidebar}
                      icon="cog"
                    />
                  )}
                  {section.Storage && (
                    <SidebarLink
                      icon="database"
                      title="Storage"
                      route="/pages/storage/main"
                      onClick={this.hideSidebar}
                    />
                  )}
                  {section.Trash && (
                    <SidebarLink
                      icon="trash-alt"
                      title="Trash"
                      route="/pages/storage/trash"
                      onClick={this.hideSidebar}
                    />
                  )}
                </ul>
              </React.Fragment>
            )}
          </>
        ) : (
          <React.Fragment>
            {isOperationsDept && (
              <ul className="sidebar__block">
                <SidebarLink
                  title="Dashboard"
                  icon="tachometer-alt"
                  route="/"
                  onClick={this.hideSidebar}
                />
              </ul>
            )}
            <ul className="sidebar__block">
              <SidebarLink
                title="My Profile"
                icon="user"
                route="/pages/user/profile"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Meetings"
                icon="handshake"
                route="/pages/manageInternalMeetings"
                onClick={this.hideSidebar}
              />

              {/* {!hideLeavesTab && ( */}
              <SingleSubCategory
                title="Miscellaneous"
                icon="fas fa-layer-group"
              >
                <SidebarLink
                  title="Employee Leaves"
                  route="/pages/hr/leaves"
                  onClick={this.hideSidebar}
                />
              </SingleSubCategory>
              {/* )} */}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  department: state.auth.user.profile
    ? state.auth.user.profile.department_name
    : '',
  allowSystemAccess: state.auth.user.profile
    ? state.auth.user.profile.system_access
    : false,
  role: state.auth.user.profile.role_name,
  user: state.auth.user,
  auth: state.auth,
});

export default connect(mapStateToProps, null)(SidebarContent);
