import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import useWebSocket from 'react-use-websocket';
import useAuth from '../../../../Common/CustomHooks/useAuth';
import { transformSocketData } from '../../../../../utils/helpers';
import { SOCKET_URL } from '../../../../../utils/constants';

export const ServiceRequestGlobalContext = createContext(null);

export const useServiceRequestGlobalContext = () =>
  useContext(ServiceRequestGlobalContext);

const ServiceRequestGlobalProvider = ({ children }) => {
  const alert = useAlert();
  const {
    token,
    role: { isSuperUser, isOperationsDeptUser },
  } = useAuth();
  const socketOptions = useWebSocket(`${SOCKET_URL}/api/service/request/`, {
    queryParams: { system: 'CRM', token },
  });
  const { lastJsonMessage } = socketOptions;

  useEffect(() => {
    if (lastJsonMessage) {
      const { isSuccess } = transformSocketData(lastJsonMessage);
      // Show message only to super user or operations user
      if (isSuccess && (isSuperUser || isOperationsDeptUser)) {
        alert.success('New service request received');
      }
    }
  }, [lastJsonMessage]);

  return (
    <ServiceRequestGlobalContext.Provider
      value={{
        ...socketOptions,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </ServiceRequestGlobalContext.Provider>
  );
};

ServiceRequestGlobalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ServiceRequestGlobalProvider;
