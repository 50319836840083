import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { addActivityLog } from './miscActions';
import { createMessage, returnErrors } from './messagesActions';

export const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const EDIT_BANK_ACCOUNT = 'EDIT_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const CLEAR_BANK_ACCOUNT = 'CLEAR_BANK_ACCOUNT';
export const GET_BANK_ACCOUNT_BY_CURRENCY = 'GET_BANK_ACCOUNT_BY_CURRENCY';
export const BANK_ACCOUNT_LOADING = 'BANK_ACCOUNT_LOADING';
export const BANK_ACCOUNT_LOADED = 'BANK_ACCOUNT_LOADED';
export const CHANGE_BANK_STATUS = 'CHANGE_BANK_STATUS';
export const BANK_STATEMENT_HEADERS = 'BANK_STATEMENT_HEADERS';
export const ADD_BANK_STATEMENT = 'ADD_BANK_STATEMENT';
export const GET_BANK_STATEMENTS = 'GET_BANK_STATEMENTS';
export const UPDATE_BANK_STATEMENT = 'UPDATE_BANK_STATEMENT';

// GET BANK_ACCOUNT
export const getBankAccount = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/bankAccounts/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_BANK_ACCOUNT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR BANK_ACCOUNT
export const clearBankAccount = () => {
  return { type: CLEAR_BANK_ACCOUNT };
};

// GET BANK_ACCOUNTS
export const getBankAccounts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BANK_ACCOUNT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/bankAccounts/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_BANK_ACCOUNTS, payload: res.data.results });
    dispatch({ type: BANK_ACCOUNT_LOADED });
  } catch (err) {
    dispatch({ type: BANK_ACCOUNT_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE BANK_ACCOUNT
export const deleteBankAccount = bank => async (dispatch, getState) => {
  const deleteActivityLog = {
    activity_type: 'Bank Account',
    activity_title: 'Bank Account Deleted',
    description: `Bank Account (${bank.bank_account_name}) deleted`,
  };

  // added type && customer_date key for transection locking
  const payload = {
    type: 'bank_account',
    bank_account_date: moment(new Date()).format('YYYY-MM-DD'),
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/bankAccounts/${bank.id}/`,
      {
        ...tokenConfig(getState),
        data: payload,
      }
    );
    dispatch(createMessage({ message: 'Bank Account Deleted' }));
    dispatch({ type: DELETE_BANK_ACCOUNT, payload: bank.id });
    dispatch(addActivityLog(deleteActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD BANK_ACCOUNT
export const addBankAccount = values => async (dispatch, getState) => {
  const newAccount = {
    ...values,
    type: 'bank_account',
  };

  const newActivityLog = {
    activity_type: 'Bank Account',
    activity_title: 'Bank Account Added',
    description: `Bank Account (${newAccount.bank_account_name}) created`,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/bankAccounts/`,
      newAccount,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bank Account Added' }));
    dispatch({ type: ADD_BANK_ACCOUNT, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT BANK_ACCOUNT
export const editBankAccount =
  ({ id, ...values }) =>
  async (dispatch, getState) => {
    const editedAccount = {
      ...values,
      type: 'bank_account',
    };

    const updateActivityLog = {
      activity_type: 'Bank Account',
      activity_title: 'Bank Account Updated',
      description: `Bank Account (${editedAccount.bank_account_name}) updated`,
    };

    try {
      const res = await axios.put(
        `${API_URL}/api/accounting/accountant/bankAccounts/${id}/`,
        editedAccount,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'Bank Account Updated' }));
      dispatch(getBankAccounts());
      dispatch({ type: EDIT_BANK_ACCOUNT, payload: res.data });
      dispatch(addActivityLog(updateActivityLog));
    } catch (err) {
      // error message dispatched from view
      throw err;
    }
  };

// GET BANK ACCOUNT BY CURRENCY
export const getBankAccountByCurrency =
  currencyId => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/sales/currency/${currencyId}/bank_account`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_BANK_ACCOUNT_BY_CURRENCY,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET BANK ACCOUNT BY CURRENCY
export const updateBankStatus = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/bank/account/${id}/status`,
      tokenConfig(getState)
    );
    dispatch({
      type: CHANGE_BANK_STATUS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// BANK STATEMENT ACTIONS

export const importBankStatement = values => async (dispatch, getState) => {
  const formData = new FormData();
  const fileHeaders = {
    date: values.date,
    withdrawals: values.withdrawals,
    deposits: values.deposits,
    description: values.description || '',
    reference_number: values.reference_number || '',
    payee: values.payee || '',
  };
  formData.append('file', values.file);
  formData.append('bank_id', values.account_name);
  formData.append('file_headers', JSON.stringify(fileHeaders));

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/bankAccounts/data_mapping/`,
      formData,
      config
    );
    dispatch({
      type: ADD_BANK_STATEMENT,
      payload: res.data,
    });
    return null;
  } catch (err) {
    const { response } = err;
    return response;
  }
};
export const uploadStatementFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('file', values.file);
  formData.append('bank_id', values.bank_id);
  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/bankAccounts/csv_headers/`,
      formData,
      config
    );

    dispatch({
      type: BANK_STATEMENT_HEADERS,
      payload: res.data.header,
    });
    return null;
  } catch (err) {
    const { response } = err;
    dispatch(returnErrors(err.response.data, err.response.status));
    return response;
  }
};
export const getBankStatementByBankId = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/bankAccounts/${id}/statement_list/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BANK_STATEMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const changeBankStatementStatus =
  values => async (dispatch, getState) => {
    const payload = {
      ...values,
      statement_id: values.id,
    };

    try {
      const res = await axios.post(
        `${API_URL}/api/accounting/accountant/bankAccounts/statement_update/`,
        payload,
        tokenConfig(getState)
      );
      dispatch({
        type: UPDATE_BANK_STATEMENT,
        payload: res.data[0],
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
