import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { GET_SALES_CONTACTS } from './salesContactsActions';
import { addActivityLog as addMiscActivityLog } from './miscActions';
import getEmailTemplate from '../../utils/getEmailTemplate';

export const GET_SALES_ACCOUNTS_LIST = 'GET_SALES_ACCOUNTS_LIST';
export const GET_SALES_ACCOUNTS = 'GET_SALES_ACCOUNTS';
export const GET_SEARCHED_SALES_ACCOUNTS = 'GET_SEARCHED_SALES_ACCOUNTS';
export const CLEAR_SEARCHED_SALES_ACCOUNTS = 'CLEAR_SEARCHED_SALES_ACCOUNTS';
export const GET_SALES_ACCOUNT = 'GET_SALES_ACCOUNT';
export const CLEAR_SALES_ACCOUNT = 'CLEAR_SALES_ACCOUNT';
export const ADD_SALES_ACCOUNT = 'ADD_SALES_ACCOUNT';
export const DELETE_SALES_ACCOUNT = 'DELETE_SALES_ACCOUNT';
export const EDIT_SALES_ACCOUNT = 'EDIT_SALES_ACCOUNT';
export const GET_SALES_ACCOUNTS_BY_COMPANY = 'GET_SALES_ACCOUNTS_BY_COMPANY';
export const GET_UNPAID_INVOICES = 'GET_UNPAID_INVOICES';
export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const GET_NON_DRAFT_INVOICES = 'GET_NON_DRAFT_INVOICES';
export const GET_ACCOUNT_INCOME = 'GET_ACCOUNT_INCOME';
export const GET_ACCOUNT_TRANSACTIONS = 'GET_ACCOUNT_TRANSACTIONS';
export const ADD_ACCOUNT_COMMENT = 'ADD_ACCOUNT_COMMENT';
export const DELETE_ACCOUNT_COMMENT = 'DELETE_ACCOUNT_COMMENT';
export const GET_ACCOUNT_COMMENTS = 'GET_ACCOUNT_COMMENTS';
export const CLEAR_ACCOUNT_COMMENTS = 'CLEAR_ACCOUNT_COMMENTS';
export const GET_ACTIVITY_LOGS = 'GET_ACTIVITY_LOGS';
export const CLEAR_ACTIVITY_LOG = 'CLEAR_ACTIVITY_LOG';
export const GET_ACCOUNT_STATEMENT = 'GET_ACCOUNT_STATEMENT';
export const SALES_ACCOUNT_LOADING = 'SALES_ACCOUNT_LOADING';
export const SALES_ACCOUNT_LOADED = 'SALES_ACCOUNT_LOADED';
export const CHECK_ACCOUNT_CREDIT_LIMIT = 'CHECK_ACCOUNT_CREDIT_LIMIT';
export const GET_ACCOUNT_CREDIT_DETAILS = 'GET_ACCOUNT_CREDIT_DETAILS';
export const ADD_ACCOUNT_DOC = 'ADD_ACCOUNT_DOC';
export const GET_ACCOUNT_DOCS = 'GET_ACCOUNT_DOCS';
export const DELETE_ACCOUNT_DOC = 'DELETE_ACCOUNT_DOC';
export const GET_ACCOUNT_EMAILS = 'GET_ACCOUNT_EMAILS';

// GET ACTIVITY LOG
export const getActivityLogsByAccountId = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/customer/${id}/activityLog`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACTIVITY_LOGS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD ACTIVITY LOG
export const addActivityLog = values => async (dispatch, getState) => {
  try {
    await axios.post(
      `${API_URL}/api/accounting/sales/customer/ActivityLog/`,
      values,
      tokenConfig(getState)
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

export const getLatestCustomerNum = () => async (_, getState) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/salesAccount/latest`,
    tokenConfig(getState)
  );
  return data;
};

// GET SALES ACCOUNT
export const getSalesAccount = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/accounts/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SALES_ACCOUNT, payload: res.data });
    dispatch({
      type: GET_SALES_CONTACTS,
      payload: res.data.sales_contacts,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR SALES ACCOUNT
export const clearSalesAccount = () => {
  return { type: CLEAR_SALES_ACCOUNT };
};

// GET SALES ACCOUNTS LIST
export const getSalesAccountsList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: SALES_ACCOUNT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/accounts`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_SALES_ACCOUNTS_LIST, payload: res.data });
    dispatch({ type: SALES_ACCOUNT_LOADED });
  } catch (err) {
    dispatch({ type: SALES_ACCOUNT_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const mergeSalesAccounts =
  (data, params) => async (dispatch, getState) => {
    try {
      dispatch({ type: SALES_ACCOUNT_LOADING });
      await axios.post(
        `${API_URL}/api/accounting/sales/merge/accounts`,
        data,
        tokenConfig(getState)
      );
      dispatch(getSalesAccountsList(params));
      dispatch({ type: SALES_ACCOUNT_LOADED });
    } catch (err) {
      dispatch({ type: SALES_ACCOUNT_LOADED });
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
// GET SALES ACCOUNTS
export const getSalesAccounts = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/accounting/sales/accounts/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_SALES_ACCOUNTS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACTIVE SALES ACCOUNTS
export const getActiveSalesAccounts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/accounts/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SALES_ACCOUNTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACCOUNTS ON SEARCH

export const getSearchedSalesAccounts =
  params => async (dispatch, getState) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/accounting/sales/search/active/accounts/?search=${params}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_SEARCHED_SALES_ACCOUNTS,
        payload: data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const clearSearchedSalesAccounts = () => {
  return { type: CLEAR_SEARCHED_SALES_ACCOUNTS };
};

// DELETE SALES ACCOUNT
export const deleteSalesAccount =
  salesAccount => async (dispatch, getState) => {
    const deleteActivityLog = {
      activity_type: 'Account',
      activity_title: 'Sales Account Deleted',
      description: `${salesAccount.sales_account_name} deleted`,
    };

    try {
      await axios.delete(
        `${API_URL}/api/accounting/sales/accounts/${salesAccount.id}/`,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'Account Deleted' }));
      dispatch({ type: DELETE_SALES_ACCOUNT, payload: salesAccount.id });
      dispatch(addMiscActivityLog(deleteActivityLog));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// ADD SALES ACCOUNT
export const addSalesAccount = values => async (dispatch, getState) => {
  const accountDetails = {
    ...values,
    type: 'account',
    account_date: moment(new Date()).format('YYYY-MM-DD'), // added type && account_date key for transection locking
    opening_balance_date: values.opening_balance_date
      ? moment(values.opening_balance_date).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    limit: values.limit || 0,
    account_name_sorting: values.sales_account_name
      ? values.sales_account_name.toLowerCase()
      : '',
  };
  const salesContacts = accountDetails.sales_contacts.map(item => ({
    title: item.title,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    phone_num: item.phone_num,
    mobile_num: item.mobile_num,
    email: item.email,
  }));
  const primaryContactDetails = accountDetails.primary_contact_details;

  if (!Object.values(primaryContactDetails).every(item => !item)) {
    primaryContactDetails.primary_contact = true;
    salesContacts.push({
      id: primaryContactDetails.id,
      primary_contact: primaryContactDetails.primary_contact,
      title: primaryContactDetails.title,
      first_name: primaryContactDetails.first_name,
      last_name: primaryContactDetails.last_name,
      dob: primaryContactDetails.dob,
      address: primaryContactDetails.address,
      city: primaryContactDetails.city,
      country: primaryContactDetails.country,
      gender: primaryContactDetails.gender,
      mobile_num: primaryContactDetails.mobile_num,
      phone_num: primaryContactDetails.phone_num,
      email: primaryContactDetails.email,
      linkedin_id: primaryContactDetails.linkedin_id,
      facebook_id: primaryContactDetails.facebook_id,
      twitter_id: primaryContactDetails.twitter_id,
      instagram_id: primaryContactDetails.instagram_id,
      notes: primaryContactDetails.notes,
      sales_account_id: primaryContactDetails.sales_account_id,
    });
  }
  delete accountDetails.primary_contact_details;
  const salesAccount = {
    ...accountDetails,
    sales_contacts: salesContacts,
    exchange_rate: values.exchange_rate ? values.exchange_rate : null,
  };

  if (values.parent) salesAccount.parent = values.parent;
  try {
    const lastNum = await dispatch(getLatestCustomerNum());
    salesAccount.transaction_num = lastNum.latest_num
      ? lastNum.latest_num + 1
      : 1;

    const { data } = await axios.post(
      `${API_URL}/api/accounting/sales/accounts/`,
      salesAccount,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: data.id,
      module_num: data.id,
      module_name: data.sales_account_name,
      activity_type: 'Account',
      activity_title: 'Sales Account Added',
      description: `${salesAccount.sales_account_name} created`,
    };
    dispatch(createMessage({ message: 'Account Added' }));
    dispatch({ type: ADD_SALES_ACCOUNT, payload: data });
    dispatch(addMiscActivityLog(newActivityLog));
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    // error message dispatched from view
    if (err && err.response) {
      throw err;
    } else
      dispatch(
        returnErrors(
          {
            message: ['An Unexpected Error Occured. Please try again later'],
          },
          500
        )
      );
  }
};

// EDIT SALES ACCOUNT
export const editSalesAccount = values => async (dispatch, getState) => {
  const accountDetails = {
    ...values,
    type: 'account',
    account_date: moment(new Date()).format('YYYY-MM-DD'), // added type && account_date key for transection locking
    opening_balance_date: values.opening_balance_date
      ? moment(values.opening_balance_date).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    limit: values.limit || 0,
    account_name_sorting: values.sales_account_name
      ? values.sales_account_name.toLowerCase()
      : '',
  };
  const salesContacts = [...accountDetails.sales_contacts];

  if (
    !Object.values(accountDetails.primary_contact_details).every(item => !item)
  ) {
    const primaryContact = {
      title: accountDetails.primary_contact_details.title,
      first_name: accountDetails.primary_contact_details.first_name,
      last_name: accountDetails.primary_contact_details.last_name,
      gender: accountDetails.primary_contact_details.gender,
      email: accountDetails.primary_contact_details.email,
      mobile_num: accountDetails.primary_contact_details.mobile_num,
      phone_num: accountDetails.primary_contact_details.phone_num,
      primary_contact: true,
    };

    salesContacts.push(primaryContact);
  }
  delete accountDetails.primary_contact_details;

  const salesAccount = {
    ...accountDetails,
    sales_contacts: salesContacts,
    exchange_rate: values.exchange_rate ? values.exchange_rate : null,
  };
  const updateActivityLog = {
    module_id: values.id,
    module_num: values.id,
    module_name: values.sales_account_name,
    activity_type: 'Account',
    activity_title: 'Sales Account Updated',
    description: `${salesAccount.sales_account_name} updated`,
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/accounts/${values.id}/`,
      salesAccount,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Account Updated' }));
    dispatch({ type: EDIT_SALES_ACCOUNT, payload: res.data });
    dispatch(addMiscActivityLog(updateActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET UNPAID INVOICES
export const getUnpaidInvoices = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/accounts/${id}/unpaidInvoices`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_UNPAID_INVOICES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR INVOICES
export const clearSalesAccountInvoices = () => {
  return { type: CLEAR_INVOICES };
};

// GET NON DRAFT INVOICES BY SALES ACCOUNT ID
export const getNonDraftInvoicesOfSalesAccount =
  id => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/sales/accounts/${id}/nonDraftInvoices`,
        tokenConfig(getState)
      );
      dispatch({ type: GET_NON_DRAFT_INVOICES, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET ACCOUNT INCOME
export const getAccountIncome =
  (id, duration = 'this fiscal year', type = 'accrual') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/sales/salesAccount/${id}/income`,
        {
          ...tokenConfig(getState),
          params: {
            duration,
            type,
          },
        }
      );
      dispatch({ type: GET_ACCOUNT_INCOME, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET ACCOUNT TRANSACTIONS
export const getAccountTransactions = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/customer/${id}/transaction`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_TRANSACTIONS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD ACCOUNT COMMENTS
export const addAccountComment = values => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/CustomerComments/`,
      values,
      tokenConfig(getState)
    );
    dispatch({ type: ADD_ACCOUNT_COMMENT, payload: res.data });
    dispatch(createMessage({ message: 'Comment Added' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACCOUNT COMMENTS
export const getAccountComments = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/customer/${id}/comments`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_COMMENTS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE ACCOUNT COMMENTS
export const deleteAccountComment = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/CustomerComments/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: DELETE_ACCOUNT_COMMENT, payload: id });
    dispatch(createMessage({ message: 'Comment Deleted' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR ACTIVITY LOG
export const clearActivityLog = () => ({ type: CLEAR_ACTIVITY_LOG });

// GET ACCOUNT STATEMENT
export const getAccountStatement =
  (id, duration, type, customDates) => async (dispatch, getState) => {
    const queryParams = {
      duration,
      filter_type: type,
    };

    if (customDates) {
      queryParams.custom_start_date = moment(customDates.start_date).format(
        'YYYY-MM-DD'
      );
      queryParams.custom_end_date = moment(customDates.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/sales/salesAccount/${id}/statement`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_ACCOUNT_STATEMENT, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET SUPPLIER CREDIT DETAILS
export const getAccountCreditDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/customer/${id}/paymentsVoucher`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_CREDIT_DETAILS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIER LIMIT
export const checkSalesAccountCreditLimit =
  (id, amount) => async (dispatch, getState) => {
    try {
      await axios.get(
        `${API_URL}/api/Customer/LimitCheck?customer_id=${id}&total_amount=${amount}`,
        tokenConfig(getState)
      );
      dispatch({ type: CHECK_ACCOUNT_CREDIT_LIMIT, payload: false });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        dispatch({ type: CHECK_ACCOUNT_CREDIT_LIMIT, payload: true });
      } else {
        dispatch(returnErrors(err.response.data, err.response.status));
      }
    }
  };

// UPLOAD ACCOUNT FILE
export const uploadAccountFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/salesAccount/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_ACCOUNT_DOC, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET ACCOUNT DOCS
export const getAccountDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/salesAccount/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ACCOUNT_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE ACCOUNT DOC
export const deleteAccountDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/salesAccount/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Sales Account Doc Deleted' }));
    dispatch({ type: DELETE_ACCOUNT_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACCOUNT EMAILS
export const getAccountEmails = id => async (dispatch, getState) => {
  const params = {
    account_id: id,
    category: 'salesAccount',
  };

  try {
    const res = await axios.get(`${API_URL}/api/sendEmail/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_ACCOUNT_EMAILS, payload: res.data.results });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPLOAD EMAIL DOCUMENT
export const uploadEmailFile = async (document, config) => {
  const documentData = new FormData();
  documentData.append('file', document);

  const res = await axios.post(
    `${API_URL}/api/sendEmailFile/`,
    documentData,
    config
  );
  return res.data;
};

// SEND EMAIL
export const sendEmail = (id, data) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  const toEmails = data.sendTo.map(item => ({ email: item }));
  const ccEmails = data.cc.map(item => ({ email: item }));

  const payload = {
    sender: data.fromEmail,
    show_emp_logo: data.show_emp_logo,
    category: 'salesAccount',
    account_id: id,
    type: data.type,
    to: toEmails,
    cc: ccEmails,
    subject: data.subject,
    body: data.message,
    files: [],
    template: getEmailTemplate(data.templateData),
  };

  try {
    if (data.statement) {
      const statement = await uploadEmailFile(data.statement, config);
      payload.files.push(statement);
    }
    if (data.document) {
      const document = await uploadEmailFile(data.document, config);
      payload.files.push(document);
    }
    await axios.post(
      `${API_URL}/api/sendEmail/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Email Sent Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};
