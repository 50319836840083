import {
  GET_TRANSACTIONS_LOCKING,
  GET_TRANSACTION_LOCKING_BY_ID,
  LOCK_UNLOCK_TRANSACTION,
} from '../actions/transactionsLockingActions';

const initialState = {
  data: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_LOCKING:
      return {
        ...state,
        data: action.payload,
      };
    case GET_TRANSACTION_LOCKING_BY_ID:
      return {
        ...state,
        fetched: action.payload,
      };
    case LOCK_UNLOCK_TRANSACTION:
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    default:
      return state;
  }
};
