import axios from 'axios';
import { getRFPDetails } from '../../server';
import { returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const CLEAR_OPERATIONS_DATA = 'CLEAR_OPERATIONS_DATA';
export const CLEAR_PRODUCT_LAUNCH_DATA = 'CLEAR_PRODUCT_LAUNCH_DATA';
export const GET_RFP_DETAILS = 'GET_RFP_DETAILS';
export const SAVE_RFP_DETAILS = 'SAVE_RFP_DETAILS';

export const clearOperationsData = () => ({ type: CLEAR_OPERATIONS_DATA });
export const clearProductLaunchData = () => ({
  type: CLEAR_PRODUCT_LAUNCH_DATA,
});

export const getRFPSeen = id => async (dispatch, getState) => {
  try {
    await axios.get(
      `${API_URL}/api/empevents/rfp_seen/${id}/status`,
      tokenConfig(getState)
    );
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getRFPDetailsData = id => async dispatch => {
  try {
    const res = await getRFPDetails(id);
    dispatch({
      type: GET_RFP_DETAILS,
      payload: res,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const saveRFPDetailsData = details => ({
  type: SAVE_RFP_DETAILS,
  payload: details,
});
