/* eslint-disable no-param-reassign */
import { round } from 'lodash';
import groupArrayOfObjects from './groupArrayOfObjects';

export const formatPurchaseByItemComparisonReportValues = (reportData = []) => {
  const formattedData = [];

  // Fetching Out Items and making them unique
  const reportDataUniqueItems = [
    ...new Set(reportData.map(({ item }) => item)),
  ];

  // Looping through each item
  reportDataUniqueItems.forEach(targetDataItemName => {
    // Fetching eatch item values and modifing the keys according to comparison
    const formattedTargetItem = {
      item_name: targetDataItemName,
    };
    const targetItemValues = reportData.filter(
      ({ item }) => item === targetDataItemName
    );
    targetItemValues.forEach(
      ({
        comparison_key: comparisonKey,
        amount,
        average,
        quantity,
        supplier_name: supplierName,
      }) => {
        // Modifying keys for target item values
        formattedTargetItem[`${comparisonKey}_amount`] = amount || '';
        formattedTargetItem[`${comparisonKey}_average`] = average || '';
        formattedTargetItem[`${comparisonKey}_quantity`] = quantity || '';
        formattedTargetItem.supplier_name = supplierName || '';
      }
    );

    // Finally pushing formatted data item into formatted data
    formattedData.push(formattedTargetItem);
  });
  return formattedData.length > 0 ? formattedData : reportData;
};

export const calculatePurchaseByItemComparisonTotal = (reportData = []) => {
  const formattedTotal = {
    item_name: 'Total',
  };
  // Grouping Data by comaprison key
  const groupedData = groupArrayOfObjects(reportData, 'comparison_key');

  Object.entries(groupedData).forEach(
    ([comparisonKey, comparisonWiseValues]) => {
      const comparisonWiseTotal = comparisonWiseValues.reduce(
        (acc, val) => {
          acc.amount = val.amount
            ? acc.amount + round(val.amount, 2)
            : acc.amount;
          acc.quantity = val.quantity
            ? acc.quantity + round(val.quantity, 2)
            : acc.quantity;
          // acc.average = val.average
          //   ? acc.average + round(val.average, 2)
          //   : acc.average;
          return acc;
        },
        {
          amount: 0,
          // average: 0,
          quantity: 0,
        }
      );
      formattedTotal[`${comparisonKey}_amount`] = comparisonWiseTotal.amount;
      // formattedTotal[`${comparisonKey}_average`] = comparisonWiseTotal.average;
      formattedTotal[`${comparisonKey}_quantity`] =
        comparisonWiseTotal.quantity;
    }
  );

  return formattedTotal;
};

export const reduceExpenseByCategoryDuplicateItemValues = data => {
  const reducedItems = data.reduce((combineItem, item) => {
    combineItem[item.chart_of_account__account_name] = combineItem[
      item.chart_of_account__account_name
    ] || {
      ...item,
      amount_without_tax: 0,
      total_amount: 0,
    };
    combineItem[item.chart_of_account__account_name].amount_without_tax +=
      item.amount_without_tax;
    combineItem[item.chart_of_account__account_name].total_amount +=
      item.total_amount;
    return combineItem;
  }, {});

  return Object.values(reducedItems);
};

export const groupPurchaseByItemReport = (reportData = []) => {
  return groupArrayOfObjects(reportData, 'supplier_name');
};

export const groupPurchaseByItemSimpleData = (reportData = []) => {
  const formattedData = {};
  const groupedData = groupArrayOfObjects(reportData, 'supplier_name');
  Object.entries(groupedData).forEach(
    ([supplierName, supplierTransactions]) => {
      const supplierTransactionTotals = supplierTransactions.reduce(
        (acc, val) => ({
          quantity: acc.quantity + (val.quantity || 0),
          amount: acc.amount + (val.amount || 0),
          average: acc.average + (val.average || 0),
        })
      );
      formattedData[supplierName] = {
        data: supplierTransactions,
        ...supplierTransactionTotals,
      };
    }
  );
  return formattedData;
};
