import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const UPLOAD_COMPANY_FILE = 'UPLOAD_COMPANY_FILE';
export const GET_COMPANY_FILES = 'GET_COMPANY_FILES';
export const GET_COMPANY_FILE = 'GET_COMPANY_FILE';
export const DELETE_COMPANY_FILE = 'DELETE_COMPANY_FILE';
export const RENAME_COMPANY_FILE = 'RENAME_COMPANY_FILE';
export const CLEAR_COMPANY_FILE = 'CLEAR_COMPANY_FILE';
export const ADD_COMPANY_FOLDER = 'ADD_COMPANY_FOLDER';
export const DELETE_COMPANY_FOLDER = 'DELETE_COMPANY_FOLDER';
export const GET_COMPANY_FOLDER_DATA = 'GET_COMPANY_FOLDER_DATA';
export const ADD_COMPANY_FOLDER_IN_HIERARCHY =
  'ADD_COMPANY_FOLDER_IN_HIERARCHY';
export const REMOVE_COMPANY_FOLDER_FROM_HIERARCHY =
  'REMOVE_COMPANY_FOLDER_FROM_HIERARCHY';
export const REMOVE_COMPANY_FOLDERS_FROM_HIERARCHY =
  'REMOVE_COMPANY_FOLDERS_FROM_HIERARCHY';
export const CLEAR_COMPANY_FOLDERS_HIERARCHY =
  'CLEAR_COMPANY_FOLDERS_HIERARCHY';
export const CLEAR_COMPANY_STORAGE = 'CLEAR_COMPANY_STORAGE';

// UPLOAD COMPANY FILE
export const uploadCompanyFile =
  (values, folderId) => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append('folder', folderId);
    formData.append('location', 'CompanyDoc');
    formData.append('doc_type', values.doc_file.file.type);
    formData.append('doc_name', values.doc_file.file.name);
    formData.append('doc_size_bytes', values.doc_file.file.size);
    formData.append(
      'doc_file',
      values.doc_file.file,
      values.doc_file.file.name
    );

    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';
    try {
      const res = await axios.post(
        `${API_URL}/api/CompanyDocViewSet/`,
        formData,
        config
      );
      dispatch(createMessage({ message: 'File Uploaded' }));
      dispatch({ type: UPLOAD_COMPANY_FILE, payload: res.data });
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      else
        dispatch(
          returnErrors({ message: ['An unexpected error occured'] }, 500)
        );
    }
  };

// GET COMPANY FILES
export const getCompanyFiles = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyDocViewSet/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_COMPANY_FILES,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// GET COMPANY FILE
export const getCompanyFile = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyDocViewSet/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_COMPANY_FILE,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// DELETE COMPANY FILE
export const deleteCompanyFile = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/CompanyDocViewSet/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'File Deleted' }));
    dispatch({ type: DELETE_COMPANY_FILE, payload: item.id });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

// RENAME COMPANY FILE
export const renameCompanyFile = values => async (dispatch, getState) => {
  try {
    await axios.put(
      `${API_URL}/api/CompanyDocViewSet/${values.id}/`,
      { doc_name: values.newName },
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'File Renamed Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

export const clearCompanyFile = () => ({
  type: CLEAR_COMPANY_FILE,
});

// DELETE FOLDER
export const deleteCompanyFolder = item => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/Folder/${item.itemId}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Folder Deleted' }));
    dispatch({
      type: DELETE_COMPANY_FOLDER,
      payload: item.id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD FOLDER
export const addCompanyFolder =
  (values, parentId) => async (dispatch, getState) => {
    const newFolder = { ...values };
    newFolder.parent_id = parentId || 0;
    try {
      const res = await axios.post(
        `${API_URL}/api/Folder/`,
        newFolder,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'Folder Added' }));
      dispatch({
        type: ADD_COMPANY_FOLDER,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET FOLDER FILES
export const getCompanyFolderData = folderId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyDocViewSet/get_folders_files/?folder_id=${folderId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_COMPANY_FOLDER_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addCompanyFolderInHierarchy = data => ({
  type: ADD_COMPANY_FOLDER_IN_HIERARCHY,
  payload: data,
});

export const removeCompanyFolderFromHierarchy = folderId => ({
  type: REMOVE_COMPANY_FOLDER_FROM_HIERARCHY,
  payload: folderId,
});

export const removeCompanyFoldersFromHierarchy = index => ({
  type: REMOVE_COMPANY_FOLDERS_FROM_HIERARCHY,
  payload: index,
});

export const clearCompanyFoldersHierarchy = () => ({
  type: CLEAR_COMPANY_FOLDERS_HIERARCHY,
});

export const clearCompanyStorage = () => ({ type: CLEAR_COMPANY_STORAGE });
