import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';

// ADD ACTIVITY LOG
export const addActivityLog = values => async (dispatch, getState) => {
  try {
    await axios.post(
      `${API_URL}/api/accounting/ActivityLog/`,
      values,
      tokenConfig(getState)
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};
