import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';

import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import { OPERATIONS_MANAGER } from '../../../utils/constants';
import useAuth from '../../Common/CustomHooks/useAuth';
import useToggle from '../../Common/CustomHooks/useToggle';
import TopbarMenuLink from './TopbarMenuLink';
import ToolTip from '../../Common/ToolTip';

const SettingsIcon = props => (
  <button {...props} className="topbar__avatar" type="button">
    <ToolTip text="Settings" position="bottom" className="my-auto">
      <CogOutlineIcon size={20} color="#BABBBC" />
    </ToolTip>
  </button>
);

function TopbarSettings() {
  // const history = useHistory();
  const {
    isAccountingSupervisor,

    profile: { role_name: role },
    permission,
  } = useSelector(state => state.auth.user);

  const {
    role: { isHRDeptUser, isAccountingDeptUser, isOperationsDeptUser },
  } = useAuth();
  const { isOpen: isMenuOpen, toggle: toggleMenu } = useToggle();
  const isOperationsManager = role === OPERATIONS_MANAGER;
  const allowAccess = permission ? permission.user_accessibility : false;
  // const navigateToSettings = useCallback(
  //   () => history.push('/pages/settings'),
  //   []
  // );
  // if (isHRDeptUser) return <SettingsIcon onClick={navigateToSettings} />;

  const menuOptions = useMemo(
    () => [
      ...(isAccountingDeptUser
        ? [
            {
              title: 'Opening Balance',
              path: '/pages/accounting/openingBalance',
              onClick: toggleMenu,
            },
          ]
        : []),

      ...(isHRDeptUser
        ? [
            {
              title: 'Settings',
              path: '/pages/settings',
              onClick: toggleMenu,
            },
          ]
        : []),
      ...(isAccountingSupervisor || isOperationsManager
        ? [
            {
              title: 'Stamp and Signature',
              path: '/pages/accounting/stampAndSignature',
              onClick: toggleMenu,
            },
          ]
        : []),

      ...(isOperationsDeptUser
        ? [
            {
              title: 'Email Settings',
              path: '/pages/operations/email-settings',
              onClick: toggleMenu,
            },
          ]
        : []),
      ...(allowAccess
        ? [
            {
              title: 'User Accessibility',
              path: '/pages/accessibility',
              onClick: toggleMenu,
            },
          ]
        : []),
    ],
    []
  );

  return (
    <div className="topbar__profile">
      <SettingsIcon onClick={toggleMenu} />
      {isMenuOpen && (
        <button className="topbar__back" type="button" onClick={toggleMenu} />
      )}
      <Collapse isOpen={isMenuOpen} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {menuOptions.map(item => (
            <TopbarMenuLink key={item.title} {...item} />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default TopbarSettings;
