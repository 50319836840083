export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const ENABLE_GLOBAL_LOADER = 'ENABLE_GLOBAL_LOADER';
export const DISABLE_GLOBAL_LOADER = 'DISABLE_GLOBAL_LOADER';

export function changeThemeToDark() {
  return {
    type: CHANGE_THEME_TO_DARK,
  };
}

export function changeThemeToLight() {
  return {
    type: CHANGE_THEME_TO_LIGHT,
  };
}

export function enableGlobalLoader() {
  return {
    type: ENABLE_GLOBAL_LOADER,
  };
}

export function disableGlobalLoader() {
  return {
    type: DISABLE_GLOBAL_LOADER,
  };
}
