import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_USER_PERMISSION_DETIALS = 'GET_USER_PERMISSION_DETIALS';
export const GET_EMPLOYEES_PERMISSIONS = 'GET_EMPLOYEES_PERMISSIONS';
export const CLEAR_USER_PERMISSION_DETIALS = 'CLEAR_USER_PERMISSION_DETIALS';

export const USERS_LOADING = 'USERS_LOADING';
export const USERS_LOADED = 'USERS_LOADED';

export const getUserPermissonDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/permissions/employee_permissions/?user_profile=${id}`,
      tokenConfig(getState)
    );
    // dispatch(createMessage({ message: 'Quotation Note Updated' }));
    dispatch({ type: GET_USER_PERMISSION_DETIALS, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
export const clearUserPermissonDetails = () => ({
  type: CLEAR_USER_PERMISSION_DETIALS,
});

export const getAllEmployeesPermissions = () => async (dispatch, getState) => {
  dispatch({ type: USERS_LOADING });
  try {
    const res = await axios.get(
      `${API_URL}/api/department/employees`,
      tokenConfig(getState)
    );
    // dispatch(createMessage({ message: 'Quotation Note Updated' }));
    dispatch({
      type: GET_EMPLOYEES_PERMISSIONS,
      payload: res.data.results,
    });

    dispatch({ type: USERS_LOADED });
  } catch (err) {
    // error message dispatched from view
    dispatch({ type: USERS_LOADED });
    throw err;
  }
};

export const addUserPermissions = values => async (dispatch, getState) => {
  const permissions = values.modules
    .filter(module => {
      if (module.checked) {
        // eslint-disable-next-line no-param-reassign
        module.sub_modules = module.sub_modules.filter(
          subModule => subModule.checked
        );
        return true;
      }
      return false;
    })
    .reduce((acc, obj) => {
      // Concatenate the children arrays of each object into a single array
      return acc.concat(obj.sub_modules);
    }, [])
    .map(module => {
      return {
        all: module.all,
        create: module.create,
        update: module.update,
        delete: module.delete,
        view: module.view,
        report_name: module.report_name,
        module: module.module,
        section: module.section,
        report_download: module.report_download,
        more_actions: module.more_actions,
      };
    });
  permissions.push({
    all: values.user_accessibility,
    create: false,
    update: false,
    delete: false,
    view: false,
    report_name: null,
    module: 'User Accessibility',
    section: 'User Accessibility',
    report_download: false,
    more_actions: [],
  });
  const payload = {
    user_profile_id: values.user_profile_id,
    permissions,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/permissions/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Permissions Saved' }));
    dispatch({
      type: GET_USER_PERMISSION_DETIALS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    // error message dispatched from view
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};
