import {
  GET_CHART_OF_ACCOUNT,
  CLEAR_CHART_OF_ACCOUNT,
  GET_CHART_OF_ACCOUNTS,
  DELETE_CHART_OF_ACCOUNT,
  ADD_CHART_OF_ACCOUNT,
  EDIT_CHART_OF_ACCOUNT,
  GET_CHART_OF_ACCOUNTS_DEPOSIT,
  GET_CHART_OF_ACCOUNTS_EXPENSE,
  GET_ACCOUNT_TYPES,
  GET_ACCOUNT_TYPE,
  GET_CHART_OF_ACCOUNT_DETAILS,
  CLEAR_CHART_OF_ACCOUNT_DETAIL,
  GET_ITEM_SALE_CHART_OF_ACCOUNTS,
  GET_ITEM_COST_CHART_OF_ACCOUNTS,
  GET_CHART_OF_ACCOUNTS_CASH,
  GET_CHART_OF_ACCOUNTS_PURCHASE,
  GET_CHART_OF_ACCOUNTS_PAYMENT_MADE,
  GET_CHART_OF_ACCOUNTS_STOCK,
  CHART_OF_ACCOUNT_LOADING,
  CHART_OF_ACCOUNT_LOADED,
  GET_CHART_OF_ACCOUNTS_LIST,
} from '../actions/chartOfAccountsActions';
import transformDataInNestedStructure from '../../utils/transformDataInNestedStructure';

const initialState = {
  accountTypes: [],
  expenseAccounts: [],
  chartOfAccounts: [],
  chartOfAccountsList: [],
  itemSaleAccounts: [],
  itemCostAccounts: [],
  fetched: null,
  fetchedAccountType: null,
  accountDetails: null,
  transformedData: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHART_OF_ACCOUNT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_CHART_OF_ACCOUNT:
      return {
        ...state,
        fetched: null,
      };
    case GET_CHART_OF_ACCOUNTS:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case GET_CHART_OF_ACCOUNTS_LIST:
      return {
        ...state,
        chartOfAccountsList: transformDataInNestedStructure(action.payload),
      };
    case GET_CHART_OF_ACCOUNTS_DEPOSIT:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case DELETE_CHART_OF_ACCOUNT:
      return {
        ...state,
        chartOfAccountsList: state.chartOfAccountsList.filter(
          chartOfAccount => chartOfAccount.id !== action.payload
        ),
      };
    case ADD_CHART_OF_ACCOUNT:
      return {
        ...state,
        chartOfAccountsList: [...state.chartOfAccountsList, action.payload],
      };
    case EDIT_CHART_OF_ACCOUNT:
      return {
        ...state,
        chartOfAccountsList: state.chartOfAccountsList.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case GET_CHART_OF_ACCOUNTS_EXPENSE:
      return {
        ...state,
        expenseAccounts: action.payload,
      };
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: action.payload,
      };
    case GET_ACCOUNT_TYPE:
      return {
        ...state,
        fetchedAccountType: action.payload,
      };
    case GET_CHART_OF_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
      };
    case CLEAR_CHART_OF_ACCOUNT_DETAIL:
      return {
        ...state,
        accountDetails: [],
      };
    case GET_ITEM_SALE_CHART_OF_ACCOUNTS:
      return {
        ...state,
        itemSaleAccounts: action.payload,
      };
    case GET_ITEM_COST_CHART_OF_ACCOUNTS:
      return {
        ...state,
        itemCostAccounts: action.payload,
      };
    case GET_CHART_OF_ACCOUNTS_CASH:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case GET_CHART_OF_ACCOUNTS_PURCHASE:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case GET_CHART_OF_ACCOUNTS_PAYMENT_MADE:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case GET_CHART_OF_ACCOUNTS_STOCK:
      return {
        ...state,
        chartOfAccounts: action.payload,
      };
    case CHART_OF_ACCOUNT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CHART_OF_ACCOUNT_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
