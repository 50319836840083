import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_FINAL_PAY = 'GET_FINAL_PAY';
export const CLEAR_FINAL_PAY = 'CLEAR_FINAL_PAY';
export const ADD_FINAL_PAY = 'ADD_FINAL_PAY';
export const DELETE_FINAL_PAY = 'DELETE_FINAL_PAY';
export const EDIT_FINAL_PAY = 'EDIT_FINAL_PAY';

// GET FinalPay
export const getFinalPay = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/FinalPay/final_pay_list/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_FINAL_PAY,
      payload: res.data && res.data[0],
    });
  } catch (err) {
    if (err && err.response && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR FinalPay
export const clearFinalPay = () => {
  return {
    type: CLEAR_FINAL_PAY,
  };
};

// DELETE FinalPay
export const deleteFinalPay = id => async (dispatch, getState) => {
  try {
    await axios.delete(`${API_URL}/api/FinalPay/${id}/`, tokenConfig(getState));
    dispatch(createMessage({ message: 'FinalPay Deleted' }));
    dispatch({
      type: DELETE_FINAL_PAY,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD FinalPay
export const addFinalPay = values => async (dispatch, getState) => {
  const newData = {
    user_profile_id: values.user_profile_id,
    one_month_notice_amount: values.one_month_notice_amount,
    is_addition: values.is_addition === 'true',
    add_deduct_note: values.add_deduct_note,
    add_deduct_reason: values.add_deduct_reason,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/FinalPay/?gratuity=${values.gratuity}`,
      newData,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Final Pay Added' }));
    dispatch({
      type: ADD_FINAL_PAY,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT FinalPay
export const editFinalPay = values => async (dispatch, getState) => {
  const editedData = {
    user_profile_id: values.user_profile_id,
    one_month_notice_amount: values.one_month_notice_amount,
    is_addition: values.is_addition === 'true',
    add_deduct_note: values.add_deduct_note,
    add_deduct_reason: values.add_deduct_reason,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/FinalPay/${values.id}/`,
      editedData,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Final Pay Updated' }));
    dispatch({
      type: EDIT_FINAL_PAY,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
