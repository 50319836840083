import axios from 'axios';
import moment from 'moment';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const GET_DOC_REQUESTS = 'GET_DOC_REQUESTS';
export const CLEAR_DOC_REQUESTS = 'CLEAR_DOC_REQUESTS';
export const GET_USER_DOC_REQUESTS = 'GET_USER_DOC_REQUESTS';
export const GET_DOC_REQUEST_HISTORY = 'GET_DOC_REQUEST_HISTORY';
export const CLEAR_DOC_REQUEST_HISTORY = 'CLEAR_DOC_REQUEST_HISTORY';
export const GET_FILTERED_DOC_REQUESTS = 'GET_FILTERED_DOC_REQUESTS';
export const DELETE_DOC_REQUEST = 'DELETE_DOC_REQUEST';
export const REQUEST_FOR_DOC = 'REQUEST_FOR_DOC';
export const UPLOAD_REQUESTED_DOCUMENT = 'UPLOAD_REQUESTED_DOCUMENT';

export const getDocRequests = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/EmployeeRequest/employee_request_allist/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DOC_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    if (err && err.response && err.response.status === 404) {
      dispatch({ type: CLEAR_DOC_REQUESTS });
    }
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const uploadRequestedDocument =
  (file, recordId, deleteFile, remarks) => async (dispatch, getState) => {
    const formData = new FormData();
    const config = tokenConfig(getState);
    let payload = {
      doc_file: null,
      status: !deleteFile ? 'cancelled' : deleteFile,
      remarks,
    };

    if (file) {
      formData.append('doc_file', file.doc_file.file, file.doc_file.name);
      formData.append('status', 'uploaded');
      formData.append('doc_type', file.doc_file.file.type);
      config.headers['Content-Type'] = 'multipart/form-data';
      payload = formData;
    }
    try {
      const res = await axios.put(
        `${API_URL}/api/EmployeeRequest/${recordId}/`,
        payload,
        config
      );
      dispatch({
        type: UPLOAD_REQUESTED_DOCUMENT,
        payload: res.data,
      });
    } catch (err) {
      if (err && err.response && err.response.status === 404) {
        dispatch({ type: CLEAR_DOC_REQUESTS });
      }
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getUserDocRequests = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/EmployeeRequest/employee_request_allist/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_DOC_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};
export const getDocRequestsHistory = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/EmployeeRequest/employee_request_history/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_DOC_REQUEST_HISTORY,
      payload: res.data,
    });
  } catch (err) {
    if (err && err.response && err.response.status === 404) {
      dispatch({ type: CLEAR_DOC_REQUEST_HISTORY });
    }
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getFilteredDocRequests = values => async (dispatch, getState) => {
  const queryParams = {};
  if (values.status) queryParams.status = values.status;
  if (values.user_profile_id)
    queryParams.user_profile_id = values.user_profile_id;
  if (values.request_type) queryParams.request_type = values.request_type;
  if (values.start_date)
    queryParams.from_date = moment(values.start_date).format('YYYY-MM-DD');
  if (values.end_date)
    queryParams.to_date = moment(values.end_date).format('YYYY-MM-DD');
  if (values.company) queryParams.company = values.company;
  try {
    const res = await axios.get(
      `${API_URL}/api/EmployeeRequest/employee_request_allist/`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({
      type: GET_FILTERED_DOC_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    if (err && err.response && err.response.status === 404) {
      dispatch({ type: CLEAR_DOC_REQUESTS });
    }
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const deleteDocRequest = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/EmployeeRequest/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Doc Request Deleted' }));
    dispatch({ type: DELETE_DOC_REQUEST, payload: id });
  } catch (err) {
    throw err;
  }
};

export const requestForDoc = values => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/EmployeeRequest/`,
      values,
      tokenConfig(getState)
    );
    dispatch(
      createMessage({
        message: `Successfully requested for ${values.request_type}`,
      })
    );
    dispatch({
      type: REQUEST_FOR_DOC,
      payload: res.data,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};
