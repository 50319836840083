import axios from 'axios';
// import { API_URL } from '../../utils/constants';

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';

// GET CLIENTS
export const getClients = () => async dispatch => {
  const res = await axios.get(`/api/clients/`);
  dispatch({
    type: GET_CLIENTS,
    payload: res.data,
  });
};

// DELETE CLIENT
export const deleteClient = id => async dispatch => {
  await axios.delete(`/api/clients/${id}/`);
  dispatch({
    type: DELETE_CLIENT,
    payload: id,
  });
};

// ADD CLIENT
export const addClient = values => async dispatch => {
  const client = {
    client_name: values.clientName,
    industry: values.industry ? values.industry.value : '',
    client_type: values.clientType ? values.clientType.value : '',
    address: values.address,
    country: values.country ? values.country.value : '',
    account_manager: values.accountManager,
    telephone: values.telephone,
    email_address: values.emailAddress,
    website: values.website,
    geo_coordinates: values.geoCoordinates,
    linkedin_page: values.linkedinPage,
    facebook_page: values.facebookPage,
    notes: values.notes,
  };
  const res = await axios.post(`/api/clients/`, client);
  dispatch({
    type: ADD_CLIENT,
    payload: res.data,
  });
};
