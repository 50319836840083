import axios from 'axios';
// import { API_URL } from '../../utils/constants';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';

// GET DOCUMENTS
export const getDocuments = () => async dispatch => {
  const res = await axios.get(`/api/documents/`);
  dispatch({
    type: GET_DOCUMENTS,
    payload: res.data,
  });
};
