import {
  GET_FINAL_PAY,
  CLEAR_FINAL_PAY,
  ADD_FINAL_PAY,
  EDIT_FINAL_PAY,
} from '../actions/finalPayActions';

const initialState = {
  pay: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FINAL_PAY:
      return {
        ...state,
        pay: action.payload,
      };
    case CLEAR_FINAL_PAY:
      return {
        ...state,
        pay: null,
      };
    case ADD_FINAL_PAY:
    case EDIT_FINAL_PAY:
      return {
        ...state,
        pay: action.payload,
      };
    default:
      return state;
  }
};
