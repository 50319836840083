import {
  GET_EXPENSE,
  CLEAR_EXPENSE,
  GET_EXPENSES,
  DELETE_EXPENSE,
  ADD_EXPENSE,
  EDIT_EXPENSE,
  ADD_EXPENSE_DOCS,
  DELETE_EXPENSE_DOC,
  GET_EXPENSE_JOURNAL,
  CLEAR_EXPENSE_JOURNAL,
  EXPENSE_LOADING,
  EXPENSE_LOADED,
  GET_EXPENSES_LIST,
} from '../actions/expensesActions';

const initialState = {
  count: null,
  expensesList: [],
  expenses: [],
  docs: [],
  fetched: null,
  journal: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_EXPENSE:
      return {
        ...state,
        fetched: null,
      };
    case GET_EXPENSES_LIST:
      return {
        ...state,
        count: action.payload.count,
        expensesList: action.payload.results,
      };
    case GET_EXPENSES:
      return {
        ...state,
        count: action.payload.count,
        expenses: action.payload.results,
      };
    case DELETE_EXPENSE:
      return {
        ...state,
        expensesList: state.expensesList.filter(
          supplier => supplier.id !== action.payload
        ),
      };
    case ADD_EXPENSE:
      return {
        ...state,
        expensesList: [...state.expensesList],
      };
    case EDIT_EXPENSE:
      return {
        ...state,
      };
    case ADD_EXPENSE_DOCS:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      };
    case DELETE_EXPENSE_DOC:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          expense_docs: state.fetched.expense_docs.filter(
            item => item.id !== action.payload
          ),
        },
      };
    case GET_EXPENSE_JOURNAL:
      return {
        ...state,
        journal: action.payload,
      };
    case CLEAR_EXPENSE_JOURNAL:
      return {
        ...state,
        journal: null,
      };
    case EXPENSE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case EXPENSE_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
