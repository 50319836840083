import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';

export const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
export const GET_COMPANY_PROFILES = 'GET_COMPANY_PROFILES';
export const CLEAR_COMPANY_PROFILE = 'CLEAR_COMPANY_PROFILE';
export const ADD_COMPANY_PROFILE = 'ADD_COMPANY_PROFILE';
export const DELETE_COMPANY_PROFILE = 'DELETE_COMPANY_PROFILE';
export const EDIT_COMPANY_PROFILE = 'EDIT_COMPANY_PROFILE';

export const getCompanyProfiles = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyProfile/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_COMPANY_PROFILES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const getCompanyProfile = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/CompanyProfile/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_COMPANY_PROFILE, payload: res.data });
  } catch (err) {
    if (err && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addCompanyProfile = values => async (dispatch, getState) => {
  const profile = {
    ...values,
    trade_license_expiration_date: values.trade_license_expiration_date
      ? moment(values.trade_license_expiration_date).format('YYYY-MM-DD')
      : '',
    establishment_card_expiration_date:
      values.establishment_card_expiration_date
        ? moment(values.establishment_card_expiration_date).format('YYYY-MM-DD')
        : '',
    ejari_expiration_date: values.ejari_expiration_date
      ? moment(values.ejari_expiration_date).format('YYYY-MM-DD')
      : '',
    third_party_liability_expiration_date:
      values.third_party_liability_expiration_date
        ? moment(values.third_party_liability_expiration_date).format(
            'YYYY-MM-DD'
          )
        : '',
    workmen_compensation_insurance_expiration_date:
      values.workmen_compensation_insurance_expiration_date
        ? moment(values.workmen_compensation_insurance_expiration_date).format(
            'YYYY-MM-DD'
          )
        : '',
    medical_insurance_expiration_date: values.medical_insurance_expiration_date
      ? moment(values.medical_insurance_expiration_date).format('YYYY-MM-DD')
      : '',
    aofc_expiration_date: values.aofc_expiration_date
      ? moment(values.aofc_expiration_date).format('YYYY-MM-DD')
      : '',
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/CompanyProfile/`,
      profile,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Profile Saved' }));
    dispatch({ type: ADD_COMPANY_PROFILE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editCompanyProfile = values => async (dispatch, getState) => {
  const profile = {
    ...values,
    trade_license_expiration_date: values.trade_license_expiration_date
      ? moment(values.trade_license_expiration_date).format('YYYY-MM-DD')
      : '',
    establishment_card_expiration_date:
      values.establishment_card_expiration_date
        ? moment(values.establishment_card_expiration_date).format('YYYY-MM-DD')
        : '',
    ejari_expiration_date: values.ejari_expiration_date
      ? moment(values.ejari_expiration_date).format('YYYY-MM-DD')
      : '',
    third_party_liability_expiration_date:
      values.third_party_liability_expiration_date
        ? moment(values.third_party_liability_expiration_date).format(
            'YYYY-MM-DD'
          )
        : '',
    workmen_compensation_insurance_expiration_date:
      values.workmen_compensation_insurance_expiration_date
        ? moment(values.workmen_compensation_insurance_expiration_date).format(
            'YYYY-MM-DD'
          )
        : '',
    medical_insurance_expiration_date: values.medical_insurance_expiration_date
      ? moment(values.medical_insurance_expiration_date).format('YYYY-MM-DD')
      : '',
    aofc_expiration_date: values.aofc_expiration_date
      ? moment(values.aofc_expiration_date).format('YYYY-MM-DD')
      : '',
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/CompanyProfile/${values.id}/`,
      profile,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Profile Updated' }));
    dispatch({ type: EDIT_COMPANY_PROFILE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const deleteCompanyProfile = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/CompanyProfile/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Company Profile Deleted' }));
    dispatch({ type: DELETE_COMPANY_PROFILE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearCompanyProfile = () => ({ type: CLEAR_COMPANY_PROFILE });
