import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';

export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const CLEAR_ANNOUNCEMENT = 'CLEAR_ANNOUNCEMENT';
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT';
// export const GET_HOLIDAY_DATES = 'GET_HOLIDAY_DATES';

export const getAnnouncements = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/announcements/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ANNOUNCEMENTS, payload: res.data.results });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const getAnnouncement = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/announcements/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ANNOUNCEMENT, payload: res.data });
  } catch (err) {
    if (err && err.response.status === 400) return;
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const addAnnouncement = values => async (dispatch, getState) => {
  let payload = {};
  if (values.attachment !== null && typeof values.attachment === 'object') {
    const formData = new FormData();
    formData.append(
      'end_date',
      values.date ? moment(values.date).format('YYYY-MM-DD') : ''
    );
    formData.append('subject', values.subject);
    formData.append('description', values.description);
    formData.append('file', values.attachment);
    payload = formData;
  } else {
    payload = {
      end_date: values.date ? moment(values.date).format('YYYY-MM-DD') : '',
      subject: values.subject,
      description: values.description,
      file: values.attachment ? values.attachment : null,
    };
  }

  try {
    const res = await axios.post(
      `${API_URL}/api/announcements/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Announcement Saved' }));
    dispatch({ type: ADD_ANNOUNCEMENT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editAnnouncement = values => async (dispatch, getState) => {
  let payload = {};
  if (values.attachment !== null && typeof values.attachment === 'object') {
    const formData = new FormData();
    formData.append(
      'end_date',
      values.date ? moment(values.date).format('YYYY-MM-DD') : ''
    );
    formData.append('subject', values.subject);
    formData.append('description', values.description);
    formData.append('file', values.attachment);
    payload = formData;
  } else {
    payload = {
      end_date: values.date ? moment(values.date).format('YYYY-MM-DD') : '',
      subject: values.subject,
      description: values.description,
      file: values.attachment ? values.attachment : null,
    };
  }

  try {
    const res = await axios.put(
      `${API_URL}/api/announcements/${values.id}/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Announcement Updated' }));
    dispatch({ type: EDIT_ANNOUNCEMENT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const deleteAnnouncement = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/announcements/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Announcement Deleted' }));
    dispatch({ type: DELETE_ANNOUNCEMENT, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearAnnouncement = () => ({ type: CLEAR_ANNOUNCEMENT });
