/* eslint-disable no-plusplus */
import axios from 'axios';
import moment from 'moment';
import { round } from 'lodash/math';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { addActivityLog } from './miscActions';
import { createMessage, returnErrors } from './messagesActions';

export const GET_JOURNALS = 'GET_JOURNALS';
export const GET_JOURNAL = 'GET_JOURNAL';
export const CLEAR_JOURNAL = 'CLEAR_JOURNAL';
export const ADD_JOURNAL = 'ADD_JOURNAL';
export const DELETE_JOURNAL = 'DELETE_JOURNAL';
export const EDIT_JOURNAL = 'EDIT_JOURNAL';
export const GET_JOURNAL_DOCS = 'GET_JOURNAL_DOCS';
export const ADD_JOURNAL_DOCS = 'ADD_JOURNAL_DOCS';
export const DELETE_JOURNAL_DOC = 'DELETE_JOURNAL_DOC';
export const GET_JOURNAL_NOTE = 'GET_JOURNAL_NOTE';
export const EDIT_JOURNAL_NOTE = 'EDIT_JOURNAL_NOTE';
export const DELETE_JOURNAL_NOTE = 'DELETE_JOURNAL_NOTE';
export const PUBLISH_JOURNAL = 'PUBLISH_JOURNAL';
export const JOURNALS_LOADING = 'JOURNALS_LOADING';
export const JOURNALS_LOADED = 'JOURNALS_LOADED';

export const getLatestJournalItemNum = () => async (_, getState) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/journalItem/latest`,
    tokenConfig(getState)
  );
  return data;
};

// GET JOURNAL
export const getJournal = (id, params) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/journals/${id}/${params || ''}`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_JOURNAL, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR JOURNAL
export const clearJournal = () => {
  return { type: CLEAR_JOURNAL };
};

// GET JOURNALS
export const getJournals = params => async (dispatch, getState) => {
  try {
    dispatch({ type: JOURNALS_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/journals/`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_JOURNALS, payload: res.data });
    dispatch({ type: JOURNALS_LOADED });
  } catch (err) {
    dispatch({ type: JOURNALS_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST JOURNAL
export const getLatestJournal = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/journals/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// DELETE JOURNAL
export const deleteJournal = journal => async (dispatch, getState) => {
  const deleteActivityLog = {
    activity_type: 'Manual Journal',
    activity_title: 'Manual Journal Deleted',
    description: `Manual Journal (${journal.journal_num}) of ${journal.currency} ${journal.total}  deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/journals/${journal.id}/`,
      {
        ...tokenConfig(getState),
        data: { type: 'manual_journal', journal_date: journal.journal_date },
      }
    );
    dispatch(createMessage({ message: 'Journal Deleted' }));
    dispatch({ type: DELETE_JOURNAL, payload: journal.id });
    dispatch(addActivityLog(deleteActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD JOURNAL
export const addJournal = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestJournalItemNum());
    let lastNum = latestNum.latest_num || 0;

    const journalItems = values.journal_items.map(item => {
      // const customerType = item.contact.split('-')[0];
      // const customerId = item.contact.split('-')[1];
      return {
        transaction_num: ++lastNum,
        chart_of_account_id: item.chart_of_account_id,
        debit: item.debits ? item.debits : 0,
        credit: item.credits ? item.credits : 0,
        // sales_account_id:
        //   customerType === 'sales_account' ? parseInt(customerId, 10) : null,
        // supplier_id:
        //   customerType === 'supplier' ? parseInt(customerId, 10) : null,
        description: item.description,
      };
    });
    const journalNotes = [
      {
        note_type: 'create',
        notes: values.notes,
      },
    ];
    const journal = {
      journal_num: values.parent
        ? values.latest_journal_num + 1
        : values.journal_num,
      journal_date:
        values.journal_date && values.journal_date !== ''
          ? moment(values.journal_date).format('YYYY-MM-DD')
          : null,
      is_cash_based: values.is_cash_based,
      currency: values.currency,
      exchange_rate: values.exchange_rate,
      reference_num: values.reference_num,
      receipt_notes: values.receipt_notes,
      notes: values.notes,
      status: values.status,
      total: round(values.grand_debit_total, 2),
      journal_items: journalItems,
      journal_notes: values.notes ? journalNotes : [],
      type: 'manual_journal',
      prepared_by: values.prepared_by,
    };

    if (values.parent) journal.parent = values.parent;

    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/journals/`,
      journal,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: res.data.id,
      module_num: res.data.journal_num,
      activity_type: 'Manual Journal',
      activity_title: 'Manual Journal Added',
      description: `Manual Journal (${journal.journal_num}) of ${journal.currency} ${journal.total}  created`,
    };
    dispatch(createMessage({ message: 'Journal Added' }));
    dispatch({ type: ADD_JOURNAL, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT JOURNAL
export const editJournal = values => async (dispatch, getState) => {
  const latestNum = await dispatch(getLatestJournalItemNum());
  let lastNum = latestNum.latest_num || 0;
  const journalItems = values.journal_items.map(item => {
    // const customerType = item.contact.split('-')[0];
    // const customerId = item.contact.split('-')[1];
    return {
      transaction_num: item.transaction_num || ++lastNum,
      chart_of_account_id: item.chart_of_account_id,
      debit: item.debits ? item.debits : 0,
      credit: item.credits ? item.credits : 0,
      // sales_account_id:
      //   customerType === 'sales_account' ? parseInt(customerId, 10) : null,
      // supplier_id:
      //   customerType === 'supplier' ? parseInt(customerId, 10) : null,
      description: item.description,
    };
  });
  const journalNotes = [
    {
      journal_id: values.id,
      note_type: 'update',
      notes: values.notes,
    },
  ];
  const journal = {
    journal_num: values.journal_num,
    journal_date:
      values.journal_date && values.journal_date !== ''
        ? moment(values.journal_date).format('YYYY-MM-DD')
        : null,
    is_cash_based: values.is_cash_based,
    currency: values.currency,
    exchange_rate: values.exchange_rate,
    reference_num: values.reference_num,
    receipt_notes: values.receipt_notes,
    notes: values.notes,
    total: round(values.grand_debit_total, 2),
    journal_items: journalItems,
    journal_notes: values.notes ? journalNotes : [],
    status: values.status,
    type: 'manual_journal',
    transaction_num: values.transaction_num,
    prepared_by: values.prepared_by,
  };
  const updateActivityLog = {
    module_id: values.id,
    module_num: values.journal_num,
    activity_type: 'Manual Journal',
    activity_title: 'Manual Journal Updated',
    description: `Manual Journal (${journal.journal_num}) of ${journal.currency} ${journal.total}  updated`,
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/journals/${values.id}/`,
      journal,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Journal Updated' }));
    dispatch({ type: EDIT_JOURNAL, payload: res.data });
    dispatch(addActivityLog(updateActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET JOURNAL DOCS
export const getJournalDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/journals/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_JOURNAL_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD JOURNALS FILE
export const uploadJournalFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/journals/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_JOURNAL_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE JOURNAL DOC
export const deleteJournalDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/journals/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Journal Doc Deleted' }));
    dispatch({ type: DELETE_JOURNAL_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET JOURNAL NOTE
export const getJournalNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/journals/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_JOURNAL_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE JOURNAL NOTE
export const deleteJournalNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/journals/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Journal Note Deleted' }));
    dispatch({ type: DELETE_JOURNAL_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// EDIT JOURNAL NOTE
export const editJournalNote = values => async (dispatch, getState) => {
  const quotationNote = {
    quotation_id: values.quotation_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/journals/notes/${values.id}/`,
      quotationNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Journal Note Updated' }));
    dispatch({ type: EDIT_JOURNAL_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// PUBLISH JOURNAL
export const publishJournal =
  ({ id, ...payload }) =>
  async (dispatch, getState) => {
    const formattedJournalItems = payload.journal_items.map(item => ({
      debit: item.debit,
      credit: item.credit,
      description: item.description,
      transaction_num: item.transaction_num,
      chart_of_account_id: item.chart_of_account,
    }));
    const formattedPayload = {
      ...payload,
      journal_items: formattedJournalItems,
    };

    try {
      const res = await axios.put(
        `${API_URL}/api/accounting/accountant/journals/${id}/`,
        formattedPayload,
        tokenConfig(getState)
      );
      dispatch({
        type: PUBLISH_JOURNAL,
        payload: res.data,
      });
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch(returnErrors('An unexpected error occured', 500));
    }
  };
