import {
  CHANGE_LEAVE_STATUS,
  DELETE_LEAVE,
  EDIT_LEAVE,
  GET_FILTERED_LEAVES,
  GET_LEAVES,
  GET_USER_LEAVES,
  REQUEST_FOR_LEAVE,
  REQUEST_FOR_REVISE_LEAVE,
  GET_LEAVES_COUNT,
  CLEAR_LEAVES_COUNT,
  CLEAR_LEAVES,
  GET_LEAVES_TO_DISABLE,
  CLEAR_LEAVES_TO_DISABLE,
} from '../actions/leavesActions';

const initialState = {
  leaves: [],
  leavesToDisable: [],
  leavesCount: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAVES:
      return {
        ...state,
        leaves: action.payload,
      };
    case GET_USER_LEAVES:
      return {
        ...state,
        leaves: action.payload,
      };
    case GET_FILTERED_LEAVES:
      return {
        ...state,
        leaves: action.payload,
      };
    case DELETE_LEAVE:
      return {
        ...state,
        leaves: state.leaves.filter(leave => leave.id !== action.payload),
      };

    case EDIT_LEAVE:
      return {
        ...state,
        leaves: state.leaves.map(leave =>
          leave.id === action.payload.id
            ? {
                ...action.payload,
              }
            : leave
        ),
      };

    case REQUEST_FOR_LEAVE:
      return {
        ...state,
        leaves: [...state.leaves, action.payload],
        leavesToDisable: [
          ...state.leavesToDisable,
          ...action.payload.leave_dates,
        ],
      };

    case REQUEST_FOR_REVISE_LEAVE:
      return {
        ...state,
        leaves: [
          ...state.leaves.map(leave =>
            leave.id === action.payload.parent
              ? { ...leave, is_approved: 'revised' }
              : leave
          ),
          action.payload,
        ],
        leavesToDisable: [
          ...state.leavesToDisable,
          ...action.payload.leave_dates,
        ],
      };
    case CHANGE_LEAVE_STATUS:
      return {
        ...state,
        leaves: state.leaves.map(leave =>
          leave.id === action.payload.id
            ? {
                ...leave,
                is_approved: action.payload.is_approved,
                remarks: action.payload.remarks,
                decided_by: action.payload.decided_by,
                immediate_remarks: action.payload.immediate_remarks,
                is_approved_by_imm_super:
                  action.payload.is_approved_by_imm_super,
              }
            : leave
        ),
      };
    case GET_LEAVES_COUNT:
      return {
        ...state,
        leavesCount: action.payload,
      };
    case CLEAR_LEAVES_COUNT:
      return {
        ...state,
        leavesCount: null,
      };
    case CLEAR_LEAVES:
      return {
        ...state,
        leaves: [],
      };
    case GET_LEAVES_TO_DISABLE:
      return {
        ...state,
        leavesToDisable: action.payload,
      };
    case CLEAR_LEAVES_TO_DISABLE:
      return {
        ...state,
        leavesToDisable: [],
      };
    default:
      return state;
  }
};
