import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import SearchIcon from 'mdi-react/MagnifyIcon';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import useClickOutside from './CustomHooks/useClickOutside';

const searchOptions = [
  { link: 'items', label: 'Items' },
  { link: 'sales/accounts', label: 'Accounts' },
  { link: 'sales/customers', label: 'Customers' },
  { link: 'sales/quotations', label: 'Quotations' },
  { link: 'sales/proInvoices', label: 'Proforma Invoices' },
  { link: 'sales/invoices', label: 'Invoices' },
  { link: 'sales/paymentsReceived', label: 'Payments Received' },
  { link: 'sales/creditNotes', label: 'Credit Notes' },
  { link: 'purchases/suppliers', label: 'Suppliers' },
  { link: 'purchases/expenses', label: 'Expenses' },
  { link: 'purchases/purOrders', label: 'Purchase Orders' },
  { link: 'purchases/bills', label: 'Bills' },
  { link: 'purchases/paymentsMade', label: 'Payments Made' },
  { link: 'purchases/supplierCredits', label: 'Debit Notes' },
  { link: 'accountant/journals', label: 'Manual Journals' },
];

function SearchBar() {
  const inputRef = useRef();
  const componentRef = useRef();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);

  const searchQuery = query.get('query');

  const [selectedPage, setSelectedPage] = useState(searchOptions[1]);
  const [showMenu, setMenu] = useState(false);
  const [searchText, setSearchText] = useState(searchQuery || '');
  const [showResetBtn, setResetBtn] = useState(!!searchQuery);

  const toggleMenu = () => setMenu(prevState => !prevState);

  const closeDropDown = () => setMenu(false);

  useClickOutside(componentRef, showMenu, closeDropDown);

  useEffect(() => {
    if (!searchQuery) {
      setResetBtn(false);
      setSearchText('');
    }
  }, [searchQuery]);

  useEffect(() => {
    const currentPage = searchOptions.find(item =>
      pathname.includes(item.link)
    );

    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(searchOptions[1]);
    }
  }, [pathname]);

  const handleSelect = item => {
    setSelectedPage(item);
    setMenu(false);
    inputRef.current.focus();
  };

  const handleSearch = keyCode => {
    if (keyCode === 13) {
      if (searchText) {
        history.push({
          pathname: `/pages/accounting/${selectedPage.link}`,
          search: `query=${searchText}`,
        });
        setResetBtn(true);
      } else {
        history.push(`/pages/accounting/${selectedPage.link}`);
        setResetBtn(false);
      }
      // setSearchText('');
    }
  };

  const handleReset = () => {
    history.push(`/pages/accounting/${selectedPage.link}`);
    setSearchText('');
    setResetBtn(false);
  };

  return (
    <div ref={componentRef} className="topbar__search">
      <span className="topbar__search-dropdown">
        <span
          className="topbar__search-dropdown--head"
          role="button"
          tabIndex={0}
          onClick={toggleMenu}
        >
          <SearchIcon size={18} className="clr-add" />
          <MenuDownIcon size={20} className="clr-add menudown-icon" />
          <span className="dropdown-label">{selectedPage.label}</span>
        </span>
        <div
          className={`topbar__search-dropdown--menu${showMenu ? ' show' : ''}`}
        >
          {searchOptions.map(item => (
            <button
              key={item.link}
              type="button"
              onClick={() => handleSelect(item)}
            >
              {selectedPage.link === item.link && (
                <CheckIcon className="check-icon" size={18} />
              )}
              {item.label}
            </button>
          ))}
        </div>
      </span>
      <input
        className="topbar__search-field"
        name="search"
        autoComplete="off"
        placeholder={`Search in ${selectedPage.label}`}
        ref={inputRef}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        onFocus={() => setMenu(false)}
        onKeyUp={e => handleSearch(e.keyCode)}
      />
      {showResetBtn && (
        <span
          className="topbar__search-reset-btn"
          role="button"
          tabIndex={0}
          onClick={handleReset}
        >
          Reset
        </span>
      )}
    </div>
  );
}

export default SearchBar;
