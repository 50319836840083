import {
  CLEAR_SALARIES,
  CLEAR_SINGLE_SALARY,
  CLEAR_DATA,
  START_LOADING,
  STOP_LOADING,
  GENERATE_SALARIES,
  GET_SALARIES,
  GET_USER_GRATUITY,
  GET_EDIT_SINGLE_SALARY,
  GET_SINGLE_SALARY_DETAILS,
} from '../actions/salaryActions';

const initialState = {
  isLoading: false,
  data: {
    count: 0,
    results: [],
  },
  userGratuity: null,
  editSingleSalary: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case GET_SALARIES:
      return {
        ...state,
        data: action.payload,
      };
    case GENERATE_SALARIES:
      return {
        ...state,
        data: {
          ...state.data,
          results: action.payload,
        },
      };
    case GET_EDIT_SINGLE_SALARY:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map(record =>
            record.id === action.payload.id ? action.payload : record
          ),
        },
      };
    case GET_SINGLE_SALARY_DETAILS:
      return {
        ...state,
        editSingleSalary: action.payload,
      };
    case CLEAR_SALARIES:
      return {
        ...state,
        data: initialState.data,
      };
    case CLEAR_SINGLE_SALARY:
      return {
        ...state,
        editSingleSalary: [],
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case GET_USER_GRATUITY:
      return {
        ...state,
        userGratuity: action.payload,
      };
    default:
      return state;
  }
};
