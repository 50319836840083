import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_TRASHS = 'GET_TRASHS';
export const GET_TRASH = 'GET_TRASH';
export const CLEAR_TRASH = 'CLEAR_TRASH';
export const DELETE_TRASH = 'DELETE_TRASH';
export const RESTORE_TRASH = 'RESTORE_TRASH';

// GET TRASH
export const getTrash = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/Trash/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TRASH,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR TRASH
export const clearTrash = () => {
  return {
    type: CLEAR_TRASH,
  };
};

// GET TRASHS
export const getTrashs = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/Trash/get_folders_files/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_TRASHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE TRASH
export const deleteTrash = item => async (dispatch, getState) => {
  const queryParams = { file_type: item.category };
  if (item.type === 'Folder') queryParams.folder_id = item.itemId;
  if (item.type === 'File') queryParams.file_id = item.itemId;
  try {
    await axios.delete(`${API_URL}/api/Trash/delete/`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch(createMessage({ message: 'Trash Deleted' }));
    dispatch({
      type: DELETE_TRASH,
      payload: item.id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// RESTORE TRASH
export const restoreTrash = item => async (dispatch, getState) => {
  const queryParams = { file_type: item.category };
  if (item.type === 'Folder') {
    queryParams.folder_id = item.itemId;
    queryParams.category = 'folder';
  }
  if (item.type === 'File') {
    queryParams.file_id = item.itemId;
    queryParams.category = 'file';
  }

  try {
    await axios.get(`${API_URL}/api/Trash/restore/`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch(createMessage({ message: 'Restored Successfully!' }));
    dispatch({
      type: RESTORE_TRASH,
      payload: item.id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
