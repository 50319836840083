import {
  GET_JOURNAL,
  CLEAR_JOURNAL,
  GET_JOURNALS,
  DELETE_JOURNAL,
  ADD_JOURNAL,
  EDIT_JOURNAL,
  GET_JOURNAL_DOCS,
  ADD_JOURNAL_DOCS,
  DELETE_JOURNAL_DOC,
  PUBLISH_JOURNAL,
  JOURNALS_LOADING,
  JOURNALS_LOADED,
  DELETE_JOURNAL_NOTE,
  EDIT_JOURNAL_NOTE,
} from '../actions/journalsActions';

const initialState = {
  count: null,
  journals: [],
  currentJournalDocs: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_JOURNAL:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_JOURNAL:
      return {
        ...state,
        fetched: null,
      };
    case GET_JOURNALS:
      return {
        ...state,
        journals: action.payload.results,
        count: action.payload.count,
      };
    case DELETE_JOURNAL:
      return {
        ...state,
        journals: state.journals.filter(
          journal => journal.id !== action.payload
        ),
        count: state.count - 1,
      };
    case ADD_JOURNAL:
      return {
        ...state,
        journals: [...state.journals, action.payload],
        count: state.count + 1,
      };
    case EDIT_JOURNAL:
      return {
        ...state,
      };
    case GET_JOURNAL_DOCS:
      return {
        ...state,
        currentJournalDocs: action.payload,
      };
    case ADD_JOURNAL_DOCS:
      return {
        ...state,
        currentJournalDocs: [...state.currentJournalDocs, action.payload],
      };
    case DELETE_JOURNAL_DOC:
      return {
        ...state,
        currentJournalDocs: state.currentJournalDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case PUBLISH_JOURNAL:
      return {
        ...state,
        fetched: action.payload,
      };
    case JOURNALS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case JOURNALS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_JOURNAL_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          journal_notes: state.fetched.journal_notes.filter(
            item => item.id !== action.payload
          ),
        },
      };
    case EDIT_JOURNAL_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          journal_notes: state.fetched.journal_notes.map(item =>
            item.id !== action.payload.id ? item : action.payload
          ),
        },
      };
    default:
      return state;
  }
};
