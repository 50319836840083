import { useState } from 'react';

function useToggle(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue);

  const toggle = newState =>
    setIsOpen(prevState =>
      typeof newState === 'boolean' ? newState : !prevState
    );

  return {
    isOpen,
    setIsOpen,
    toggle,
  };
}

export default useToggle;
