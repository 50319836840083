import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import RecentIcon from 'mdi-react/RecentIcon';
import UserIcon from 'mdi-react/UserIcon';
import FileIcon from 'mdi-react/FileIcon';
import FileDocumentBoxIcon from 'mdi-react/FileDocumentBoxIcon';
import { getRecentActivity } from '../../../redux/actions/usersActions';
import getLinkAddress from '../../../utils/getLinkAddress';
import ToolTip from '../../Common/ToolTip';

function RecentActivity() {
  const dispatch = useDispatch();
  const { recentActivity } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(getRecentActivity());
  }, []);

  const getActivityIcon = type => {
    switch (type) {
      case 'Invoice' || 'Invoice Payment' || 'Credit Note' || 'Payment Made':
        return <FileIcon size={28} color="#808080" />;
      case 'Account' || 'Customer' || 'Supplier':
        return <UserIcon size={28} color="#808080" />;
      default:
        return <FileDocumentBoxIcon size={28} color="#808080" />;
    }
  };

  return (
    <UncontrolledDropdown className="mr-0 recent-activity-dropdown">
      <ToolTip
        text="Recent Activity"
        position="bottom"
        className="mr-3 text-nowrap"
      >
        <DropdownToggle tag="div" className="nav-link p-0 m-0">
          <RecentIcon size={28} color="#BABBBC" />
        </DropdownToggle>
      </ToolTip>
      <DropdownMenu className="dropdown__menu recent-activity-menu">
        <div className="container px-0">
          {recentActivity.slice(0, 10).map(activity => (
            <div className="row m-0 px-0" key={activity.id}>
              <div className="col-12 m-0 p-0">
                <Link to={getLinkAddress(activity.type, activity.object_id)}>
                  <DropdownItem className="d-flex justify-content-between align-items-center">
                    <div className="icon d-flex custom-w-15">
                      {getActivityIcon(activity.type)}
                    </div>
                    <div className="data custom-w-85 pl-2">
                      <div className="header">{activity.title}</div>
                      <div className="description text-uppercase">
                        {activity.type}
                      </div>
                    </div>
                  </DropdownItem>
                </Link>
              </div>
            </div>
          ))}
        </div>
        {recentActivity.length === 0 && (
          <DropdownItem className="d-flex justify-content-center">
            <span>No activity found</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default RecentActivity;
