import {
  GET_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  CLEAR_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT,
} from '../actions/announcemntsActions';

const initialState = {
  count: null,
  announcements: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_ANNOUNCEMENT:
      return {
        ...state,
        fetched: null,
      };
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        // count: action.payload.count,
        announcements: action.payload,
      };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(
          profile => profile.id !== action.payload
        ),
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [...state.announcements, action.payload],
        // holidayDates: [...state.holidayDates, ...action.payload.holiday_dates],
      };
    case EDIT_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.map(activity =>
          activity.id === action.payload.id ? action.payload : activity
        ),
      };
    default:
      return state;
  }
};
