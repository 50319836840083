import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarNotification from './TopbarNotification';
import SearchBar from '../../Common/SearchBar';
import QuickCreateMenu from './QuickCreateMenu';
import RecentActivity from './RecentActivity';
import TopbarSettings from './TopbarSettings';
import { FINANCE_DEPT } from '../../../utils/constants';

function Topbar(props) {
  const { pathname } = useLocation();
  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
  const { department_name: department, system_access: allowSystemAccess } =
    useSelector(state => state.auth.user.profile);
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/" />
        </div>
        {allowSystemAccess && department === FINANCE_DEPT && (
          <>
            {!pathname.includes('/pages/reports/') && (
              <div className="topbar__middle">
                <div className="d-flex align-items-center">
                  <QuickCreateMenu />
                  <RecentActivity />
                </div>
                <SearchBar />
              </div>
            )}
          </>
        )}
        <div className="topbar__right">
          {/* <TopbarNotification /> */}
          {allowSystemAccess && (
            <div className="d-flex align-items-center justify-content-between">
              <TopbarSettings />
            </div>
          )}
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
}

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
