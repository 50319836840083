import {
  GET_INTERNAL_MEETINGS,
  CLEAR_INTERNAL_MEETINGS,
  ADD_INTERNAL_MEETING,
  DELETE_INTERNAL_MEETING,
  GET_INTERNAL_MEETING,
  CLEAR_INTERNAL_MEETING,
  EDIT_INTERNAL_MEETING,
} from '../actions/internalMeetingsActions';

const initialState = {
  meetings: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INTERNAL_MEETINGS:
      return {
        ...state,
        meetings: action.payload,
      };
    case GET_INTERNAL_MEETING:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_INTERNAL_MEETINGS:
      return {
        ...state,
        meetings: [],
      };
    case CLEAR_INTERNAL_MEETING:
      return {
        ...state,
        fetched: null,
      };
    case ADD_INTERNAL_MEETING:
      return {
        ...state,
        meetings: [...state.meetings, action.payload],
      };
    case DELETE_INTERNAL_MEETING:
      return {
        ...state,
        meetings: state.meetings.filter(
          meeting => meeting.id !== action.payload
        ),
      };
    case EDIT_INTERNAL_MEETING:
      return {
        ...state,
        meetings: state.meetings.map(meeting =>
          meeting.id === action.payload.id ? action.payload : meeting
        ),
      };
    default:
      return state;
  }
};
