import axios from 'axios';
import { round } from 'lodash/math';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_CHART_OF_ACCOUNTS_LIST = 'GET_CHART_OF_ACCOUNTS_LIST';
export const GET_CHART_OF_ACCOUNTS = 'GET_CHART_OF_ACCOUNTS';
export const GET_CHART_OF_ACCOUNT = 'GET_CHART_OF_ACCOUNT';
export const CLEAR_CHART_OF_ACCOUNT = 'CLEAR_CHART_OF_ACCOUNT';
export const ADD_CHART_OF_ACCOUNT = 'ADD_CHART_OF_ACCOUNT';
export const DELETE_CHART_OF_ACCOUNT = 'DELETE_CHART_OF_ACCOUNT';
export const EDIT_CHART_OF_ACCOUNT = 'EDIT_CHART_OF_ACCOUNT';
export const GET_CHART_OF_ACCOUNTS_DEPOSIT = 'GET_CHART_OF_ACCOUNTS_DEPOSIT';
export const GET_CHART_OF_ACCOUNTS_EXPENSE = 'GET_CHART_OF_ACCOUNTS_EXPENSE';
export const GET_ACCOUNT_TYPES = 'GET_ACCOUNT_TYPES';
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE';
export const GET_CHART_OF_ACCOUNT_DETAILS = 'GET_CHART_OF_ACCOUNT_DETAILS';
export const CLEAR_CHART_OF_ACCOUNT_DETAIL = 'CLEAR_CHART_OF_ACCOUNT_DETAIL';
export const GET_ITEM_SALE_CHART_OF_ACCOUNTS =
  'GET_ITEM_SALE_CHART_OF_ACCOUNTS';
export const GET_ITEM_COST_CHART_OF_ACCOUNTS =
  'GET_ITEM_COST_CHART_OF_ACCOUNTS';
export const GET_CHART_OF_ACCOUNTS_CASH = 'GET_CHART_OF_ACCOUNTS_CASH';
export const GET_CHART_OF_ACCOUNTS_PURCHASE = 'GET_CHART_OF_ACCOUNTS_PURCHASE';
export const GET_CHART_OF_ACCOUNTS_PAYMENT_MADE =
  'GET_CHART_OF_ACCOUNTS_PAYMENT_MADE';
export const GET_CHART_OF_ACCOUNTS_STOCK = 'GET_CHART_OF_ACCOUNTS_STOCK';
export const CHART_OF_ACCOUNT_LOADING = 'CHART_OF_ACCOUNT_LOADING';
export const CHART_OF_ACCOUNT_LOADED = 'CHART_OF_ACCOUNT_LOADED';
export const UPDATE_COA_OPENING_BALANCE = 'UPDATE_COA_OPENING_BALANCE';

// GET CHART OF ACCOUNT
export const getChartOfAccount = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CHART_OF_ACCOUNT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR CHART OF ACCOUNT
export const clearChartOfAccount = () => {
  return { type: CLEAR_CHART_OF_ACCOUNT };
};

// GET CHART OF ACCOUNTS
export const getChartOfAccounts =
  selectedFilter => async (dispatch, getState) => {
    const queryParams = {};

    if (selectedFilter) {
      if (selectedFilter === 'True' || selectedFilter === 'False') {
        queryParams.is_active = selectedFilter;
      } else {
        queryParams.account_type = selectedFilter;
      }
    }

    try {
      dispatch({ type: CHART_OF_ACCOUNT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/accounting/accountant/chartOfAccounts/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_CHART_OF_ACCOUNTS, payload: res.data.results });
      dispatch({ type: CHART_OF_ACCOUNT_LOADED });
    } catch (err) {
      dispatch({ type: CHART_OF_ACCOUNT_LOADED });
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET CHART OF ACCOUNTS LIST
export const getChartOfAccountsList =
  selectedFilter => async (dispatch, getState) => {
    const queryParams = {};

    if (selectedFilter) {
      if (selectedFilter === 'True' || selectedFilter === 'False') {
        queryParams.is_active = selectedFilter;
      } else {
        queryParams.account_type = selectedFilter;
      }
    }

    try {
      dispatch({ type: CHART_OF_ACCOUNT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/accounting/accountant/list/chartOfAccounts`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_CHART_OF_ACCOUNTS_LIST, payload: res.data.results });
      dispatch({ type: CHART_OF_ACCOUNT_LOADED });
    } catch (err) {
      dispatch({ type: CHART_OF_ACCOUNT_LOADED });
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET CHART OF ACCOUNTS DEPOSIT
export const getChartOfAccountsDeposit = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/deposit`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS_DEPOSIT,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CHART OF ACCOUNTS EXPENSE
export const getChartOfAccountsExpense = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/expense`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS_EXPENSE,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACTIVE CHART OF ACCOUNTS
export const getActiveChartOfAccounts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CHART OF ACCOUNT
export const deleteChartOfAccount = coa => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/${coa.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'chart_of_account',
          created_at: moment(coa.created_at).format('YYYY-MM-DD'),
        },
      }
    );
    dispatch(createMessage({ message: 'Chart Of Account Deleted' }));
    dispatch({ type: DELETE_CHART_OF_ACCOUNT, payload: coa.id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD CHART OF ACCOUNT
export const addChartOfAccount = values => async (dispatch, getState) => {
  const chartOfAccount = {
    // account_code: values.account_code,
    account_name: values.account_name,
    account_type: values.account_type,
    description: values.description,
    is_watchlisted: values.is_watchlisted,
    type: 'chart_of_account',
    created_at: new Date(),
  };

  if (values.account_type === 'bank') {
    // chartOfAccount.bank_account_type = values.bank_account_type;
    chartOfAccount.account_number = values.account_number;
    chartOfAccount.IBAN = values.IBAN;
    chartOfAccount.bank_name = values.bank_name;
    chartOfAccount.bank_branch_address = values.bank_branch_address;
    chartOfAccount.swift_code = values.swift_code;
    chartOfAccount.currency = values.currency;
  }

  if (values.parent_account_id) {
    chartOfAccount.parent_account_id = values.parent_account_id;
  }

  if (values.account_number) {
    chartOfAccount.account_number = values.account_number;
  }

  if (values.opening_balance) {
    chartOfAccount.opening_balance = round(values.opening_balance, 2);
  }

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/`,
      chartOfAccount,
      tokenConfig(getState)
    );
    dispatch({ type: ADD_CHART_OF_ACCOUNT, payload: res.data });
    dispatch(createMessage({ message: 'Chart Of Account Added' }));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT CHART OF ACCOUNT
export const editChartOfAccount = values => async (dispatch, getState) => {
  const chartOfAccount = {
    // account_code: values.account_code,
    account_name: values.account_name,
    account_type: values.account_type,
    description: values.description,
    is_watchlisted: values.is_watchlisted,
    is_active: values.is_active,
    type: 'chart_of_account',
    created_at: values.created_at,
  };

  if (values.account_type === 'bank') {
    // chartOfAccount.bank_account_type = values.bank_account_type;
    chartOfAccount.account_number = values.account_number;
    chartOfAccount.currency = values.currency;
    chartOfAccount.IBAN = values.IBAN;
    chartOfAccount.bank_name = values.bank_name;
    chartOfAccount.bank_branch_address = values.bank_branch_address;
    chartOfAccount.swift_code = values.swift_code;
  }

  if (values.parent_account_id) {
    chartOfAccount.parent_account_id = values.parent_account_id;
  }

  if (values.account_number) {
    chartOfAccount.account_number = values.account_number;
  }

  if (values.opening_balance) {
    chartOfAccount.opening_balance = round(values.opening_balance, 2);
  }

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/${values.id}/`,
      chartOfAccount,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Chart Of Account Updated' }));
    dispatch({ type: EDIT_CHART_OF_ACCOUNT, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET ACCOUNT TYPES
export const getAccountTypes = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/accountTypes/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_TYPES, payload: res.data.results });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET EXPENSE ACCOUNT TYPES
export const getExpenseAccountTypes = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/accountTypes/expense`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_TYPES, payload: res.data.results });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACCOUNT TYPE
export const getAccountType = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/accountTypes/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_ACCOUNT_TYPE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CHART OF ACCOUNT DETAILS
export const getChartOfAccountDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accountant/chart/of/account/${id}/detail`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CHART_OF_ACCOUNT_DETAILS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearChartOfAccountDetail = () => ({
  type: CLEAR_CHART_OF_ACCOUNT_DETAIL,
});

// GET ITEM SALE CHART OF ACCOUNTS
export const getItemSaleChartOfAccounts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/itemSale`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ITEM_SALE_CHART_OF_ACCOUNTS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ITEM COST CHART OF ACCOUNTS
export const getItemCostChartOfAccounts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/itemCost`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ITEM_COST_CHART_OF_ACCOUNTS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CASH CHART OF ACCOUNTS
export const getChartOfAccountCash = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/cash`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS_CASH,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PURCHASE CHART OF ACCOUNTS
export const getChartOfAccountsPurchase = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/purchase`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS_PURCHASE,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CHART OF ACCOUNTS PAYMENT MADE
export const getChartOfAccountsPaymentMade =
  () => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/accountant/chartOfAccounts/payment/made`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_CHART_OF_ACCOUNTS_PAYMENT_MADE,
        payload: res.data.results,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET CHART OF ACCOUNTS STOCK
export const getChartOfAccountsStock = () => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/stock`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CHART_OF_ACCOUNTS_STOCK,
      payload: data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPDATE CHART OF ACCOUNTS OPENING BALANCE
export const updateCoaOpeningBalance = values => async (dispatch, getState) => {
  const data = {
    chart_of_accounts: values.chartOfAccounts.map(item => ({
      id: item.id,
      is_credit: item.is_credit,
      opening_balance: item.opening_balance,
      migration_date: values.migrationDate,
    })),
  };

  try {
    await axios.patch(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/openingBalance`,
      data,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Opening Balance Updated' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CHART OF ACCOUNTS OPENING BALANCE
export const deleteCoaOpeningBalance = values => async (dispatch, getState) => {
  const data = {
    chart_of_accounts: values.chartOfAccounts.map(item => ({
      id: item.id,
      is_credit: item.is_credit,
      opening_balance: item.opening_balance,
    })),
  };

  try {
    await axios.patch(
      `${API_URL}/api/accounting/accountant/chartOfAccounts/openingBalance`,
      data,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Opening Balance Deleted' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
