import {
  GET_SALES_ACCOUNTS_LIST,
  GET_SALES_ACCOUNT,
  GET_SEARCHED_SALES_ACCOUNTS,
  CLEAR_SEARCHED_SALES_ACCOUNTS,
  CLEAR_SALES_ACCOUNT,
  GET_SALES_ACCOUNTS,
  DELETE_SALES_ACCOUNT,
  ADD_SALES_ACCOUNT,
  EDIT_SALES_ACCOUNT,
  GET_SALES_ACCOUNTS_BY_COMPANY,
  GET_UNPAID_INVOICES,
  CLEAR_INVOICES,
  GET_NON_DRAFT_INVOICES,
  GET_ACCOUNT_INCOME,
  GET_ACCOUNT_TRANSACTIONS,
  GET_ACCOUNT_COMMENTS,
  ADD_ACCOUNT_COMMENT,
  DELETE_ACCOUNT_COMMENT,
  CLEAR_ACTIVITY_LOG,
  GET_ACTIVITY_LOGS,
  GET_ACCOUNT_STATEMENT,
  SALES_ACCOUNT_LOADING,
  SALES_ACCOUNT_LOADED,
  CHECK_ACCOUNT_CREDIT_LIMIT,
  GET_ACCOUNT_CREDIT_DETAILS,
  ADD_ACCOUNT_DOC,
  GET_ACCOUNT_DOCS,
  DELETE_ACCOUNT_DOC,
  GET_ACCOUNT_EMAILS,
} from '../actions/salesAccountsActions';

const initialState = {
  count: null,
  salesAccountsList: [],
  salesAccounts: [],
  searchedSalesAccounts: [],
  invoices: [],
  fetched: null,
  income: null,
  transactions: [],
  comments: [],
  activityLogs: [],
  statement: [],
  creditDetails: [],
  docs: [],
  emails: [],
  isLoading: false,
  isLimitExceeded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_ACCOUNT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_SALES_ACCOUNT:
      return {
        ...state,
        count: null,
        invoices: [],
        fetched: null,
        income: null,
        transactions: [],
        comments: [],
        activityLogs: [],
        statement: [],
        creditDetails: [],
        docs: [],
        emails: [],
      };
    case GET_SALES_ACCOUNTS_LIST:
      return {
        ...state,
        count: action.payload.count,
        salesAccountsList: action.payload.results,
      };
    case GET_SALES_ACCOUNTS:
      return {
        ...state,
        count: action.payload.count,
        salesAccounts: action.payload.results,
      };

    case GET_SEARCHED_SALES_ACCOUNTS:
      return {
        ...state,
        searchedSalesAccounts: action.payload,
      };

    case CLEAR_SEARCHED_SALES_ACCOUNTS:
      return {
        ...state,
        searchedSalesAccounts: [],
      };
    case GET_SALES_ACCOUNTS_BY_COMPANY:
      return {
        ...state,
        salesAccounts: action.payload,
      };
    case DELETE_SALES_ACCOUNT:
      return {
        ...state,
        salesAccountsList: state.salesAccountsList.filter(
          salesAccount => salesAccount.id !== action.payload
        ),
      };
    case ADD_SALES_ACCOUNT:
      return {
        ...state,
        salesAccountsList: [...state.salesAccountsList],
      };
    case EDIT_SALES_ACCOUNT:
      return {
        ...state,
      };
    case GET_UNPAID_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: [],
      };
    case GET_NON_DRAFT_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case GET_ACCOUNT_INCOME:
      return {
        ...state,
        income: action.payload,
      };
    case GET_ACCOUNT_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_ACCOUNT_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case ADD_ACCOUNT_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    case DELETE_ACCOUNT_COMMENT:
      return {
        ...state,
        comments: state.comments.filter(
          comment => comment.id !== action.payload
        ),
      };
    case CLEAR_ACTIVITY_LOG:
      return {
        ...state,
        activityLogs: [],
      };
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogs: action.payload,
      };
    case GET_ACCOUNT_STATEMENT:
      return {
        ...state,
        statement: action.payload,
      };
    case SALES_ACCOUNT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SALES_ACCOUNT_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ACCOUNT_CREDIT_DETAILS:
      return {
        ...state,
        creditDetails: action.payload,
      };
    case CHECK_ACCOUNT_CREDIT_LIMIT:
      return {
        ...state,
        isLimitExceeded: action.payload,
      };
    case GET_ACCOUNT_DOCS:
      return {
        ...state,
        docs: action.payload,
      };
    case ADD_ACCOUNT_DOC:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      };
    case DELETE_ACCOUNT_DOC:
      return {
        ...state,
        docs: state.docs.filter(doc => doc.id !== action.payload),
      };
    case GET_ACCOUNT_EMAILS:
      return {
        ...state,
        emails: action.payload,
      };
    default:
      return state;
  }
};
