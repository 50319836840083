import { GET_DOCUMENTS } from '../actions/documentsActions';

const initialState = {
  documents: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    default:
      return state;
  }
};
