import {
  GET_CURRENCY,
  CLEAR_CURRENCY,
  GET_CURRENCIES,
  DELETE_CURRENCY,
  ADD_CURRENCY,
  EDIT_CURRENCY,
  DELETE_RATE_FROM_CURRENCY,
  CURRENCY_LOADING,
  CURRENCY_LOADED,
} from '../actions/currenciesActions';

const initialState = {
  currencies: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_CURRENCY:
      return {
        ...state,
        fetched: null,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    case DELETE_CURRENCY:
      return {
        ...state,
        currencies: state.currencies.filter(
          currency => currency.id !== action.payload
        ),
      };
    case ADD_CURRENCY:
      return {
        ...state,
        currencies: [...state.currencies, action.payload],
      };
    case EDIT_CURRENCY:
      return {
        ...state,
      };
    case DELETE_RATE_FROM_CURRENCY:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            exchange_rates: state.fetched.exchange_rates.filter(
              rate => rate.id !== action.payload
            ),
          },
        },
      };
    case CURRENCY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CURRENCY_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
