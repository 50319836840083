import {
  GET_SALES_CONTACT,
  ADD_SALES_CONTACT,
  CLEAR_SALES_CONTACT,
  GET_SALES_CONTACTS,
  DELETE_SALES_CONTACT,
  EDIT_SALES_CONTACT,
} from '../actions/salesContactsActions';

const initialState = {
  salesContacts: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_CONTACT:
      return {
        ...state,
        fetched: action.payload,
      };
    case ADD_SALES_CONTACT:
      return {
        ...state,
        salesContacts: [...state.salesContacts, action.payload],
      };
    case CLEAR_SALES_CONTACT:
      return {
        ...state,
        fetched: null,
      };
    case GET_SALES_CONTACTS:
      return {
        ...state,
        salesContacts: action.payload,
      };
    case DELETE_SALES_CONTACT:
      return {
        ...state,
        salesContacts: state.salesContacts.filter(
          salesContact => salesContact.id !== action.payload
        ),
      };
    case EDIT_SALES_CONTACT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
