import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  // ACCOUNTING_CONTROLLER,
  // ACCOUNTING_MANAGER,
  // ACCOUNTING_STAFF,
  // OPERATIONS_CONTROLLER,
  // OPERATIONS_MANAGER,
  // OPERATIONS_STAFF,
  SUPER_USER,
  // HR_MANAGER,
  // HR_CONTROLLER,
  // HR_STAFF,
  FINANCE_DEPT,
  HR_DEPT,
  SALES_DEPT,
  OPERTIONS_DEPT,
  OPERTIONS_VIP_DEPT,
  OPERTIONS_CORP_DEPT,
  OPERTIONS_MICE_DEPT,
  OPERTIONS_TICKETING_DEPT,
  // ACCOUNTING_STAFF_PAYABLE,
  // ACCOUNTING_STAFF_RECEIVABLE,
} from '../../../utils/constants';

function useAuth() {
  const { token, isAuthenticated, isLoading, user } = useSelector(
    state => state.auth
  );

  const role = useMemo(() => {
    const targetUserGroup = user ? user.profile.role_name : ''; // Targeting first group
    const userDepartment = user ? user.profile.department_name : ''; // User Department

    const isSuperUser = targetUserGroup === SUPER_USER;
    const isAccountingDeptUser = userDepartment === FINANCE_DEPT;
    // ||
    // [
    //   ACCOUNTING_CONTROLLER,
    //   ACCOUNTING_MANAGER,
    //   ACCOUNTING_STAFF_PAYABLE,
    //   ACCOUNTING_STAFF_RECEIVABLE,
    // ].includes(targetUserGroup);

    const isHRDeptUser = userDepartment === HR_DEPT;
    // ||
    // [HR_CONTROLLER, HR_MANAGER, HR_STAFF].includes(targetUserGroup);

    const isOperationsDeptUser = [
      OPERTIONS_DEPT,
      OPERTIONS_VIP_DEPT,
      OPERTIONS_CORP_DEPT,
      OPERTIONS_MICE_DEPT,
      OPERTIONS_TICKETING_DEPT,
    ].includes(userDepartment);
    // ||
    // [OPERATIONS_CONTROLLER, OPERATIONS_MANAGER, OPERATIONS_STAFF].includes(
    //   targetUserGroup
    // );

    const isSalesUser = userDepartment === SALES_DEPT;

    return {
      isSuperUser,
      isAccountingDeptUser,
      isHRDeptUser,
      isOperationsDeptUser,
      isSalesUser,
    };
  }, [user]);

  return {
    token,
    isAuthenticated,
    isLoading,
    user,
    role,
  };
}

export default useAuth;
