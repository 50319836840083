/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';
import { addSupplierActivityLog } from './suppliersActions';
import formatAmount from '../../utils/formatAmount';

export const GET_SUPPLIER_CREDITS_LIST = 'GET_SUPPLIER_CREDITS_LIST';
export const GET_SUPPLIER_CREDITS = 'GET_SUPPLIER_CREDITS';
export const GET_SUPPLIER_CREDIT = 'GET_SUPPLIER_CREDIT';
export const CLEAR_SUPPLIER_CREDIT = 'CLEAR_SUPPLIER_CREDIT';
export const ADD_SUPPLIER_CREDIT = 'ADD_SUPPLIER_CREDIT';
export const DELETE_SUPPLIER_CREDIT = 'DELETE_SUPPLIER_CREDIT';
export const EDIT_SUPPLIER_CREDIT = 'EDIT_SUPPLIER_CREDIT';
export const GET_SUPPLIER_CREDIT_DOCS = 'GET_SUPPLIER_CREDIT_DOCS';
export const ADD_SUPPLIER_CREDIT_DOCS = 'ADD_SUPPLIER_CREDIT_DOCS';
export const DELETE_SUPPLIER_CREDIT_DOC = 'DELETE_SUPPLIER_CREDIT_DOC';
export const MARK_SUPPLIER_CREDIT_OPEN = 'MARK_SUPPLIER_CREDIT_OPEN';
export const GET_SUPPLIER_CREDIT_JOURNAL = 'GET_SUPPLIER_CREDIT_JOURNAL';
export const CLEAR_SUPPLIER_CREDIT_JOURNAL = 'CLEAR_SUPPLIER_CREDIT_JOURNAL';
export const SUPPLIER_CREDITS_LOADING = 'SUPPLIER_CREDITS_LOADING';
export const SUPPLIER_CREDITS_LOADED = 'SUPPLIER_CREDITS_LOADED';

export const getLatestBillCreditNum = () => async (_, getState) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/billSupplierCredit/latest`,
    tokenConfig(getState)
  );
  return data;
};

// GET SUPPLIER CREDIT
export const getSupplierCredit = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/supplierCredits/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_CREDIT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR SUPPLIER CREDIT
export const clearSupplierCredit = () => {
  return { type: CLEAR_SUPPLIER_CREDIT };
};

// GET SUPPLIER CREDITS LIST
export const getSupplierCreditsList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPLIER_CREDITS_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/supplierCredits`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_SUPPLIER_CREDITS_LIST, payload: res.data });
    dispatch({ type: SUPPLIER_CREDITS_LOADED });
  } catch (err) {
    dispatch({ type: SUPPLIER_CREDITS_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIER CREDITS
export const getSupplierCredits = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/supplierCredits/`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_SUPPLIER_CREDITS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SUPPLIER CREDIT
export const deleteSupplierCredit =
  supplierCredit => async (dispatch, getState) => {
    const newActivityLog = {
      supplier_id: supplierCredit.supplier_id,
      activity_type: 'Debit Note',
      activity_title: 'Debit Note Deleted',
      module_num: supplierCredit.supplier_credit_num,
      amount: round(supplierCredit.without_change_grand_total, 2),
      description: `Debit Note ${
        supplierCredit.supplier_credit_formatted_number
      } of amount ${supplierCredit.currency_symbol}${formatAmount(
        supplierCredit.without_change_grand_total
      )} deleted`,
    };

    try {
      await axios.delete(
        `${API_URL}/api/accounting/purchases/supplierCredits/${supplierCredit.id}/`,
        {
          ...tokenConfig(getState),
          data: {
            type: 'supplier_credit',
            supplier_credit_date: supplierCredit.supplier_credit_date,
          },
        }
      );
      dispatch(createMessage({ message: 'Debit Note Deleted' }));
      dispatch({ type: DELETE_SUPPLIER_CREDIT, payload: supplierCredit.id });
      dispatch(addSupplierActivityLog(newActivityLog));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// ADD SUPPLIER CREDIT
export const addSupplierCredit = values => async (dispatch, getState) => {
  const modifiedSupplierCreditItems = [];
  const supplierCreditItems = values.supplier_credit_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    chart_of_account_id: item.chart_of_account_id,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
  }));
  Object.values(
    groupArrayOfObjects(supplierCreditItems, 'service_type')
  ).forEach(item => {
    item.forEach((subItem, index) => {
      const itemCounter = index + 1;
      modifiedSupplierCreditItems.push({
        ...subItem,
        service_type_name: subItem.service_type + itemCounter,
      });
    });
  });

  const newSupplierCredit = {
    supplier_id: values.supplier_id,
    bill_id: values.bill_id,
    currency: values.currency,
    // supplier_credit_num: values.supplier_credit_num,
    exchange_rate_of_suppliercredit_currency: round(values.exchange_rate, 6),
    supplier_credit_date:
      values.supplier_credit_date && values.supplier_credit_date !== ''
        ? moment(values.supplier_credit_date).format('YYYY-MM-DD')
        : null,
    order_num: values.order_num,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    is_tax_inclusive: true,
    supplier_notes: values.supplier_notes,
    supplier_credit_items: modifiedSupplierCreditItems,
    credits_remaining: values.grand_total,
    type: 'supplier_credit',
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    signature: values.signature,
  };

  if (values.status) {
    newSupplierCredit.status = values.status;
  }

  if (values.parent) newSupplierCredit.parent = values.parent;

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/supplierCredits/`,
      newSupplierCredit,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: res.data.id,
      supplier_id: values.supplier_id,
      activity_type: 'Debit Note',
      activity_title: 'Debit Note Added',
      module_num: values.supplier_credit_num,
      amount: round(values.grand_total, 2),
      description: `Debit Note of amount ${
        values.currency_symbol
      }${formatAmount(values.grand_total)} created`,
    };
    dispatch(createMessage({ message: 'Debit Note Added' }));
    dispatch({ type: ADD_SUPPLIER_CREDIT, payload: res.data });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT SUPPLIER CREDIT
export const editSupplierCredit = values => async (dispatch, getState) => {
  try {
    const modifiedSupplierCreditItems = [];
    const latestNum = await dispatch(getLatestBillCreditNum());
    let lastNum = latestNum.latest_num || 0;

    const supplierCreditItems = values.supplier_credit_items.map(item => ({
      service_type: item.service_type,
      description: item.description,
      currency: item.currency,
      chart_of_account_id: item.chart_of_account_id,
      unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
      num_units: item.num_units,
      num_nights: item.num_nights,
      amount_ex_vat: round(item.amount_ex_vat, 2),
      vat_rate: round(item.vat_rate, 2),
      vat_amount: round(item.vat_amount, 2),
      gross_amount: round(item.gross_amount, 2),
    }));

    Object.values(
      groupArrayOfObjects(supplierCreditItems, 'service_type')
    ).forEach(item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedSupplierCreditItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    });

    const billSupplierCredits = values.bill_supplier_credits.map(item => ({
      ...item,
      transaction_num: ++lastNum,
    }));

    const editedSupplierCredit = {
      status: values.status,
      supplier_id: values.supplier_id,
      bill_id: values.bill_id,
      currency: values.currency,
      // supplier_credit_num: values.supplier_credit_num,
      exchange_rate_of_suppliercredit_currency: round(values.exchange_rate, 6),
      supplier_credit_date:
        values.supplier_credit_date && values.supplier_credit_date !== ''
          ? moment(values.supplier_credit_date).format('YYYY-MM-DD')
          : null,
      order_num: values.order_num,
      without_change_amount_total: round(values.amount_total, 2),
      without_change_vat_total: round(values.vat_total, 2),
      without_change_grand_total: round(values.grand_total, 2),
      is_tax_inclusive: true,
      supplier_notes: values.supplier_notes,
      bill_supplier_credits: billSupplierCredits,
      supplier_credit_items: modifiedSupplierCreditItems,
      type: 'supplier_credit',
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
      exchange_rate: 1,
      grand_total: 1,
    };

    const newActivityLog = {
      module_id: values.id,
      supplier_id: values.supplier_id,
      activity_type: 'Debit Note',
      activity_title: 'Debit Note Updated',
      module_num: values.supplier_credit_num,
      amount: values.grand_total,
      description: `Debit Note "${values.supplier_credit_prefix}-${values.supplier_credit_num}" updated`,
    };

    await axios.put(
      `${API_URL}/api/accounting/purchases/supplierCredits/${values.id}/`,
      editedSupplierCredit,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Debit Note Updated' }));
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET LATEST SUPPLIER CREDIT
export const getLatestSupplierCredit = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/supplierCredits/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET SUPPLIER CREDIT DOCS
export const getSupplierCreditDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/supplierCredit/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SUPPLIER_CREDIT_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD SUPPLIER CREDIT FILE
export const uploadSupplierCreditFile =
  values => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append(
      'doc_file',
      values.doc_file.file,
      values.doc_file.file.name
    );
    formData.append('doc_type', values.doc_file.file.type);
    formData.append('doc_name', values.doc_file.file.name);
    formData.append('doc_size_bytes', values.doc_file.file.size);

    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';
    try {
      const res = await axios.post(
        `${API_URL}/api/accounting/purchases/supplierCredit/${values.id}/uploadDoc`,
        formData,
        config
      );
      dispatch(createMessage({ message: 'File Uploaded' }));
      dispatch({ type: ADD_SUPPLIER_CREDIT_DOCS, payload: res.data });
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch(returnErrors('An unexpected error occured', 500));
    }
  };

// DELETE SUPPLIER CREDIT DOC
export const deleteSupplierCreditDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/supplierCredit/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Debit Note Doc Deleted' }));
    dispatch({ type: DELETE_SUPPLIER_CREDIT_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// MARK SUPPLIER CREDIT AS OPEN
export const markSupplierCreditAsOpen =
  supplierCredit => async (dispatch, getState) => {
    const newActivityLog = {
      module_id: supplierCredit.id,
      supplier_id: supplierCredit.supplier_id,
      activity_type: 'Debit Note',
      activity_title: 'Debit Note Updated',
      module_num: supplierCredit.supplier_credit_num,
      amount: round(supplierCredit.grand_total, 2),
      description: `Debit Note "${supplierCredit.supplier_credit_prefix}-${supplierCredit.supplier_credit_num}" status changed from draft to open`,
    };

    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/purchases/supplierCredits/${supplierCredit.id}/open`,
        tokenConfig(getState)
      );
      dispatch({
        type: MARK_SUPPLIER_CREDIT_OPEN,
        payload: res.data,
      });
      dispatch(addSupplierActivityLog(newActivityLog));
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch(returnErrors('An unexpected error occured', 500));
    }
  };

// REFUND SUPPLIER CREDIT
export const refundSupplierCredit = values => async (dispatch, getState) => {
  const latestNum = await dispatch(getLatestBillCreditNum());
  const lastNum = latestNum.latest_num || 0;
  const refundData = {
    supplier_credit_id: values.supplier_credit_id,
    credit_note_id: values.supplier_credit_id,
    bill_credit_notes: [
      {
        transaction_num: lastNum + 1,
        payment_mode: values.payment_mode,
        amount_applied: values.amount_applied,
        from_account_id: values.from_account_id,
        reference_num: values.reference_num,
        refunded_on: values.refunded_on
          ? moment(values.refunded_on).format('YYYY-MM-DD')
          : '',
        description: values.description,
      },
    ],
  };

  const newActivityLog = {
    module_id: values.supplier_credit_id,
    supplier_id: values.supplier_id,
    activity_type: 'Debit Note',
    activity_title: 'Debit Note Refunded',
    module_num: values.supplier_credit_num,
    amount: values.amount_applied,
    description: `Refund added for the Debit Note "${values.supplier_credit_num_with_prefix}"`,
  };

  try {
    await axios.post(
      `${API_URL}/api/accounting/sales/suppliercredit/amountapply`,
      refundData,
      tokenConfig(getState)
    );
    dispatch(getSupplierCredit(values.supplier_credit_id));
    dispatch(addSupplierActivityLog(newActivityLog));
    dispatch(createMessage({ message: 'Debit Note Refunded' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};

// APPLY SUPPLIER CREDIT TO INVOICE
export const applySupplierCredit = values => async (dispatch, getState) => {
  const latestNum = await dispatch(getLatestBillCreditNum());
  let lastNum = latestNum.latest_num || 0;

  const billSupplierCredits = values.unpaid_bills
    .filter(item => item.amount_applied > 0)
    .map(bill => {
      if (bill.bill_num === 'Supplier Opening Balance') {
        return {
          supplier_account_id: bill.id,
          amount_applied: bill.amount_applied,
          transaction_num: ++lastNum,
        };
      }
      return {
        bill_id: bill.id,
        amount_applied: bill.amount_applied,
        transaction_num: ++lastNum,
      };
    });

  const data = {
    supplier_credit_id: values.id,
    credit_note_id: values.id,
    bill_credit_notes: billSupplierCredits,
  };

  const billNums = values.unpaid_bills
    .filter(bill => bill.amount_applied > 0)
    .reduce((acc, val) => `${acc}${val.bill_num}, `, '');

  const newActivityLog = {
    module_id: values.id,
    supplier_id: values.supplier_id,
    activity_type: 'Debit Note',
    activity_title: 'Debit Note Updated',
    module_num: values.supplier_credit_num,
    amount: values.grand_total,
    description: `Debit Note "${values.supplier_credit_prefix}-${values.supplier_credit_num}" applied to ${billNums}`,
  };

  try {
    await axios.post(
      `${API_URL}/api/accounting/sales/suppliercredit/amountapply`,
      data,
      tokenConfig(getState)
    );
    dispatch(getSupplierCredit(values.id));
    dispatch(addSupplierActivityLog(newActivityLog));
    dispatch(createMessage({ message: 'Credits Applied Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};

// GET SUPPLIER CREDIT JOURNAL
export const getSupplierCreditJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/supplier/credit/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_CREDIT_JOURNAL, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearSupplierCreditJournal = () => ({
  type: CLEAR_SUPPLIER_CREDIT_JOURNAL,
});
