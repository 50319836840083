import {
  GET_CREDIT_NOTE,
  CLEAR_CREDIT_NOTE,
  GET_CREDIT_NOTES,
  DELETE_CREDIT_NOTE,
  ADD_CREDIT_NOTE,
  EDIT_CREDIT_NOTE,
  GET_CREDIT_NOTE_DOCS,
  ADD_CREDIT_NOTE_DOCS,
  DELETE_CREDIT_NOTE_DOC,
  MARK_OPEN,
  GET_CREDIT_NOTE_JOURNALS,
  CLEAR_CREDIT_NOTE_JOURNALS,
  CREDIT_NOTES_LOADING,
  CREDIT_NOTES_LOADED,
  EDIT_CREDIT_NOTE_NOTES,
  DELETE_CREDIT_NOTE_NOTE,
  GET_CREDIT_NOTES_LIST,
} from '../actions/creditNotesActions';

const initialState = {
  count: null,
  creditNotesList: [],
  creditNotes: [],
  currentCreditNoteDocs: [],
  fetched: null,
  journal: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CREDIT_NOTE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_CREDIT_NOTE:
      return {
        ...state,
        fetched: null,
      };
    case GET_CREDIT_NOTES_LIST:
      return {
        ...state,
        count: action.payload.count,
        creditNotesList: action.payload.results,
      };
    case GET_CREDIT_NOTES:
      return {
        ...state,
        count: action.payload.count,
        creditNotes: action.payload.results,
      };
    case DELETE_CREDIT_NOTE:
      return {
        ...state,
        creditNotesList: state.creditNotesList.filter(
          creditNote => creditNote.id !== action.payload
        ),
      };
    case ADD_CREDIT_NOTE:
      return {
        ...state,
        creditNotesList: [...state.creditNotesList],
      };
    case EDIT_CREDIT_NOTE:
      return {
        ...state,
        // creditNotes: [...state.creditNotes, action.payload],
      };
    case GET_CREDIT_NOTE_DOCS:
      return {
        ...state,
        currentCreditNoteDocs: action.payload,
      };
    case ADD_CREDIT_NOTE_DOCS:
      return {
        ...state,
        currentCreditNoteDocs: [...state.currentCreditNoteDocs, action.payload],
      };
    case DELETE_CREDIT_NOTE_DOC:
      return {
        ...state,
        currentCreditNoteDocs: state.currentCreditNoteDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case MARK_OPEN:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_CREDIT_NOTE_JOURNALS:
      return {
        ...state,
        journal: action.payload,
      };
    case CLEAR_CREDIT_NOTE_JOURNALS:
      return {
        ...state,
        journal: null,
      };
    case CREDIT_NOTES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CREDIT_NOTES_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_CREDIT_NOTE_NOTES:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            credit_note_records: state.fetched.credit_note_records.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_CREDIT_NOTE_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            credit_note_records: state.fetched.credit_note_records.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    default:
      return state;
  }
};
