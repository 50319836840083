import {
  GET_PAYMENT_MODES,
  CHANGE_DEFAULT_MODE,
  MODIFY_PAYMENT_MODES,
} from '../actions/paymentModesActions';

const initialState = {
  paymentModes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: action.payload,
      };
    case CHANGE_DEFAULT_MODE:
      return {
        ...state,
        paymentModes: action.payload,
      };
    case MODIFY_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: action.payload,
      };
    default:
      return state;
  }
};
