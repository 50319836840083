import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import formatString from '../../utils/formatString';

export const GET_SALES_PERSONS = 'GET_SALES_PERSONS';
export const GET_ACTIVE_SALES_PERSONS = 'GET_ACTIVE_SALES_PERSONS';
export const GET_SALES_PERSON = 'GET_SALES_PERSON';
export const CLEAR_SALES_PERSON = 'CLEAR_SALES_PERSON';
export const ADD_SALES_PERSON = 'ADD_SALES_PERSON';
export const DELETE_SALES_PERSON = 'DELETE_SALES_PERSON';
export const EDIT_SALES_PERSON = 'EDIT_SALES_PERSON';

// GET SALES PERSON
export const getSalesPerson = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/salesPersons/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SALES_PERSON,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR SALES PERSON
export const clearSalesPersons = () => {
  return {
    type: CLEAR_SALES_PERSON,
  };
};

// GET ACTIVE SALES PERSONS
export const getActiveSalesPersons = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/salesPersons/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_ACTIVE_SALES_PERSONS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SALES PERSONS
export const getSalesPersons = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/salesPersons/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SALES_PERSONS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SALES PERSON
export const deleteSalesPerson = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/salesPersons/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Sales Person Deleted' }));
    dispatch({
      type: DELETE_SALES_PERSON,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD SALES PERSON
export const addSalesPerson = values => async (dispatch, getState) => {
  const newSalesPerson = {
    // Sales Person name must be capitalized as per requirement
    sales_person_name: formatString(values.name),
    email: values.email,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/salesPersons/`,
      newSalesPerson,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Sales Person Added' }));
    dispatch({
      type: ADD_SALES_PERSON,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// EDIT SALES PERSON
export const editSalesPerson = values => async (dispatch, getState) => {
  const editedSalesPerson = {
    sales_person_name: values.name,
    email: values.email,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/salesPersons/${values.id}/`,
      editedSalesPerson,
      tokenConfig(getState)
    );
    dispatch(getSalesPersons());
    dispatch(createMessage({ message: 'Sales Person Updated' }));
    dispatch({
      type: EDIT_SALES_PERSON,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
