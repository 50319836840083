import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { returnErrors, createMessage } from './messagesActions';

export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const ADD_PAYMENT_TERM = 'ADD_PAYMENT_TERM';
export const MODIFY_PAYMENT_TERMS = 'MODIFY_PAYMENT_TERMS';
export const CHANGE_DEFAULT_TERM = 'CHANGE_DEFAULT_TERM';

// Get Payment Terms
export const getPaymentTerms = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/paymentTerms/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PAYMENT_TERMS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Add Payment Terms
export const addPaymentTerm = values => async (dispatch, getState) => {
  const paymentTerm = {
    term_name: values.term_name,
    no_of_days: values.no_of_days,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/paymentTerms/`,
      paymentTerm,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Term Added' }));
    dispatch({
      type: ADD_PAYMENT_TERM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Configure Payment Terms
export const configurePaymentTerms = values => async (dispatch, getState) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/paymentTerms/all`,
      values,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Terms Modified' }));
    dispatch({
      type: MODIFY_PAYMENT_TERMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Change Default Term
export const changeDefaultTerm = modifiedPaymentTerms => ({
  type: CHANGE_DEFAULT_TERM,
  payload: modifiedPaymentTerms,
});
