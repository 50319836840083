import {
  GET_SUPPLIER,
  CLEAR_SUPPLIER,
  GET_SUPPLIERS,
  GET_SUPPLIERS_BY_SEARCH,
  CLEAR_SUPPLIERS_BY_SEARCH,
  GET_SUPPLIERS_OPTIONS_BY_SEARCH,
  DELETE_SUPPLIER,
  ADD_SUPPLIER,
  EDIT_SUPPLIER,
  GET_UNPAID_BILLS,
  CLEAR_BILLS,
  GET_SUPPLIER_INCOME,
  GET_SUPPLIER_TRANSACTIONS,
  GET_SUPPLIER_COMMENTS,
  ADD_SUPPLIER_COMMENT,
  DELETE_SUPPLIER_COMMENT,
  CLEAR_SUPPLIER_ACTIVITY_LOG,
  GET_SUPPLIER_ACTIVITY_LOGS,
  GET_SUPPLIER_STATEMENT,
  SUPPLIERS_LOADING,
  SUPPLIERS_LOADED,
  GET_SUPPLIER_CREDIT_DETAILS,
  CHECK_SUPPLIER_CREDIT_LIMIT,
  ADD_SUPPLIER_DOC,
  GET_SUPPLIER_DOCS,
  DELETE_SUPPLIER_DOC,
  GET_SUPPLIER_EMAILS,
  GET_SUPPLIERS_LIST,
} from '../actions/suppliersActions';

const initialState = {
  count: null,
  suppliersList: [],
  suppliers: [],
  searchedSuppliers: [],
  supplierOptions: [],
  bills: [],
  fetched: null,
  income: null,
  transactions: [],
  comments: [],
  activityLogs: [],
  statement: [],
  creditDetails: [],
  docs: [],
  emails: [],
  isLoading: false,
  isLimitExceeded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIER:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_SUPPLIER:
      return {
        ...state,
        count: null,
        bills: [],
        fetched: null,
        income: null,
        transactions: [],
        comments: [],
        activityLogs: [],
        statement: [],
        creditDetails: [],
        docs: [],
        emails: [],
      };
    case GET_SUPPLIERS_LIST:
      return {
        ...state,
        count: action.payload.count,
        suppliersList: action.payload.results,
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        count: action.payload.count,
        suppliers: action.payload.results,
      };

    case GET_SUPPLIERS_BY_SEARCH:
      return {
        ...state,
        searchedSuppliers: action.payload,
      };
    case CLEAR_SUPPLIERS_BY_SEARCH:
      return {
        ...state,
        searchedSuppliers: [],
      };
    case GET_SUPPLIERS_OPTIONS_BY_SEARCH:
      return {
        ...state,
        supplierOptions: action.payload,
      };

    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliersList: state.suppliersList.filter(
          supplier => supplier.id !== action.payload
        ),
      };
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliersList: [...state.suppliersList],
      };
    case EDIT_SUPPLIER:
      return {
        ...state,
      };
    case GET_UNPAID_BILLS:
      return {
        ...state,
        bills: action.payload,
      };
    case CLEAR_BILLS:
      return {
        ...state,
        bills: [],
      };
    case GET_SUPPLIER_INCOME:
      return {
        ...state,
        income: action.payload,
      };
    case GET_SUPPLIER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_SUPPLIER_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case ADD_SUPPLIER_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    case DELETE_SUPPLIER_COMMENT:
      return {
        ...state,
        comments: state.comments.filter(
          comment => comment.id !== action.payload
        ),
      };
    case CLEAR_SUPPLIER_ACTIVITY_LOG:
      return {
        ...state,
        activityLogs: [],
      };
    case GET_SUPPLIER_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogs: action.payload,
      };
    case GET_SUPPLIER_STATEMENT:
      return {
        ...state,
        statement: action.payload,
      };
    case SUPPLIERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SUPPLIERS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_SUPPLIER_CREDIT_DETAILS:
      return {
        ...state,
        creditDetails: action.payload,
      };
    case CHECK_SUPPLIER_CREDIT_LIMIT:
      return {
        ...state,
        isLimitExceeded: action.payload,
      };
    case GET_SUPPLIER_DOCS:
      return {
        ...state,
        docs: action.payload,
      };
    case ADD_SUPPLIER_DOC:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      };
    case DELETE_SUPPLIER_DOC:
      return {
        ...state,
        docs: state.docs.filter(doc => doc.id !== action.payload),
      };
    case GET_SUPPLIER_EMAILS:
      return {
        ...state,
        emails: action.payload,
      };
    default:
      return state;
  }
};
