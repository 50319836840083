import React from 'react';
import { useLocation } from 'react-router-dom';

function Unauthorized() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const message = query.get('message');
  return (
    <div
      style={{ height: '90vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="text-center">
        <h1 style={{ fontSize: 120 }}>403</h1>
        <h1 className="mt-5">{message}</h1>
      </div>
    </div>
  );
}

export default Unauthorized;
