import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_SALES_CONTACT = 'GET_SALES_CONTACT';
export const CLEAR_SALES_CONTACT = 'CLEAR_SALES_CONTACT';
export const GET_SALES_CONTACTS = 'GET_SALES_CONTACTS';
export const ADD_SALES_CONTACT = 'ADD_SALES_CONTACT';
export const EDIT_SALES_CONTACT = 'EDIT_SALES_CONTACT';
export const DELETE_SALES_CONTACT = 'DELETE_SALES_CONTACT';

// GET SALES CONTACT
export const getSalesContact = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/accounts/contacts/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SALES_CONTACT, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// CLEAR SALES CONTACT
export const clearSalesContact = () => {
  return {
    type: CLEAR_SALES_CONTACT,
  };
};

// GET SALES CONTACTS
export const getSalesContacts = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/accounts/contacts/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SALES_CONTACTS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SALES CONTACT
export const deleteSalesContact = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/accounts/contacts/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Sales Contact Deleted' }));
    dispatch({ type: DELETE_SALES_CONTACT, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD SALES CONTACT
export const addSalesContact = values => async (dispatch, getState) => {
  const salesContact = {
    sales_account_id: values.sales_account_id,
    primary_contact: values.primary_contact,
    title: values.title,
    first_name: values.first_name,
    last_name: values.last_name,
    mobile_num: values.mobile_num,
    phone_num: values.phone_num,
    email: values.email,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/accounts/contacts/`,
      salesContact,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Customer Contact Added' }));
    // Commented because it doesnot seems to be useful
    // dispatch({ type: CLEAR_SALES_ACCOUNT });
    dispatch({ type: ADD_SALES_CONTACT, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT SALES CONTACT
export const editSalesContact = values => async (dispatch, getState) => {
  const salesContact = {
    sales_account_id: values.sales_account_id,
    primary_contact: values.primary_contact,
    title: values.title,
    first_name: values.first_name,
    last_name: values.last_name,
    mobile_num: values.mobile_num,
    phone_num: values.phone_num,
    email: values.email,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/accounts/contacts/${values.id}/`,
      salesContact,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Customer Contact Updated' }));
    dispatch({ type: EDIT_SALES_CONTACT, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
