import {
  GET_PAYMENT_MADE,
  CLEAR_PAYMENT_MADE,
  GET_PAYMENTS_MADE,
  DELETE_PAYMENT_MADE,
  ADD_PAYMENT_MADE,
  EDIT_PAYMENT_MADE,
  GET_PAYMENT_MADE_DOCS,
  ADD_PAYMENT_MADE_DOCS,
  DELETE_PAYMENT_MADE_DOC,
  GET_PAYMENT_MADE_JOURNAL,
  CLEAR_PAYMENT_MADE_JOURNAL,
  PAYMENT_MADE_LOADING,
  PAYMENT_MADE_LOADED,
  REFUND_PAYAMENT_MADE,
  GET_PAYMENTS_MADE_LIST,
  GET_PAYMENT_MADE_REFUNDS,
} from '../actions/paymentsMadeActions';

const initialState = {
  count: null,
  paymentsMadeList: [],
  paymentsMade: [],
  currentPaymentMadeDocs: [],
  paymentMadeRefunds: [],
  fetched: null,
  journal: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_MADE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_PAYMENT_MADE:
      return {
        ...state,
        fetched: null,
      };
    case GET_PAYMENTS_MADE_LIST:
      return {
        ...state,
        count: action.payload.count,
        paymentsMadeList: action.payload.results,
      };
    case GET_PAYMENTS_MADE:
      return {
        ...state,
        count: action.payload.count,
        paymentsMade: action.payload.results,
      };
    case DELETE_PAYMENT_MADE:
      return {
        ...state,
        paymentsMadeList: state.paymentsMadeList.filter(
          paymentMade => paymentMade.id !== action.payload
        ),
      };
    case ADD_PAYMENT_MADE:
      return {
        ...state,
        paymentsMadeList: [...state.paymentsMadeList],
      };
    case EDIT_PAYMENT_MADE:
      return {
        ...state,
      };
    case GET_PAYMENT_MADE_DOCS:
      return {
        ...state,
        currentPaymentMadeDocs: action.payload,
      };
    case ADD_PAYMENT_MADE_DOCS:
      return {
        ...state,
        currentPaymentMadeDocs: [
          ...state.currentPaymentMadeDocs,
          action.payload,
        ],
      };
    case DELETE_PAYMENT_MADE_DOC:
      return {
        ...state,
        currentPaymentMadeDocs: state.currentPaymentMadeDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case GET_PAYMENT_MADE_JOURNAL:
      return {
        ...state,
        journal: action.payload,
      };
    case CLEAR_PAYMENT_MADE_JOURNAL:
      return {
        ...state,
        journal: [],
      };
    case PAYMENT_MADE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PAYMENT_MADE_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case REFUND_PAYAMENT_MADE:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_PAYMENT_MADE_REFUNDS:
      return {
        ...state,
        paymentMadeRefunds: action.payload,
      };
    default:
      return state;
  }
};
