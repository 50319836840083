import {
  GET_COMPANY_ACTIVITY,
  GET_UPCOMING_ACTIVITIES,
  GET_COMPANY_ACTIVITIES,
  CLEAR_COMPANY_ACTIVITY,
  ADD_COMPANY_ACTIVITY,
  DELETE_COMPANY_ACTIVITY,
  EDIT_COMPANY_ACTIVITY,
} from '../actions/companyActivitiesActions';

const initialState = {
  count: null,
  activities: [],
  upcomingActivities: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_ACTIVITY:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_COMPANY_ACTIVITY:
      return {
        ...state,
        fetched: null,
      };
    case GET_COMPANY_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
      };
    case GET_UPCOMING_ACTIVITIES:
      return {
        ...state,
        upcomingActivities: action.payload,
      };
    case DELETE_COMPANY_ACTIVITY:
      return {
        ...state,
        activities: state.activities.filter(
          profile => profile.id !== action.payload
        ),
      };
    case ADD_COMPANY_ACTIVITY:
      return {
        ...state,
        activities: [...state.activities, action.payload],
        // holidayDates: [...state.holidayDates, ...action.payload.holiday_dates],
      };
    case EDIT_COMPANY_ACTIVITY:
      return {
        ...state,
        activities: state.activities.map(activity =>
          activity.id === action.payload.id ? action.payload : activity
        ),
      };
    // case GET_HOLIDAY_DATES:
    //   return {
    //     ...state,
    //     holidayDates: action.payload,
    //   };
    default:
      return state;
  }
};
