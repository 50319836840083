import axios from 'axios';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';

export const GET_ACTIVE_CURRENCIES = 'GET_ACTIVE_CURRENCIES';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCY = 'GET_CURRENCY';
export const CLEAR_CURRENCY = 'CLEAR_CURRENCY';
export const ADD_CURRENCY = 'ADD_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const EDIT_CURRENCY = 'EDIT_CURRENCY';
export const DELETE_RATE_FROM_CURRENCY = 'DELETE_RATE_FROM_CURRENCY';
export const CURRENCY_LOADING = 'CURRENCY_LOADING';
export const CURRENCY_LOADED = 'CURRENCY_LOADED';

// GET CURRENCY
export const getCurrency = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/currencies/${id}/exchange_rate`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CURRENCY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR CURRENCY
export const clearCurrency = () => {
  return {
    type: CLEAR_CURRENCY,
  };
};

// GET ACTIVE CURRENCIES
export const getActiveCurrencies = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/currencies/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CURRENCIES,
      payload: res.data.results,
    });
    dispatch({ type: CURRENCY_LOADED });
  } catch (err) {
    dispatch({ type: CURRENCY_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CURRENCIES
export const getCurrencies = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/accountant/currencies/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CURRENCIES,
      payload: res.data.results,
    });
    dispatch({ type: CURRENCY_LOADED });
  } catch (err) {
    dispatch({ type: CURRENCY_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CURRENCY
export const deleteCurrency = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/accountant/currencies/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Deleted' }));
    dispatch({
      type: DELETE_CURRENCY,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD CURRENCY
export const addCurrency = values => async (dispatch, getState) => {
  const currency = {
    currency_name: values.currency_name,
    unit: values.unit,
  };
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/accountant/currencies/`,
      currency,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Added' }));
    dispatch({
      type: ADD_CURRENCY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// EDIT CURRENCY
export const editCurrency = values => async (dispatch, getState) => {
  const currency = {
    currency_code: values.currency_code,
    currency_name: values.currency_name,
    currency_symbol: values.currency_symbol,
    price_precision: values.price_precision,
    exchange_rate: values.exchange_rate,
    exchange_rates: values.exchange_rates,
    is_active: values.is_active,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/accountant/currencies/${values.id}/`,
      currency,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Updated' }));
    dispatch({
      type: EDIT_CURRENCY,
      payload: res.data,
    });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

export const activateCurrency = values => async (dispatch, getState) => {
  const currency = {
    currency_code: values.currency_code,
    currency_name: values.currency_name,
    currency_symbol: values.currency_symbol,
    price_precision: values.price_precision,
    is_active: true,
    exchange_rates: values.exchange_rates,
  };

  try {
    await axios.put(
      `${API_URL}/api/accounting/accountant/currencies/${values.id}/`,
      currency,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Currency Activated' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
