import {
  GET_COMPANY_PROFILE,
  CLEAR_COMPANY_PROFILE,
  GET_COMPANY_PROFILES,
  DELETE_COMPANY_PROFILE,
  ADD_COMPANY_PROFILE,
  EDIT_COMPANY_PROFILE,
} from '../actions/companyProfileActions';

const initialState = {
  count: null,
  profiles: [],
  fetched: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_PROFILE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_COMPANY_PROFILE:
      return {
        ...state,
        fetched: null,
      };
    case GET_COMPANY_PROFILES:
      return {
        ...state,
        count: action.payload.count,
        profiles: action.payload.results,
      };
    case DELETE_COMPANY_PROFILE:
      return {
        ...state,
        profiles: state.profiles.filter(
          profile => profile.id !== action.payload
        ),
      };
    case ADD_COMPANY_PROFILE:
      return {
        ...state,
        profiles: [...state.profiles, action.payload],
      };
    case EDIT_COMPANY_PROFILE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
