/* eslint-disable no-plusplus */

import {
  GET_TRASH,
  CLEAR_TRASH,
  GET_TRASHS,
  DELETE_TRASH,
  RESTORE_TRASH,
} from '../actions/trashActions';

const initialState = {
  items: [],
  fetched: null,
};

let i = 0;

const mapFolder = item => ({
  id: i++,
  itemId: item.id,
  name: item.fold_name,
  type: 'Folder',
  owner: item.department,
  createdAt: item.created_at,
  deletedAt: item.deleted_at,
  deletedBy: item.deleted_by,
});

const mapStorageFile = item => ({
  id: i++,
  itemId: item.id,
  name: item.doc_name,
  type: 'File',
  owner: '',
  createdAt: item.created_at,
  deletedAt: item.deleted_at,
  deletedBy: item.deleted_by,
  category: 'StorageFile',
});

const mapCompanyDoc = item => ({
  id: i++,
  itemId: item.id,
  name: item.doc_name,
  type: 'File',
  owner: '',
  createdAt: item.created_at,
  deletedAt: item.deleted_at,
  deletedBy: item.deleted_by,
  category: 'CompanyDoc',
});

const mapProfileDoc = item => ({
  id: i++,
  itemId: item.id,
  name: item.doc_name,
  type: 'File',
  owner: '',
  createdAt: item.created_at,
  deletedAt: item.deleted_at,
  deletedBy: item.deleted_by,
  category: 'ProfileDoc',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRASH:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_TRASH:
      return {
        ...state,
        fetched: null,
      };
    case GET_TRASHS:
      return {
        ...state,
        items: [
          ...action.payload.folders.map(mapFolder),
          ...action.payload.storage.map(mapStorageFile),
          ...action.payload.company_doc.map(mapCompanyDoc),
          ...action.payload.profile_doc.map(mapProfileDoc),
        ],
      };
    case DELETE_TRASH:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case RESTORE_TRASH:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};
