import {
  GET_PAYMENT_TERMS,
  ADD_PAYMENT_TERM,
  CHANGE_DEFAULT_TERM,
  MODIFY_PAYMENT_TERMS,
} from '../actions/paymentTermsActions';

const initialState = {
  paymentTerms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload,
      };
    case ADD_PAYMENT_TERM:
      return {
        ...state,
        paymentTerms: [...state.paymentTerms, action.payload],
      };
    case CHANGE_DEFAULT_TERM:
      return {
        ...state,
        paymentTerms: action.payload,
      };
    case MODIFY_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload,
      };
    default:
      return state;
  }
};
